import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import {Button} from '@mui/material';
import {SimpleForm, TextInput, useTranslate} from 'react-admin';
import {Link, useParams} from 'react-router-dom';
import CustomEdit from '../common/CustomEdit';
import RequiredText from '../common/RequiredText';

const CompanyEdit = () => {
	const {id} = useParams() as {id: string};
	const translate = useTranslate();
	return (
		<CustomEdit>
			<SimpleForm sx={{maxWidth: 600}}>
				<RequiredText />
				<TextInput source="name" required fullWidth />
				<TextInput source="registration" required fullWidth />
				<Button
					to={`/companies/${id}/detail?`}
					color="primary"
					component={Link}
					variant="outlined"
					startIcon={<SchoolOutlinedIcon aria-hidden="true" />}
				>
					{translate('resources.companies.groupReportLink')}
				</Button>
			</SimpleForm>
		</CustomEdit>
	);
};

export default CompanyEdit;

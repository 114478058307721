import {Box, Button, TextField, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {visuallyHidden} from '@mui/utils';
import {FormEvent, useState} from 'react';
import {NotificationType, useNotify, useTranslate} from 'react-admin';
import {Link} from 'react-router-dom';
import {submitForgotPasswordRequest} from '../../api';
import {ReactComponent as FicLogo} from '../../layout/fic-logo.svg';
import {ReactComponent as LoginIllustration} from '../login-illustration.svg';

const ForgotPasswordPage = () => {
	const [email, setEmail] = useState('');
	const translate = useTranslate();
	const notify = useNotify();

	const [errors, setErrors] = useState({
		message: '',
		isError: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);

		const {message, isError} = await submitForgotPasswordRequest(email);

		if (isError) {
			setErrors({message, isError});
			setIsSubmitting((isSubmitting) => !isSubmitting);
			return;
		}
		setErrors({message: '', isError: !errors.isError});
		notify(translate(message), {
			type: 'success'
		});
	};

	return (
		<div className="LoginPage">
			<div className="LoginPage-leftAside">
				<LoginIllustration
					className="LoginPage-illustration"
					role="img"
					aria-hidden="true"
				/>
			</div>
			<div className="LoginPage-rightMain">
				<form onSubmit={handleSubmit} className="LoginPage-form">
					<h1 style={visuallyHidden}>
						{translate('forgotPassword.label.legend')}
					</h1>

					<FicLogo className="LoginPage-ficLogo" />

					<fieldset className="LoginPage-fieldset">
						<legend>
							<Typography
								component="span"
								sx={(theme: Theme) => ({
									display: 'block',
									marginBottom: '1rem',
									color: theme.palette.primary.main,
									fontSize: '1.5rem'
								})}
							>
								{translate('forgotPassword.legend')}
							</Typography>
						</legend>

						<TextField
							sx={{marginTop: 0, marginBottom: '1rem'}}
							id="email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							label={translate('forgotPassword.label.email')}
							variant="filled"
							size="medium"
							fullWidth
							error={errors.isError}
							helperText={errors.message}
						/>
					</fieldset>
					<Box
						sx={{
							display: 'flex',
							alignContent: 'center',
							flexDirection: 'column'
						}}
					>
						<Button
							disabled={isSubmitting}
							type="submit"
							variant="contained"
							sx={{
								width: 'fit-content',
								padding: '0.75rem 4rem',
								borderRadius: '12px',
								alignSelf: 'flex-end',
								marginBottom: '1.5rem'
							}}
						>
							{translate('forgotPassword.label.submit')}
						</Button>
						<Button
							component={Link}
							to="/login"
							variant="text"
							sx={{
								width: 'fit-content',
								alignSelf: 'flex-end'
							}}
						>
							{translate('login.label.submit')}
						</Button>
					</Box>
				</form>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;

import {
	ArrayInput,
	AutocompleteArrayInput,
	AutocompleteInput,
	BooleanInput,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput
} from 'react-admin';
import CustomEdit from '../common/CustomEdit';
import RequiredText from '../common/RequiredText';
import ReferenceValuesLink from './ReferenceValuesLink';

const CorporationEdit = () => (
	<CustomEdit>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />
			<TextInput source="name" required fullWidth />
			<ReferenceInput source="company_id" reference="companies">
				<AutocompleteInput
					optionText="name"
					sx={{minWidth: '300px', flexWrap: 'nowrap'}}
					debounce={500}
					suggestionLimit={10}
					filterToQuery={(searchText) => ({name: `%${searchText}%`})}
					fullWidth
				/>
			</ReferenceInput>
			<ReferenceInput source="region_id" reference="regions">
				<SelectInput optionText="name" required fullWidth />
			</ReferenceInput>
			<TextInput source="street" required fullWidth />
			<TextInput source="zip" required fullWidth />
			<TextInput source="city" required fullWidth />
			<ReferenceInput source="agreement_id" reference="agreements">
				<AutocompleteInput
					suggestionLimit={10}
					debounce={500}
					filterToQuery={(searchText) => ({label: `%${searchText}%`})}
					optionText="label"
					fullWidth
				/>
			</ReferenceInput>
			<TextInput
				source="registration"
				helperText="resources.corporations.helpers.registration"
				required
				fullWidth
			/>
			<ArrayInput source="registrations">
				<SimpleFormIterator>
					<TextInput source="" />
				</SimpleFormIterator>
			</ArrayInput>
			<ReferenceArrayInput reference="activities" source="activities_ids">
				<AutocompleteArrayInput
					optionText="name"
					suggestionLimit={10}
					debounce={500}
					filterToQuery={(searchText) => ({name: `%${searchText}%`})}
					fullWidth
				/>
			</ReferenceArrayInput>
			<ReferenceArrayInput reference="users" source="users_ids">
				<AutocompleteArrayInput
					suggestionLimit={10}
					debounce={500}
					filterToQuery={(searchText) => ({lastname: `%${searchText}%`})}
					optionText={({firstname, lastname, username}) =>
						`${lastname.toUpperCase()} ${firstname} (${username})`
					}
					fullWidth
				/>
			</ReferenceArrayInput>
			<BooleanInput
				source="active"
				helperText="resources.corporations.helpers.active"
			/>
			<BooleanInput
				source="reportable"
				helperText="resources.corporations.helpers.reportable"
			/>
			<ReferenceValuesLink label="resources.corporations.referenceValuesLink" />
		</SimpleForm>
	</CustomEdit>
);

export default CorporationEdit;

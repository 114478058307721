import {SelectChangeEvent} from '@mui/material';
import {stringify} from 'query-string';
import {useCallback, useRef, useState} from 'react';
import {apiUrl, httpClient} from '../../api';
import {DatesFilter, Option, Options} from './types';
import {generateAllOptions, getInitialDatesValues, groupBy} from './utils';

type OnChange = (
	event: SelectChangeEvent<number>,
	key: 'start' | 'end'
) => {
	options: Options;
	values: DatesFilter | null;
};

type InitializeDatesFilter = (
	corporationsIds: CorporationsIds,
	searchParams: URLSearchParams
) => Promise<{
	options: Options;
	values: DatesFilter;
}>;

type CorporationsIds = number | number[] | undefined;

type UseDatesFilter = () => [
	DatesFilter | null,
	Options,
	OnChange,
	InitializeDatesFilter
];

const groupOptionsByYear = (options: Options['all']) =>
	Object.entries<Array<Option & {year: string}>>(
		groupBy(
			options.map((option) => ({
				...option,
				year: option.date.getFullYear()
			})),
			'year'
		)
	).reverse(); // reverse order because iteration order of object with integer key is ascendant

const getStartAndEndOptionsGroupedByYear = (
	values: DatesFilter,
	options: Options['all']
) => {
	const startOptions = options.filter(
		({date}) => date.getTime() <= values?.end.date.getTime()
	);
	const startOptionsGroupedByYear = groupOptionsByYear(startOptions);

	const endOptions = options.filter(
		({date}) => date.getTime() >= values?.start.date.getTime()
	);
	const endOptionsGroupedByYear = groupOptionsByYear(endOptions);

	return {
		start: startOptionsGroupedByYear,
		end: endOptionsGroupedByYear
	};
};

const fetchDatesRange = async (
	corporationsIds: CorporationsIds
): Promise<{
	start: string;
	end: string;
}> => {
	const params = {
		corporations_ids: Array.isArray(corporationsIds)
			? corporationsIds
			: [corporationsIds]
	};
	const queryString = stringify(params, {
		arrayFormat: 'bracket'
	});
	const {json} = await httpClient(
		apiUrl(`/indicators/statistics/date-range?${queryString}`)
	);

	return json;
};

const useDatesFilter: UseDatesFilter = () => {
	const [values, setValues] = useState<DatesFilter | null>(null);
	const [options, setOptions] = useState<Options>({
		all: [],
		start: [],
		end: []
	});

	const intializeDatesFilter: InitializeDatesFilter = useRef(
		async (
			corporationsIds: CorporationsIds,
			searchParams: URLSearchParams
		) => {
			const datesRange = await fetchDatesRange(corporationsIds);

			const allOptions = generateAllOptions(datesRange);
			const intialValues = getInitialDatesValues(allOptions, searchParams);

			const options = {
				all: allOptions,
				...getStartAndEndOptionsGroupedByYear(intialValues, allOptions)
			};
			setOptions(options);
			setValues(intialValues);

			return {
				options,
				values: intialValues
			};
		}
	).current;

	const onChange: OnChange = useCallback(
		(event, key) => {
			const optionSelected = options.all.find(
				({value}) => value === event.target.value
			);

			if (!values) {
				return {
					options,
					values: null
				};
			}

			const newValues = {
				...values,
				[key]: optionSelected
			};
			const startAndEndOptionsGroupedByYear =
				getStartAndEndOptionsGroupedByYear(newValues, options.all);

			const newOptions = {
				...options,
				...startAndEndOptionsGroupedByYear
			};

			setValues(newValues);
			setOptions(newOptions);

			return {
				options: newOptions,
				values: newValues
			};
		},
		[options, values]
	);

	return [values, options, onChange, intializeDatesFilter];
};

export default useDatesFilter;

import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import {Grid, Theme, Typography} from '@mui/material';
import {useTranslate} from 'react-admin';

interface ErrorMessageProps {
	text: string; // translation key or fallback to text string
	size?: 'small' | 'large';
}

const ErrorMessage = ({text, size = 'large'}: ErrorMessageProps) => {
	const translate = useTranslate();

	return (
		<Grid
			container
			alignItems="center"
			flexWrap="nowrap"
			columnGap={size === 'small' ? 1 : 2}
		>
			<Grid item>
				<ForumOutlinedIcon
					fontSize={size}
					sx={(theme: Theme) => ({
						color: theme.palette.primary.main
					})}
				/>
			</Grid>
			<Grid item>
				<Typography
					component="p"
					sx={{fontSize: size === 'small' ? '0.9rem' : '1rem'}}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: translate(text)
						}}
					/>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default ErrorMessage;

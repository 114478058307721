export const fr = {
	name: 'Établissement |||| Établissements',
	fields: {
		id: 'ID',
		name: 'Nom',
		company_id: 'Groupe scolaire',
		street: 'Adresse',
		zip: 'Code postal',
		city: 'Ville',
		agreement_id: 'Convention collective',
		registration: 'NIC',
		registrations: 'Anciens NICs',
		active: 'Actif',
		reportable: 'Notifiable',
		internal: 'Interne',
		users_ids: 'Utilisateurs',
		activities_ids: "Niveaux d'études"
	},
	helpers: {
		registration: '5 derniers chiffres du SIRET',
		active: `Client pour lequel Fic travaille actuellement sur le volet social.
			Sinon, ses données sont disponibles en temps qu’archives et au sein des panels`,
		reportable: `Champ qui détermine si l'établissement devra recevoir ou non 
			l'alerte mail si il n'y pas de fichiers DSN récents`,
		internal: `Pour indication : client suivi par FIC pour d'autres prestations`
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des établissements',
	socialReportLink: 'Voir le bilan',
	socialReportLabel: "Bilan social de l'établissement",
	referenceValuesLink: 'Voir les valeurs de réference'
};

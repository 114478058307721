import {NumberInput, SimpleForm, TextInput} from 'react-admin';
import CustomEdit from '../common/CustomEdit';
import RequiredText from '../common/RequiredText';

const ThemeEdit = () => (
	<CustomEdit>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />
			<TextInput source="name" required fullWidth />
			<NumberInput source="position" required fullWidth />
		</SimpleForm>
	</CustomEdit>
);

export default ThemeEdit;

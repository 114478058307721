export const fr = {
	legend: 'Récupération de compte',
	label: {
		email: 'Email',
		submit: 'Envoyer'
	},
	message: {
		success:
			"Votre demande a bien été enregistrée, un lien  vous sera envoyé à l'adresse email indiquée !",
		unknow: "Une erreur s'est produite lors de l'envoi de la requête !"
	}
};

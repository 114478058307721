import Box from '@mui/material/Box';
import {useEffect} from 'react';
import {Layout, LayoutProps, usePermissions, useStore} from 'react-admin';
import {hasFicPermissions} from '../auth';
import {ChartsLabelsColorsStoreType} from '../charts/models/types';
import CustomAppBar from './CustomAppBar';
import './CustomLayout.css';
import CustomMenu from './CustomMenu';
import CustomSidebar from './CustomSidebar';
import Footer from './Footer';

const CustomLayout = (props: LayoutProps) => {
	const {permissions, isLoading} = usePermissions();
	const [chartsLabelsColors, setChartsLabelsColors] =
		useStore<ChartsLabelsColorsStoreType>('charts.labels.colors');

	useEffect(() => {
		setChartsLabelsColors({
			pie: {},
			bar: {},
			bar_gradient_colors: {},
			mix_bar: {},
			mix_bar_2: {}
		});
	}, []);
	if (isLoading) {
		return null;
	}
	const hasLeftMenu = hasFicPermissions(permissions);

	return (
		<Box
			sx={{
				'.layout .RaLayout-content': {
					xs: {
						paddingLeft: hasLeftMenu
							? 'calc(16px + 60px) !important' // padding + Menu width
							: '16px',
						paddingRight: '16px !important'
					},
					md: {
						paddingLeft: 'calc(50px + 76px + 20px) !important', // padding + Menu width + Menu's left offset
						paddingRight: 'calc(50px + 76px + 20px) !important'
					}
				}
			}}
		>
			<Layout
				{...props}
				appBar={CustomAppBar}
				menu={hasLeftMenu ? CustomMenu : () => null}
				sidebar={CustomSidebar}
			>
				<>
					{props.children}
					<Footer />
				</>
			</Layout>
		</Box>
	);
};

export default CustomLayout;

import merge from 'deepmerge';
import {defaultTheme} from 'react-admin';

// @see https://schemist.fglt.fr/?preset=1@5@4566a7f8y28a,9a66a7f8y28a,4566a7f8y28a,9a66a7f8y28a,0122b23,01223014@clr,,color,0f91ab,hlt,$%20light,amount,shift,temp,sdw,$%20dark,fe6d00
const theme: object = merge(defaultTheme, {
	palette: {
		primary: {
			main: '#FE862F',
			light: '#FFC195',
			dark: '#994000',
			contrastText: '#fff'
		},
		secondary: {
			main: '#0f91ab',
			light: '#16aecd',
			dark: '#0a758b'
		},
		background: {
			paper: '#FFF'
		},
		text: {
			primary: '#2e2e2e',
			secondary: '#494949F2'
		}
	},
	typography: {
		fontFamily: '"Open Sans",  Sans-Serif',
		fontSize: 16
	},
	shape: {
		borderRadius: 5
	},
	components: {
		RaDatagrid: {
			styleOverrides: {
				root: {
					'& .RaDatagrid-tableWrapper': {
						padding: '1rem',
						borderRadius: '10px'
					},
					'& .RaDatagrid-headerCell': {
						fontWeight: 'bold',
						fontSize: '0.9rem'
					},
					'& .RaDatagrid-headerCell::after': {
						content: "'|'",
						position: 'absolute',
						top: '50%',
						right: 0,
						transform: 'translateY(-50%)',
						color: '#E0E0E0'
					},
					'& .RaDatagrid-rowCell': {
						fontSize: '0.9rem'
					},
					'& .RaDatagrid-row:last-child td': {
						borderBottom: 'none'
					}
				}
			}
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
					fontWeight: 'normal'
				}
			}
		},
		MuiCard: {
			defaultProps: {
				elevation: 0
			},
			styleOverrides: {
				root: {
					boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
				}
			}
		}
	}
});

export default theme;

import DeleteIcon from '@mui/icons-material/Delete';
import {alpha, styled} from '@mui/material/styles';
import {useState} from 'react';
import {
	Button,
	ButtonProps,
	Confirm,
	HttpError,
	useDelete,
	useNotify,
	useRecordContext,
	useRefresh,
	useTranslate
} from 'react-admin';
import {Declaration} from '../../resources/declarations/types';

const CustomDeleteWithConfirmButton = (props: ButtonProps) => {
	const notify = useNotify();
	const translate = useTranslate();
	const record = useRecordContext<Declaration>(props);
	const [open, setOpen] = useState(false);
	const refresh = useRefresh();
	const [deleteOne, {isLoading}] = useDelete<Declaration, HttpError>(
		undefined,
		undefined,
		{
			onSettled: (_, error) => {
				if (error) {
					notify(
						error?.body?.errors?.message ??
							translate('resources.declarations.delete.error'),
						{
							type: 'error'
						}
					);
				} else {
					refresh();
				}
				setOpen(false);
			}
		}
	);

	if (!record.is_deletable) {
		return null;
	}

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);
	const handleConfirm = () => {
		deleteOne('declarations', {
			id: record.id,
			previousData: record
		});
	};

	return (
		<>
			<StyledButton
				startIcon={<DeleteIcon />}
				label="ra.action.delete"
				onClick={handleClick}
				className="ra-delete-button"
			/>
			<Confirm
				isOpen={open}
				loading={isLoading}
				title="resources.declarations.delete.modal.title"
				content="resources.declarations.delete.modal.content"
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</>
	);
};

const PREFIX = 'RaDeleteWithConfirmButton';

const StyledButton = styled(Button, {
	name: PREFIX,
	overridesResolver: (props, styles) => styles.root
})(({theme}) => ({
	color: theme.palette.error.main,
	'&:hover': {
		backgroundColor: alpha(theme.palette.error.main, 0.12),
		// Reset on mouse devices
		'@media (hover: none)': {
			backgroundColor: 'transparent'
		}
	}
}));

export default CustomDeleteWithConfirmButton;

import {stringify} from 'query-string';
import {useRef, useState} from 'react';
import {HttpError} from 'react-admin';
import {apiUrl, httpClient} from '../../api';
import {Stats} from '../../charts/models/types';

export type GetPanelStatsParams = {
	start: string;
	end: string;
	same_activities?: boolean;
	agreements_ids?: number[];
	regions_ids?: number[];
	min_students?: number;
	max_students?: number;
	min_payroll?: number;
	max_payroll?: number;
};

const useGetPanelStats = () => {
	const [stats, setStats] = useState<Stats>({});

	const fetchStats = useRef(
		async (
			corporationId: number | undefined,
			indicatorsIds: number[],
			params: GetPanelStatsParams
		) => {
			const query = {
				...params,
				corporation_id: corporationId
			};
			const result = await Promise.all([
				...indicatorsIds.map((id) => {
					return httpClient(
						apiUrl(
							`/indicators/${id}/panel?${stringify(query, {
								arrayFormat: 'bracket'
							})}`
						)
					).then(
						({json}) => ({id, ...json}),
						(reason: any) => {
							if (reason instanceof HttpError) {
								return {
									id,
									error: reason.body.errors.message
								};
							}
						}
					);
				})
			]);
			const newStats = result.reduce(
				(previous, current) => ({
					...previous,
					[current.id]: current.error
						? {error: current.error}
						: current.data
				}),
				{}
			);
			setStats((prevStats) => ({...prevStats, ...newStats}));
		}
	).current;

	return [stats, fetchStats] as const;
};

export default useGetPanelStats;

import {
	ClearButtons,
	FormatButtons,
	LinkButtons,
	ListButtons,
	QuoteButtons,
	RichTextInputToolbar
} from 'ra-input-rich-text';

const CustomRichTextToolbar = () => (
	<RichTextInputToolbar>
		<FormatButtons />
		<ListButtons />
		<LinkButtons />
		<QuoteButtons />
		<ClearButtons />
	</RichTextInputToolbar>
);

export default CustomRichTextToolbar;

export const fr = {
	name: 'Valeur de référence |||| Valeurs de référence',
	backLink: 'Retour',
	fields: {
		id: 'ID',
		type_id: 'Type de la valeur de référence',
		'begin.date': 'Date de début',
		begin: 'Date de début',
		value: 'Valeur',
		corporation_id: 'Etablissement'
	},
	helpers: {
		value: 'La valeur peut être un entier ou nombre décimal (au centième).',
		'begin.date':
			'La date prise en compte pour les calculs sera le premier jour du mois saisi.'
	}
};

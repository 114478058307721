import ApartmentIcon from '@mui/icons-material/Apartment';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import {Admin, CustomRoutes, Resource} from 'react-admin';
import {BrowserRouter, Route} from 'react-router-dom';
import CompanyDetailPage from './app/company/CompanyDetailPage';
import PrintableCompanyDetailPage from './app/company/PrintableCompanyDetailPage';
import CorporationComparePage from './app/corporation/CorporationComparePage';
import CorporationCompareToPanelPage from './app/corporation/CorporationCompareToPanelPage';
import CorporationDeclarationsPage from './app/corporation/CorporationDeclarationsPage';
import CorporationDetailPage from './app/corporation/CorporationDetailPage';
import IndicatorDetailPage from './app/corporation/IndicatorDetailPage';
import PrintableCorporationDetailPage from './app/corporation/PrintableCorporationDetailPage';
import CustomHomePage from './app/home/CustomHomePage';
import {hasFicPermissions} from './auth';
import CustomLayout from './layout/CustomLayout';
import CustomLoginPage from './login/CustomLoginPage';
import ForgotPasswordPage from './login/forgot-password/ForgotPasswordPage';
import ResetPasswordPage from './login/reset-password/ResetPasswordPage';
import auth from './providers/auth';
import data from './providers/data';
import i18n from './providers/i18n';
import AnalyzeCreate from './resources/analyzes/AnalyzeCreate';
import AnalyzeEdit from './resources/analyzes/AnalyzeEdit';
import AnalyzeList from './resources/analyzes/AnalyzeList';
import BlockList from './resources/blocks/BlockList';
import BlockShow from './resources/blocks/BlockShow';
import CompanyCreate from './resources/companies/CompanyCreate';
import CompanyEdit from './resources/companies/CompanyEdit';
import CompanyList from './resources/companies/CompanyList';
import CorporationCreate from './resources/corporations/CorporationCreate';
import CorporationEdit from './resources/corporations/CorporationEdit';
import CorporationList from './resources/corporations/CorporationList';
import HeadingList from './resources/headings/HeadingList';
import HeadingShow from './resources/headings/HeadingShow';
import IndicatorCreate from './resources/indicators/IndicatorCreate';
import IndicatorEdit from './resources/indicators/IndicatorEdit';
import IndicatorList from './resources/indicators/IndicatorList';
import ReferenceValueCreate from './resources/reference-values/ReferenceValueCreate';
import ReferenceValueEdit from './resources/reference-values/ReferenceValueEdit';
import ReferenceValueList from './resources/reference-values/ReferenceValueList';
import ThemeCreate from './resources/themes/ThemeCreate';
import ThemeEdit from './resources/themes/ThemeEdit';
import ThemeList from './resources/themes/ThemeList';
import UserCreate from './resources/users/UserCreate';
import UserEdit from './resources/users/UserEdit';
import UserList from './resources/users/UserList';
import ValueEdit from './resources/values/ValueEdit';
import ValueList from './resources/values/ValueList';
import theme from './theme';

const App = () => (
	<BrowserRouter>
		<Admin
			dashboard={CustomHomePage}
			i18nProvider={i18n}
			authProvider={auth}
			dataProvider={data}
			layout={CustomLayout}
			loginPage={CustomLoginPage}
			theme={theme}
			disableTelemetry
			requireAuth
		>
			{(permissions) => (
				<>
					{hasFicPermissions(permissions) ? (
						<>
							<Resource
								name="users"
								list={UserList}
								create={UserCreate}
								edit={UserEdit}
								icon={SupervisedUserCircleOutlinedIcon}
							/>
							<Resource
								name="companies"
								list={CompanyList}
								create={CompanyCreate}
								edit={CompanyEdit}
								icon={SchoolOutlinedIcon}
							/>
							<Resource
								name="corporations"
								list={CorporationList}
								create={CorporationCreate}
								edit={CorporationEdit}
								icon={ApartmentIcon}
							/>
							<Resource
								name="blocks"
								list={BlockList}
								show={BlockShow}
								icon={BallotOutlinedIcon}
							/>
							<Resource
								name="headings"
								list={HeadingList}
								show={HeadingShow}
								icon={ListOutlinedIcon}
							/>
							<Resource
								name="values"
								list={ValueList}
								edit={ValueEdit}
								icon={TextFieldsIcon}
							/>
							<Resource
								name="themes"
								list={ThemeList}
								create={ThemeCreate}
								edit={ThemeEdit}
								icon={ColorLensOutlinedIcon}
							/>
							<Resource
								name="indicators"
								list={IndicatorList}
								create={IndicatorCreate}
								edit={IndicatorEdit}
								icon={BarChartOutlinedIcon}
							/>
							<Resource
								name="reference-values"
								list={ReferenceValueList}
								create={ReferenceValueCreate}
								edit={ReferenceValueEdit}
								icon={AutoAwesomeMotionOutlinedIcon}
							/>
							<Resource
								name="analyzes"
								list={AnalyzeList}
								create={AnalyzeCreate}
								edit={AnalyzeEdit}
								icon={ListOutlinedIcon}
							/>
						</>
					) : null}
					<CustomRoutes>
						<Route
							path="/companies/:id/detail"
							element={<CompanyDetailPage />}
						/>
						<Route
							path="/corporations/:id/detail"
							element={<CorporationDetailPage />}
						/>
						<Route
							path="/:resource/:resourceId/indicator/:indicatorId"
							element={<IndicatorDetailPage />}
						/>
						<Route
							path="/corporations/:id/declarations"
							element={<CorporationDeclarationsPage />}
						/>
						<Route
							path="/corporations/:id/compare"
							element={<CorporationComparePage />}
						/>
						<Route
							path="/corporations/:id/compare-panel"
							element={<CorporationCompareToPanelPage />}
						/>
					</CustomRoutes>
					<CustomRoutes noLayout>
						<Route
							path="/forgot-password"
							element={<ForgotPasswordPage />}
						/>
						<Route
							path="/change-password"
							element={<ResetPasswordPage />}
						/>
						<Route
							path="/report/corporation/:id"
							element={<PrintableCorporationDetailPage />}
						/>
						<Route
							path="/report/company/:id"
							element={<PrintableCompanyDetailPage />}
						/>
					</CustomRoutes>
				</>
			)}
		</Admin>
	</BrowserRouter>
);

export default App;

import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Box, Button} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useCallback, useState} from 'react';
import {
	Datagrid,
	DateField,
	List,
	Loading,
	TextField,
	useGetOne,
	useRedirect,
	useRefresh,
	useTranslate
} from 'react-admin';
import {useParams, useSearchParams} from 'react-router-dom';
import CustomDeleteWithConfirmButton from './CustomDeleteWithConfirmButton';
import ImportDialog from './ImportDialog';
import PageHeader from './PageHeader';

const CorporationDeclarationsPage = () => {
	const {id} = useParams() as {id: string};
	const [searchParams] = useSearchParams();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const {data, isLoading} = useGetOne(
		'corporations',
		{id},
		{onError: () => redirect('/')}
	);
	const translate = useTranslate();

	const [isOpen, setIsOpen] = useState(false);
	const openImportDialog = () => {
		setIsOpen(true);
	};
	const closeImportDialog = useCallback(() => {
		setIsOpen(false);
		refresh();
	}, [refresh]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(2)
			})}
		>
			<PageHeader
				backLink={{
					label: translate('corporation.declarations.link.back'),
					title: translate('corporation.declarations.link.backTitle'),
					target: `/corporations/${data.id}/detail?${searchParams}`
				}}
				title={translate('corporation.declarations.title')}
			>
				<Button
					color="primary"
					variant="contained"
					startIcon={<FileUploadIcon aria-hidden="true" />}
					onClick={openImportDialog}
				>
					{translate('corporation.declarations.upload.buttonLabel')}
				</Button>
			</PageHeader>
			<ImportDialog
				isOpen={isOpen}
				onClose={closeImportDialog}
				corporation_id={id}
			/>

			<Box sx={{animation: `slideUp 0.5s 0.2s ease-out both`}}>
				<List
					perPage={25}
					resource="declarations"
					filter={{corporation_id: data.id}}
					sort={{field: 'declared_at', order: 'DESC'}}
					exporter={false}
				>
					<Datagrid size="small" bulkActionButtons={false}>
						<DateField
							source="declared_at.date"
							options={{year: 'numeric', month: 'long'}}
							sx={{textTransform: 'capitalize '}}
							sortBy="declared_at"
						/>
						<DateField
							source="constituted_at.date"
							sortBy="constituted_at"
							sortable={false}
						/>
						<TextField source="total_people" sortable={false} />
						<TextField source="user_name" sortable={false} />
						<CustomDeleteWithConfirmButton />
					</Datagrid>
				</List>
			</Box>
		</Box>
	);
};

export default CorporationDeclarationsPage;

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
	Button,
	DialogActions,
	FormControl,
	Grid,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select,
	Theme,
	Typography
} from '@mui/material';
import {useEffect} from 'react';
import {Form, useRedirect, useTranslate} from 'react-admin';
import {useSearchParams} from 'react-router-dom';
import {Corporation} from '../../resources/corporations/types';
import {DatesFilter as DatesFilterType} from './types';
import useDatesFilter from './useDatesFilter';
import {formatDateISO} from './utils';

interface CompareDialogDateFormProps {
	corporation: Corporation;
	activeDatesFilter: DatesFilterType;
}
const CompareDialogDateForm = (props: CompareDialogDateFormProps) => {
	const {corporation, activeDatesFilter} = props;
	const [searchParams] = useSearchParams();
	const redirect = useRedirect();
	const translate = useTranslate();
	const [datesFilter, options, onChange, intializeDatesFilter] =
		useDatesFilter();

	useEffect(() => {
		intializeDatesFilter(corporation.id, searchParams);
	}, [corporation, intializeDatesFilter, searchParams]);

	const handleSubmit = () => {
		if (!datesFilter) {
			return;
		}

		const searchParams = new URLSearchParams({
			referenceStart: formatDateISO(activeDatesFilter.start.date),
			referenceEnd: formatDateISO(activeDatesFilter.end.date),
			comparedStart: formatDateISO(datesFilter?.start?.date),
			comparedEnd: formatDateISO(datesFilter?.end?.date)
		});
		redirect(`/corporations/${corporation.id}/compare?${searchParams}`);
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Grid container flexDirection="column" alignItems="flex-end">
				<Grid item>
					<Grid container alignItems="center" sx={{margin: '0 auto 1rem'}}>
						<Grid item>
							<AccessTimeIcon
								sx={(theme: Theme) => ({
									color: theme.palette.primary.main,
									mr: 1
								})}
								aria-hidden="true"
							/>
						</Grid>
						<Grid item>
							<Typography
								component="h2"
								sx={(theme: Theme) => ({
									fontSize: '1.2rem',
									fontWeight: '500',
									color: theme.palette.text.secondary
								})}
							>
								{translate('corporation.compare.dialog.period')}
							</Typography>
						</Grid>
					</Grid>
					{!!datesFilter && (
						<>
							<FormControl
								sx={{
									display: 'block',
									maxWidth: '12rem',
									marginBottom: '1rem'
								}}
							>
								<InputLabel id="start-month-label">
									{translate('corporation.detail.datesFilter.start')}
								</InputLabel>
								<Select
									labelId="start-month-label"
									id="start-month-select"
									value={datesFilter?.start.value}
									size="small"
									onChange={(event) => {
										onChange(event, 'start');
									}}
									sx={{
										width: '100%'
									}}
								>
									{options.start.map(([year, options]) => [
										<ListSubheader>{year}</ListSubheader>,
										options.map(({value, label}) => (
											<MenuItem
												key={`start-month-${value}`}
												value={value}
											>
												{label}
											</MenuItem>
										))
									])}
								</Select>
							</FormControl>
							<FormControl
								sx={{
									display: 'block',
									maxWidth: '12rem',
									marginBottom: '1rem'
								}}
							>
								<InputLabel id="end-month-label">
									{translate('corporation.detail.datesFilter.end')}
								</InputLabel>
								<Select
									labelId="end-month-label"
									id="end-month-select"
									value={datesFilter?.end.value}
									size="small"
									onChange={(event) => {
										onChange(event, 'end');
									}}
									sx={{
										width: '100%'
									}}
								>
									{options.end.map(([year, options]) => [
										<ListSubheader>{year}</ListSubheader>,
										options.map(({value, label}) => (
											<MenuItem
												key={`end-month-${value}`}
												value={value}
											>
												{label}
											</MenuItem>
										))
									])}
								</Select>
							</FormControl>
						</>
					)}
				</Grid>
			</Grid>
			<DialogActions sx={{alignSelf: 'flex-end'}}>
				<Button variant="contained" type="submit">
					{translate('corporation.compare.dialog.submit.period')}
				</Button>
			</DialogActions>
		</Form>
	);
};

export default CompareDialogDateForm;

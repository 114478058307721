export const fr = {
	title: 'Le Bilan Social - Fic Expertise',
	legend: 'Connexion',
	label: {
		username: 'Identifiant',
		password: 'Mot de passe',
		submit: 'Je me connecte',
		forgotpassword: 'Mot de passe oublié'
	},
	password: {
		toggle: {
			show: 'Voir le mot de passe',
			hidden: 'Cacher le mot de passe'
		}
	}
};

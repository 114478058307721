import React, {useEffect} from 'react';
import {useStore} from 'react-admin';
import {Indicator} from '../../resources/indicators/types';
import {
	AreaStats,
	BarStats,
	COLORS,
	COLORS_GRADIENT,
	ChartSizes,
	ChartsLabelsColorsStoreType,
	GraphicTypes,
	IndicatorStats,
	LineStats,
	NumberStats,
	NumberTuple,
	PieStats,
	StackedBarStats,
	TableStats
} from '../models/types';
import Area from './Area';
import Bar from './Bar';
import HugeNumber from './HugeNumber';
import Line from './Line';
import Pie from './Pie';
import StackedBar from './StackedBar';
import Table from './Table';

interface ChartRendererProps {
	data: IndicatorStats;
	graphicType: Indicator['graphical_representation'];
	idTitle: string;
	size: ChartSizes;
	domain?: NumberTuple | null;
	hugeFontSize?: string | number;
	fontSize?: string | number;
	padding?: string | number;
	legend: boolean;
	indicator: Indicator | null;
}

const ChartRenderer = ({
	graphicType,
	data,
	idTitle,
	size,
	domain,
	hugeFontSize,
	fontSize,
	padding,
	legend,
	indicator
}: ChartRendererProps) => {
	const [chartsLabelsColorStore, setchartsLabelsColorStore] =
		useStore<ChartsLabelsColorsStoreType>('charts.labels.colors');

	const truncatedTitle = idTitle.replace(/-[0-9]$/, '');

	const getColorsFromDataKeys = (
		data: Array<{key: string}>,
		graphicType:
			| GraphicTypes.pie
			| GraphicTypes.bar
			| GraphicTypes.bar_gradient_colors
			| GraphicTypes.mixBar
			| GraphicTypes.mixBar_2,
		store: ChartsLabelsColorsStoreType,
		offset = 0
	) => {
		const colorsDataKeys = new Map();

		let colors = COLORS;
		if (GraphicTypes.bar_gradient_colors == graphicType) {
			colors = COLORS_GRADIENT;
		}

		data.forEach(({key}) => {
			if (!COLORS[offset]) {
				offset = 0;
			}

			if (key !== '') {
				if (
					!store[graphicType]?.[truncatedTitle] &&
					!store[graphicType]?.[truncatedTitle]?.[key]
				) {
					colorsDataKeys.set(key, colors[offset]);
				} else {
					const currentColors = Object.values(
						store[graphicType][truncatedTitle]
					);
					colorsDataKeys.set(key, colors[offset]);
					while (currentColors.includes(colorsDataKeys.get(key))) {
						offset++;
						if (!colors[offset]) {
							break;
						}
						colorsDataKeys.set(key, colors[offset]);
					}
				}
			}
			offset++;
		});
		return Object.fromEntries(colorsDataKeys);
	};

	useEffect(() => {
		const handleLabelsColor = () => {
			if (
				graphicType === GraphicTypes.pie ||
				((graphicType === GraphicTypes.bar ||
					graphicType === GraphicTypes.bar_gradient_colors ||
					graphicType === GraphicTypes.mixBar ||
					graphicType === GraphicTypes.mixBar_2) &&
					data?.all)
			) {
				let values: {key: any}[];

				if (
					graphicType === GraphicTypes.mixBar ||
					graphicType === GraphicTypes.mixBar_2
				) {
					values = [];
					Object.entries(data?.all).map(function (key) {
						Object.values(key[1]).map(function (value) {
							let label = '';

							if ((value as {rule: any; label: string}).label === '') {
								label = key[0];
							} else {
								label =
									key[0] +
									' ' +
									(value as {rule: any; label: string}).label;
							}

							values.push({
								key: label
							});
						});
					});
				} else {
					values = Object.values(data?.all ?? {}).map(({label}) => ({
						key: label
					}));
				}
				const labelsColorFromData = {
					[graphicType]: {
						[truncatedTitle]: {
							...getColorsFromDataKeys(
								values,
								graphicType,
								chartsLabelsColorStore
							)
						}
					}
				};

				setchartsLabelsColorStore((currentStore) => {
					currentStore[graphicType][truncatedTitle] = {
						...labelsColorFromData[graphicType][truncatedTitle],
						...currentStore[graphicType][truncatedTitle]
					};
					return currentStore;
				});
			}
		};
		handleLabelsColor();
	}, [chartsLabelsColorStore]);
	return (
		<>
			{graphicType === GraphicTypes.percent ||
			graphicType === GraphicTypes.currency ||
			graphicType === GraphicTypes.people ? (
				<HugeNumber
					hugeFontSize={hugeFontSize}
					value={data?.all ? (data as NumberStats)?.all : 0}
					type={graphicType}
				/>
			) : graphicType === GraphicTypes.pie ? (
				!!data?.all && (
					<Pie
						data={(data as PieStats)?.all}
						size={size}
						idTitle={truncatedTitle}
						showLabelInside={
							indicator?.identifier === 4 || indicator?.identifier === 19
						}
					/>
				)
			) : graphicType === GraphicTypes.bar ||
			  graphicType === GraphicTypes.bar_gradient_colors ? (
				!!data?.all && (
					<Bar
						data={(data as BarStats)?.all}
						size={size}
						idTitle={truncatedTitle}
						domain={domain}
						legend={legend}
					/>
				)
			) : graphicType === GraphicTypes.area ? (
				!!data && (
					<Area data={data as AreaStats} size={size} domain={domain} />
				)
			) : graphicType === GraphicTypes.line ? (
				!!data && (
					<Line data={data as LineStats} size={size} domain={domain} />
				)
			) : graphicType === GraphicTypes.table ||
			  graphicType === GraphicTypes.values_table ? (
				data.all ? (
					<Table
						data={data as TableStats}
						idTitle={`indicator-title-${idTitle}`}
						size={size}
						type={graphicType}
						fontSize={fontSize}
						padding={padding}
					/>
				) : indicator?.identifier === 13 ? (
					<HugeNumber
						hugeFontSize={hugeFontSize}
						value={0}
						type={GraphicTypes.hour}
					/>
				) : (
					<HugeNumber
						hugeFontSize={hugeFontSize}
						value={0}
						type={GraphicTypes.people}
					/>
				)
			) : graphicType === GraphicTypes.mixBar ||
			  graphicType === GraphicTypes.mixBar_2 ? (
				data.all ? (
					<StackedBar
						data={data as StackedBarStats}
						size={ChartSizes.medium}
						type={graphicType}
						idTitle={truncatedTitle}
						domain={domain}
					/>
				) : (
					<HugeNumber
						hugeFontSize={hugeFontSize}
						value={0}
						type={GraphicTypes.people}
					/>
				)
			) : (
				<p>Coming soon</p>
			)}
		</>
	);
};

export default React.memo(ChartRenderer);

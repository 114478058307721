import {
	ArrayField,
	ChipField,
	Datagrid,
	EditButton,
	FunctionField,
	SelectArrayInput,
	SingleFieldList,
	TextField,
	TextInput
} from 'react-admin';
import CustomList from '../common/CustomList';
import {ROLE_CHOICES, UserRole} from './types';

const filters = [
	<TextInput source="firstname" />,
	<TextInput source="lastname" />,
	<TextInput source="username" />,
	<SelectArrayInput
		source="roles"
		choices={ROLE_CHOICES}
		optionText="role"
		optionValue="role"
	/>
];

const UserList = () => (
	<CustomList filters={filters} sort={{field: 'lastname', order: 'ASC'}}>
		<Datagrid size="small">
			<TextField source="lastname" />
			<TextField source="firstname" />
			<TextField source="username" />
			<ArrayField source="roles" sortable={false}>
				<SingleFieldList linkType={false}>
					<FunctionField
						render={(record: UserRole) => (
							<ChipField
								size="small"
								record={{name: record}}
								source="name"
							/>
						)}
					/>
				</SingleFieldList>
			</ArrayField>
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default UserList;

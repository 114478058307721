import {Grid, Theme, Typography} from '@mui/material';
import {ReactNode} from 'react';
import BackButton from '../../resources/common/BackButton';

interface PageHeaderProps {
	backLink?: {
		label: string;
		title?: string;
		target: string;
	};
	title?: string;
	icon?: ReactNode;
	children?: ReactNode;
}

const PageHeader = ({backLink, title, icon, children}: PageHeaderProps) => (
	<>
		<Grid
			container
			justifyContent={'space-between'}
			alignItems="flex-start"
			spacing={4}
		>
			<Grid item>
				<Grid container spacing={3} alignItems="flex-start">
					{backLink && (
						<Grid item>
							<BackButton {...backLink} />
						</Grid>
					)}
					<Grid item>
						<Typography
							component="h1"
							sx={(theme: Theme) => ({
								fontSize: '2rem',
								fontWeight: '500',
								lineHeight: 1.2,
								marginBottom: '0.5rem',
								color: theme.palette.text.secondary,
								display: 'flex',
								alignItems: 'center',
								'svg': {
									color: theme.palette.primary.main,
									mr: 1
								}
							})}
						>
							{icon}
							{title}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>{children}</Grid>
		</Grid>
	</>
);

export default PageHeader;

import Box from '@mui/material/Box';
import {Theme} from '@mui/material/styles';
import {List, ListProps, useListController} from 'react-admin';
import CustomListActions from './CustomListActions';
import Title from './Title';

const CustomList = (props: ListProps) => {
	const {defaultTitle, resource} = useListController();
	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(2)
			})}
		>
			<Title title={defaultTitle} resourceName={resource} />
			<List
				perPage={25}
				{...props}
				exporter={false}
				sx={{
					'.MuiToolbar-root': {
						minHeight: 0
					}
				}}
				actions={<CustomListActions />}
			/>
		</Box>
	);
};

export default CustomList;

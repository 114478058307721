export const fr = {
	name: 'Bloc |||| Blocs',
	fields: {
		id: 'ID',
		code: 'Code',
		title: 'Titre'
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des blocs'
};

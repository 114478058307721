import {useTranslate} from 'react-admin';

const HugePercentNumber = ({
	value,
	hugeFontSize
}: {
	value: number;
	hugeFontSize: string | number | undefined;
}) => {
	const translate = useTranslate();
	return (
		<>
			<span className="huge" style={{fontSize: hugeFontSize}}>
				{value.toFixed(2).replace(/\./g, ',')}
			</span>
			<span className="small" style={{fontSize: hugeFontSize}}>
				&nbsp;{translate('charts.table.symbols.percent')}
			</span>
		</>
	);
};

export default HugePercentNumber;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslate} from 'react-admin';

const linksLabel = ['legalNotices', 'accessibility'];

const Footer = () => {
	const translate = useTranslate();

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Box
			component="footer"
			role="contentinfo"
			sx={{padding: '2rem 0 1rem', marginTop: 'auto', fontSize: '0.9rem'}}
		>
			<Grid
				container
				justifyContent={`${matches ? 'flex-end' : 'center'}`}
				columnSpacing="15"
			>
				<a
					title={'a.cousin@fic-expertise.fr'}
					href={'mailto:a.cousin@fic-expertise.fr'}
					style={{color: theme.palette.primary.main}}
				>
					{translate('layout.footer.need_help')}
				</a>
				<Grid item component="p">
					{translate('layout.footer.copyright')}
				</Grid>
			</Grid>
		</Box>
	);
};

export default Footer;

export const fr = {
	name: 'Valeur |||| Valeurs',
	fields: {
		id: 'ID',
		code: 'Code',
		label: 'Libellé DSN',
		label_to_display: 'Libellé à afficher',
		heading_id: 'Rubrique'
	},
	helpers: {
		label_to_display:
			'Si ce libellé est renseigné, il sera affiché dans les indicateurs recensant cette valeur. Sinon, le libellé DSN sera affiché par défaut.'
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des valeurs'
};

import {Tabs as MuiTabs} from '@mui/material';
import {useTheme as useMuiTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslate} from 'react-admin';
import {Theme} from '../../resources/themes/types';
import StyledTab from './StyledTab';

interface TabsProps {
	activeTabIndex: number;
	handleTabChange: (index: number) => void;
	themes: Theme[];
	isLoading: boolean;
}

const Tabs = ({
	themes,
	activeTabIndex,
	handleTabChange,
	isLoading
}: TabsProps) => {
	const translate = useTranslate();
	const onChange = (_: React.SyntheticEvent, index: number) => {
		if (!isLoading) {
			handleTabChange(index);
		}
	};

	const theme = useMuiTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<MuiTabs
			value={activeTabIndex}
			sx={{
				margin: '0.5rem auto 1rem',

				'& .MuiTabs-flexContainer': {
					justifyContent: `${matches ? 'center' : 'flex-start'}`
				}
			}}
			onChange={onChange}
			aria-label={translate('corporation.detail.tabs.label')}
			TabIndicatorProps={{
				sx: {backgroundColor: 'transparent'}
			}}
			variant="scrollable"
			indicatorColor="secondary"
			allowScrollButtonsMobile
			visibleScrollbar
		>
			{themes.map((theme, index) => (
				<StyledTab
					key={`corporation-tabs-${index}`}
					label={theme.name}
					id={`simple-tab-${index}`}
					aria-controls={`simple-tabpanel-${index}`}
					isLoading={isLoading}
				/>
			))}
		</MuiTabs>
	);
};

export default Tabs;

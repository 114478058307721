import {
	Box,
	Card,
	CardContent,
	List,
	ListItem,
	Theme,
	Typography
} from '@mui/material';
import {useStore, useTranslate} from 'react-admin';
import {Analyze} from '../models/Analyze';

type AnalyzesProps = {
	analyzes: Analyze[];
};

const Analyzes = ({analyzes}: AnalyzesProps) => {
	const translate = useTranslate();
	const [printable] = useStore('printable.page');
	return (
		<Box
			component="li"
			sx={{
				py: 0,
				marginBottom: '20px',
				animation: `slideUp 0.5s ease-out both`,
				...(printable && {
					gridColumnEnd: 'span 2'
				})
			}}
		>
			<Card sx={{width: '100%', height: '100%'}}>
				<CardContent
					className="content"
					sx={{
						position: 'relative',
						padding: '20px'
					}}
				>
					<List
						sx={{
							listStyle: 'none'
						}}
					>
						{analyzes?.map(
							({id, content, begin, expiration, updated}) => (
								<ListItem
									key={`analyzes-${id}`}
									sx={{
										flexDirection: 'column',
										alignItems: 'flex-start',
										marginBottom: '2rem',
										padding: 0
									}}
								>
									<Typography
										component="h3"
										sx={{
											textTransform: 'capitalize',
											fontSize: '1rem',
											fontWeight: '500',
											alignSelf: 'center'
										}}
									>
										{translate('analyzes.datesRange', {
											startDate: new Date(begin).toLocaleDateString(
												'fr-FR',
												{
													month: 'long',
													year: 'numeric'
												}
											),
											endDate: new Date(
												expiration
											).toLocaleDateString('fr-FR', {
												month: 'long',
												year: 'numeric'
											})
										})}
									</Typography>

									<Box
										sx={{fontSize: '0.85rem'}}
										dangerouslySetInnerHTML={{__html: content}}
									/>

									<Typography
										component="p"
										sx={(theme: Theme) => ({
											fontSize: '0.85rem',
											alignSelf: 'flex-end',
											color: theme.palette.text.secondary
										})}
									>
										{translate('analyzes.updatedText', {
											updatedDate: new Date(
												updated
											).toLocaleDateString('fr-FR', {
												day: 'numeric',
												month: 'numeric',
												year: 'numeric'
											})
										})}
									</Typography>
								</ListItem>
							)
						)}
					</List>
				</CardContent>
			</Card>
		</Box>
	);
};

export default Analyzes;

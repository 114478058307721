import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	Collapse,
	FormControlLabel,
	FormGroup
} from '@mui/material';
import {Form, useTranslate} from 'react-admin';
import {Indicator} from '../../resources/indicators/types';
import ExpandMore from './ExpandMore';
import {ThemeEnhancedWithIndicators} from './types';

interface IndicatorsCheckboxesProps {
	themes: ThemeEnhancedWithIndicators[];
	expandedThemes: Record<number, boolean>;
	activeIndicators: Indicator[];
	handleExpandClick: (index: number) => void;
	handleIndicatorChange: (indicator: Indicator) => void;
}

const IndicatorsCheckboxes = ({
	themes,
	expandedThemes,
	activeIndicators,
	handleExpandClick,
	handleIndicatorChange
}: IndicatorsCheckboxesProps) => {
	const translate = useTranslate();

	return (
		<Box sx={{animation: `slideUp 0.5s 0.2s ease-out both`}}>
			<Card>
				<CardContent>
					<Form>
						{themes.map(
							({name, id, indicators, isSynthesis}, index) =>
								!isSynthesis && (
									<Box key={`theme-list-indicators-${id}`}>
										<ExpandMore
											expand={expandedThemes[index] ?? false}
											onClick={() => {
												handleExpandClick(index);
											}}
											aria-expanded={expandedThemes[index] ?? false}
											aria-label={translate(
												'corporation.compare.expand',
												{
													name
												}
											)}
										>
											<ExpandMoreIcon />
											{name}
										</ExpandMore>
										<Collapse
											in={expandedThemes[index] ?? false}
											timeout="auto"
											unmountOnExit
										>
											<Box sx={{padding: '0 2rem'}}>
												<FormGroup>
													{indicators.map((indicator) => (
														<FormControlLabel
															key={`indicators-list-${indicator.id}`}
															control={
																<Checkbox
																	size="small"
																	color="default"
																	value={indicator.id}
																	checked={activeIndicators.some(
																		(item) =>
																			item.id ===
																			indicator.id
																	)}
																	onChange={() => {
																		handleIndicatorChange(
																			indicator
																		);
																	}}
																/>
															}
															value={indicator.id}
															label={indicator.name}
															sx={{
																alignItems: 'start',
																'.MuiTypography-root': {
																	paddingTop: '7px',
																	fontSize: '1rem'
																}
															}}
														/>
													))}
												</FormGroup>
											</Box>
										</Collapse>
									</Box>
								)
						)}
					</Form>
				</CardContent>
			</Card>
		</Box>
	);
};
export default IndicatorsCheckboxes;

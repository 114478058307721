export const fr = {
	legend: 'Réinitialisation du mot de passe',
	label: {
		password: 'Mot de passe',
		confirmpassword: 'Confirmation du mot de passe',
		submit: 'Réinitialiser'
	},
	message: {
		success: 'Votre nouveau mot de passe a bien été modifié !',
		unknow: "Une erreur s'est produite lors de l'envoi de la requête !"
	}
};

import {RaRecord} from 'ra-core';
import {ReferenceField, SimpleShowLayout, TextField} from 'react-admin';
import CustomShow from '../common/CustomShow';

const HeadingShow = () => (
	<CustomShow>
		<SimpleShowLayout>
			<TextField source="name" />
			<TextField source="code" />
			<TextField source="format" />
			<TextField source="comment" />
			<ReferenceField
				source="block_id"
				reference="blocks"
				sortable={false}
				link={(record: RaRecord, reference: string) =>
					`/${reference}/${record.block_id}/show`
				}
			>
				<TextField source="title" />
			</ReferenceField>
		</SimpleShowLayout>
	</CustomShow>
);

export default HeadingShow;

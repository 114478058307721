import {AuthProvider} from 'react-admin';
import {apiUrl, fetchLogin, httpClient} from '../api';
import {
	getToken,
	isAllowedAnonymousRoute,
	removeToken,
	setToken
} from '../auth';

const authProvider: AuthProvider = {
	login({username, password}) {
		return fetchLogin(username, password).then(({json}) => {
			setToken(json.token);
		});
	},
	checkError(error) {
		if (error.status === 401) {
			removeToken();
			return Promise.reject();
		}

		return Promise.resolve();
	},
	checkAuth() {
		if (isAllowedAnonymousRoute(window.location.pathname)) {
			return Promise.resolve();
		}
		return getToken() ? Promise.resolve() : Promise.reject();
	},
	logout() {
		removeToken();
		return Promise.resolve();
	},
	getPermissions: async () => {
		if (isAllowedAnonymousRoute(window.location.pathname)) {
			return [];
		}
		const {json: user} = await httpClient(apiUrl(`/connected-user-data`));
		return user.roles;
	}
};

export default authProvider;

import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import {fr as analyzesFr} from '../analyzes/utils/i18n';
import {fr as corporationFr} from '../app/corporation/i18n';
import {fr as homeFr} from '../app/home/i18n';
import {fr as chartsFr} from '../charts/utils/i18n';
import {fr as layoutFr} from '../layout/i18n';
import {fr as forgotPasswordFr} from '../login/forgot-password/i18n';
import {fr as loginFr} from '../login/i18n';
import {fr as resetPasswordFr} from '../login/reset-password/i18n';
import {fr as resourceAnalyzesFr} from '../resources/analyzes/i18n';
import {fr as resourceBlocksFr} from '../resources/blocks/i18n';
import {fr as commonFr} from '../resources/common/i18n';
import {fr as resourceCompaniesFr} from '../resources/companies/i18n';
import {fr as resourceCorporationsFr} from '../resources/corporations/i18n';
import {fr as resourceDeclarationsFr} from '../resources/declarations/i18n';
import {fr as resourceHeadingsFr} from '../resources/headings/i18n';
import {fr as resourceIndicatorsFr} from '../resources/indicators/i18n';
import {fr as resourceReferenceValuesFr} from '../resources/reference-values/i18n';
import {fr as resourceThemesFr} from '../resources/themes/i18n';
import {fr as resourceUsersFr} from '../resources/users/i18n';
import {fr as resourceValuesFr} from '../resources/values/i18n';

export const messages = {
	...fr,
	resources: {
		blocks: resourceBlocksFr,
		companies: resourceCompaniesFr,
		corporations: resourceCorporationsFr,
		headings: resourceHeadingsFr,
		values: resourceValuesFr,
		declarations: resourceDeclarationsFr,
		users: resourceUsersFr,
		themes: resourceThemesFr,
		indicators: resourceIndicatorsFr,
		analyzes: resourceAnalyzesFr,
		'reference-values': resourceReferenceValuesFr
	},
	layout: layoutFr,
	login: loginFr,
	forgotPassword: forgotPasswordFr,
	resetPassword: resetPasswordFr,
	home: homeFr,
	common: commonFr,
	charts: chartsFr,
	corporation: corporationFr,
	analyzes: analyzesFr
};

export default polyglotI18nProvider(() => messages, 'fr', {allowMissing: true});

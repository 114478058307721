import {
	BooleanField,
	Datagrid,
	EditButton,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	TextField,
	TextInput
} from 'react-admin';
import CustomList from '../common/CustomList';
import SelectBooleanInput from '../common/SelectBooleanInput';
import SocialReportLink from './SocialReportLink';

const filters = [
	<TextInput source="name" />,
	<TextInput source="city" />,
	<ReferenceInput source="region_id" reference="regions">
		<SelectInput optionText="name" />
	</ReferenceInput>,
	<SelectBooleanInput source="active" />
];

const CorporationList = () => (
	<CustomList filters={filters}>
		<Datagrid size="small">
			<TextField source="name" />
			<ReferenceField
				source="company_id"
				reference="companies"
				sortBy="company.name"
			>
				<TextField source="name" />
			</ReferenceField>
			<TextField source="city" />
			<ReferenceField
				source="region_id"
				reference="regions"
				sortBy="region.name"
				link={false}
			>
				<TextField source="name" />
			</ReferenceField>
			<BooleanField source="active" />
			<SocialReportLink label="resources.corporations.socialReportLink" />
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default CorporationList;

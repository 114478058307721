import {Datagrid, EditButton, TextField} from 'react-admin';
import CustomList from '../common/CustomList';

const ThemeList = () => (
	<CustomList sort={{field: 'position', order: 'ASC'}}>
		<Datagrid size="small">
			<TextField source="name" />
			<TextField source="position" />
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default ThemeList;

import {DateInput, DateInputProps} from 'react-admin';

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

const convertDateToString = (value: Date) => {
	// value is a `Date` object
	if (!(value instanceof Date) || isNaN(value.getDate())) return '';
	const pad = '00';
	const yyyy = value.getFullYear().toString();
	const MM = (value.getMonth() + 1).toString();
	const dd = value.getDate().toString();
	return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateFormatter = (value: {date: string} | string) => {
	// null, undefined and empty string values should not go through dateFormatter
	// otherwise, it returns undefined and will make the input an uncontrolled one.
	if (value == null || value === '') {
		return '';
	}

	if (typeof value === 'string') {
		// Valid dates should not be converted
		if (dateFormatRegex.test(value)) {
			return value;
		}
		return convertDateToString(new Date(value));
	}

	return convertDateToString(new Date(value?.date));
};

const CustomDateInput = (props: DateInputProps) => (
	<DateInput {...props} format={dateFormatter} />
);

export default CustomDateInput;

import {RaRecord} from 'ra-core';
import {Datagrid, ReferenceField, TextField} from 'react-admin';
import CustomList from '../common/CustomList';

const HeadingList = () => (
	<CustomList sort={{field: 'name', order: 'ASC'}}>
		<Datagrid size="small" bulkActionButtons={false}>
			<TextField source="name" />
			<TextField source="code" />
			<TextField source="format" />
			<TextField source="comment" />
			<ReferenceField
				source="block_id"
				reference="blocks"
				sortable={false}
				link={(record: RaRecord, reference: string) =>
					`/${reference}/${record.block_id}/show`
				}
			>
				<TextField source="title" />
			</ReferenceField>
		</Datagrid>
	</CustomList>
);

export default HeadingList;

import {RaRecord} from 'ra-core';
import {
	AutocompleteArrayInput,
	Datagrid,
	DateField,
	EditButton,
	ReferenceArrayInput,
	ReferenceField,
	TextField,
	TextInput
} from 'react-admin';
import CustomList from '../common/CustomList';

const filters = [
	<TextInput source="begin.date" type="number" label="Année de début" />,
	<ReferenceArrayInput
		source="type_id"
		reference="reference-value-types"
		sort={{field: 'label', order: 'ASC'}}
	>
		<AutocompleteArrayInput
			source="label"
			optionText="label"
			sx={{minWidth: '300px', flexWrap: 'nowrap'}}
			debounce={500}
			suggestionLimit={20}
			filterToQuery={(searchText) => ({label: `%${searchText}%`})}
		/>
	</ReferenceArrayInput>,

	<ReferenceArrayInput source="corporation_id" reference="corporations">
		<AutocompleteArrayInput
			source="name"
			optionText="name"
			sx={{minWidth: '300px', flexWrap: 'nowrap'}}
			debounce={500}
			suggestionLimit={20}
			filterToQuery={(searchText) => ({name: `%${searchText}%`})}
		/>
	</ReferenceArrayInput>
];

const ReferenceValueList = () => (
	<CustomList filters={filters} sort={{field: 'value', order: 'ASC'}}>
		<Datagrid size="small" bulkActionButtons={false}>
			<TextField source="value" />
			<DateField
				source="begin.date"
				sortBy="begin"
				options={{year: 'numeric', month: '2-digit', day: '2-digit'}}
			/>
			<ReferenceField source="type_id" reference="reference-value-types">
				<TextField source="label" label="label" />
			</ReferenceField>
			<ReferenceField
				source="corporation_id"
				sortBy="id"
				reference="corporations"
				link={(record: RaRecord, reference: string) => {
					console.log(record);
					return `/${reference}/${record.corporation_id}/show`;
				}}
			>
				<TextField source="name" />
			</ReferenceField>
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default ReferenceValueList;

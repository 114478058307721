import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import Button from '@mui/material/Button';
import {useRecordContext, useTranslate} from 'react-admin';
import {Link} from 'react-router-dom';
import {Corporation} from './types';

const SocialReportLink = ({label}: {label: string}) => {
	const record = useRecordContext<Corporation>();
	const translate = useTranslate();
	return (
		<Button
			component={Link}
			to={`/corporations/${record.id}/detail`}
			startIcon={<BarChartOutlinedIcon />}
		>
			{translate(label)}
		</Button>
	);
};

export default SocialReportLink;

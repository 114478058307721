import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {
	CreateBase,
	FileField,
	FileInput,
	Form,
	HttpError,
	Validator,
	useCreate,
	useNotify,
	useTranslate
} from 'react-admin';
import {FieldValues} from 'react-hook-form';
import {Declaration} from '../../resources/declarations/types';

interface ImportDialogProps {
	corporation_id: string;
	isOpen: boolean;
	onClose: () => void;
}

const MAX_FILES_COUNT = 12;

const ImportDialog = (props: ImportDialogProps) => {
	const {onClose, isOpen, corporation_id} = props;
	const translate = useTranslate();
	const translateRoot = 'resources.declarations.upload';
	const notify = useNotify();
	const [error, setError] = useState(null);
	const [create, {isLoading}] = useCreate<Declaration, HttpError>(
		undefined,
		undefined,
		{
			onSettled: (_, error) => {
				if (error) {
					setError(error?.body?.errors?.message);
				} else {
					notify(translate(`${translateRoot}.success`), {
						type: 'success'
					});
					closeDialog();
				}
			}
		}
	);

	useEffect(() => {
		const files = Array.from(
			document.getElementsByClassName(
				'RaFileInput-preview'
			) as HTMLCollectionOf<HTMLElement>
		);

		files.forEach((fileLink) => {
			fileLink.style.pointerEvents = isLoading ? 'none' : 'auto';
		});
	}, [isLoading]);

	const closeDialog = useCallback(() => {
		setError(null);
		onClose();
	}, [onClose]);

	const handleDialogClose = useCallback(
		(_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
			if (reason === 'backdropClick') {
				return;
			}
			closeDialog();
		},
		[closeDialog]
	);

	const handleSubmit = (data: FieldValues) => {
		setError(null);
		const formData = new FormData();
		data.dsn.forEach(({rawFile}: {rawFile: File}) => {
			formData.append('dsn[]', rawFile);
		});
		formData.append('corporation_id', corporation_id);
		formData.append('comment', 'null');
		create('declarations', {
			data: {formData},
			meta: {isMultipart: true}
		});
	};

	const checkMaxFilesCount: Validator = (files) => {
		return files && files.length > MAX_FILES_COUNT
			? translate(`${translateRoot}.maxFiles`, {
					maxFilesCount: MAX_FILES_COUNT
				})
			: undefined;
	};

	return (
		<Dialog
			onClose={handleDialogClose}
			open={isOpen}
			fullWidth
			maxWidth="xs"
			PaperProps={{sx: {padding: '1rem'}}}
		>
			<DialogTitle sx={{padding: 0, marginBottom: '1rem'}}>
				{translate(`${translateRoot}.title`)}
			</DialogTitle>

			<DialogContent sx={{padding: 0}}>
				<CreateBase resource="declarations">
					<Form onSubmit={handleSubmit}>
						<Box mb={3}>
							<FileInput
								source="dsn"
								fullWidth
								multiple
								sx={{
									'& .RaFileInput-dropZone': {
										backgroundColor: '#f1efef'
									}
								}}
								placeholder={
									<p>{translate(`${translateRoot}.placeholder`)}</p>
								}
								validate={checkMaxFilesCount}
								helperText={`${translateRoot}.helper`}
							>
								<FileField source="src" title="title" />
							</FileInput>
						</Box>

						<Box mb={3}>
							{!!error && <Alert severity="error">{error}</Alert>}
							{isLoading && (
								<Alert severity="info" icon={false}>
									<AlertTitle>
										<CircularProgress
											size={18}
											color="secondary"
											sx={{marginRight: '0.5rem'}}
										/>
										{translate(`${translateRoot}.loading.title`)}
									</AlertTitle>
									{translate(`${translateRoot}.loading.content`)}
								</Alert>
							)}
						</Box>

						<DialogActions sx={{alignSelf: 'flex-end'}}>
							<Button onClick={closeDialog} disabled={isLoading}>
								{translate(`${translateRoot}.close`)}
							</Button>
							<Button
								variant="contained"
								type="submit"
								disabled={isLoading}
							>
								{translate(`${translateRoot}.submit`)}
							</Button>
						</DialogActions>
					</Form>
				</CreateBase>
			</DialogContent>
		</Dialog>
	);
};

export default ImportDialog;

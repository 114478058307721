import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import {Box, Button, Grid, SelectChangeEvent, Theme} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useTranslate} from 'react-admin';
import {Link, useLocation, useParams, useSearchParams} from 'react-router-dom';
import dataProvider from '../../providers/data';
import {Company} from '../../resources/companies/types';
import DashboardGrid from '../corporation/DashboardGrid';
import DatesFilter from '../corporation/DatesFilter';
import ErrorMessage from '../corporation/ErrorMessage';
import LoadingContainer from '../corporation/LoadingContainer';
import PageHeader from '../corporation/PageHeader';
import TabPanel from '../corporation/TabPanel';
import Tabs from '../corporation/Tabs';
import {
	DatesFilter as DatesFilterType,
	ThemeEnhancedWithIndicators
} from '../corporation/types';
import useDatesFilter from '../corporation/useDatesFilter';
import useErrorHandler from '../corporation/useErrorHandler';
import useGetStats from '../corporation/useGetStats';
import useGetThemes from '../corporation/useGetThemes';
import {formatDateISO, isObjectEmpty} from '../corporation/utils';
import ExportCompanyModal from './ExportCompanyModal';

const CompanyDetailPage = () => {
	const {id} = useParams() as {id: string};
	const [searchParams, setSearchParams] = useSearchParams();

	const [company, setCompany] = useState<Company>();
	const [isReady, setIsReady] = useState(false);
	const {error, setError, handleError} = useErrorHandler();
	const [datesFilter, options, onChange, intializeDatesFilter] =
		useDatesFilter();
	const [activeTabIndex, setActiveTabIndex] = useState(
		searchParams.has('activeTabIndex')
			? parseInt(searchParams.get('activeTabIndex')!, 10)
			: 0
	);
	const [isLoading, setIsLoading] = useState(false);
	const [themes, fetchThemes] = useGetThemes();
	const [stats, fetchStats, isStatsLoading] = useGetStats();
	const translate = useTranslate();
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	const {state} = useLocation();

	const initialize = useCallback(
		async (
			idCompany: string,
			activeTabIndex: number,
			searchParams: URLSearchParams
		) => {
			try {
				const {data: company} = await dataProvider.getOne<Company>(
					'companies',
					{
						id: parseInt(idCompany)
					}
				);

				setCompany(company);

				let themesTabs: ThemeEnhancedWithIndicators[];
				if (!themes) {
					themesTabs = await fetchThemes();
				} else {
					themesTabs = themes;
				}

				const datesFilter = await intializeDatesFilter(
					company?.corporations_ids,
					searchParams
				);

				await fetchStats(
					company?.corporations_ids,
					themesTabs?.[activeTabIndex].indicators.map(({id}) => id),
					datesFilter.values.start.date,
					datesFilter.values.end.date,
					{reset: true}
				);

				setIsReady(true);
				setIsLoading(false);
				setSearchParams(
					new URLSearchParams({
						start: formatDateISO(datesFilter.values.start.date),
						end: formatDateISO(datesFilter.values.end.date),
						activeTabIndex: activeTabIndex.toString()
					})
				);
			} catch (e) {
				handleError(e);
			} finally {
				setIsReady(true);
				setIsLoading(false);
			}
		},
		[
			themes,
			fetchStats,
			setSearchParams,
			fetchThemes,
			handleError,
			intializeDatesFilter
		]
	);

	useEffect(() => {
		// Ce useEffect est là pour relancer
		// la fonction initalize lorsque l'on a changé d'établissement en
		// modifiant une valeur dans le state qui va trigger le useEffect ci dessous
		if (!company) {
			return;
		}

		if (company.id !== parseInt(id, 10)) {
			setActiveTabIndex(
				searchParams.has('activeTabIndex')
					? parseInt(searchParams.get('activeTabIndex')!, 10)
					: 0
			);
			setIsReady(false);
		}
	}, [company, id, isReady, isLoading, searchParams, setIsReady]);

	useEffect(() => {
		if (!isReady && !isLoading) {
			setError(null);
			setIsLoading(true);
			initialize(id, activeTabIndex, searchParams);
		}
	}, [
		id,
		initialize,
		activeTabIndex,
		isReady,
		isLoading,
		searchParams,
		setError
	]);

	const updateStats = useCallback(
		async (
			datesFilter: DatesFilterType | null,
			tabIndex: number,
			options: {reset: boolean}
		) => {
			if (!themes || !datesFilter) {
				return;
			}

			const indicators = themes[tabIndex].indicators
				.map(({id}) => id)
				.filter((id) => (options.reset ? true : !(id in stats)));

			setActiveTabIndex(tabIndex);

			try {
				await fetchStats(
					company?.corporations_ids,
					indicators,
					datesFilter.start.date,
					datesFilter.end.date,
					options
				);

				const searchParams = new URLSearchParams({
					start: formatDateISO(datesFilter.start.date),
					end: formatDateISO(datesFilter.end.date),
					activeTabIndex: tabIndex.toString()
				});
				setSearchParams(searchParams);
			} catch (e) {
				handleError(e);
			}
		},
		[
			company?.corporations_ids,
			fetchStats,
			handleError,
			setSearchParams,
			stats,
			themes
		]
	);

	const handleTabChange = useCallback(
		(index: number) => {
			updateStats(datesFilter, index, {reset: false});
		},
		[datesFilter, updateStats]
	);

	const handleDatesFilterChange = useCallback(
		(event: SelectChangeEvent<number>, key: 'start' | 'end') => {
			const result = onChange(event, key);

			updateStats(result?.values, activeTabIndex, {reset: true});
		},
		[activeTabIndex, onChange, updateStats]
	);

	const [from] = useState(state?.from ?? '/');

	if (!company) {
		return null;
	}

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(6)
			})}
		>
			<Grid item container wrap="nowrap" gap={1} alignItems={'center'}>
				<Button
					variant="outlined"
					to={from}
					component={Link}
					startIcon={<ArrowBackOutlinedIcon />}
					disabled={isLoading}
				>
					{translate('corporation.detail.link.back')}
				</Button>
				<PageHeader title={company?.name} icon={<SchoolOutlinedIcon />} />
				<Button
					variant="outlined"
					onClick={handleOpenModal}
					startIcon={<PictureAsPdfOutlinedIcon aria-hidden="true" />}
				>
					{translate('corporation.report.export')}
				</Button>
				<ExportCompanyModal
					company={company}
					searchParams={searchParams}
					open={openModal}
					onClose={handleCloseModal}
				/>
			</Grid>

			{themes && !error && (
				<Tabs
					activeTabIndex={activeTabIndex}
					handleTabChange={handleTabChange}
					themes={themes}
					isLoading={isLoading}
				/>
			)}

			{error ? (
				<ErrorMessage text={error} />
			) : (
				<LoadingContainer
					isLoading={isObjectEmpty(stats) || isStatsLoading}
					sx={{
						marginTop: '10rem !important',
						height: '100% !important'
					}}
				>
					{!!datesFilter && (
						<DatesFilter
							options={options}
							handleChange={handleDatesFilterChange}
							datesFilter={datesFilter}
						/>
					)}
					<Box>
						{themes?.map((theme, index) => (
							<TabPanel
								key={`corporation-tabpanel-${index}`}
								value={activeTabIndex}
								index={index}
							>
								<DashboardGrid
									key={company.id}
									indicators={theme.indicators}
									stats={stats}
									datesFilter={datesFilter}
									corporationsIds={company.corporations_ids}
									activeTabIndex={activeTabIndex}
									resource="companies"
									resourceId={company.id}
								/>
							</TabPanel>
						))}
					</Box>
				</LoadingContainer>
			)}
		</Box>
	);
};

export default CompanyDetailPage;

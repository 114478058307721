import {stringify} from 'query-string';
import {useRef, useState} from 'react';
import {HttpError} from 'react-admin';
import {apiUrl, httpClient} from '../../api';
import {Stats} from '../../charts/models/types';
import {getQueryDatesRange} from './utils';

type UseGetStats = () => [
	stats: Stats,
	fetchStats: (
		corporationsIds: number | number[] | undefined,
		indicators_ids: number[],
		startDate: Date,
		endDate: Date,
		options?: Options
	) => Promise<void>,
	isLoading: boolean
];

type Options = {
	reset: boolean;
};

const useGetStats: UseGetStats = () => {
	const [stats, setStats] = useState<Stats>({});
	const [isLoading, setIsLoading] = useState(false);

	const fetchStats = useRef(
		async (
			corporationsIds: number | number[] | undefined,
			indicators_ids: number[],
			startDate: Date,
			endDate: Date,
			options?: Options
		) => {
			setIsLoading(true);

			const queryDatesRange = getQueryDatesRange(startDate, endDate);
			const params = {
				...queryDatesRange,
				corporations_ids: Array.isArray(corporationsIds)
					? corporationsIds
					: [corporationsIds]
			};
			try {
				const result = await Promise.all([
					...indicators_ids.map((id) => {
						return httpClient(
							apiUrl(
								`/indicators/${id}/statistics?${stringify(params, {
									arrayFormat: 'bracket'
								})}`
							)
						).then(
							({json}) => ({id, ...json}),
							(reason: any) => {
								if (reason instanceof HttpError) {
									return {
										id,
										error: reason.body.errors.message
									};
								}
							}
						);
					})
				]);
				const newStats = result.reduce(
					(previous, current) => ({
						...previous,
						[current.id]: current.error
							? {error: current.error}
							: current.data
					}),
					{}
				);
				setStats((prevStats) =>
					options?.reset ? newStats : {...prevStats, ...newStats}
				);
				setIsLoading(false);
			} catch (e) {
				setIsLoading(false);
				throw e;
			}
		}
	).current;

	return [stats, fetchStats, isLoading];
};

export default useGetStats;

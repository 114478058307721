import {useEffect, useState} from 'react';
import {useStore} from 'react-admin';
import {ChartsLabelsColorsStoreType} from '../models/types';

export const useLabelsColor = (
	initialValue: ChartsLabelsColorsStoreType | null = null
) => {
	const [color, setColor] = useState<ChartsLabelsColorsStoreType | null>(
		initialValue
	);
	const [chartsLablsStore] = useStore('charts.labels.colors');
	useEffect(() => {
		setColor(chartsLablsStore);
	}, [chartsLablsStore]);

	return color;
};

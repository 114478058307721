import {useTranslate} from 'react-admin';

const HugeCurrencyNumber = ({
	value,
	hugeFontSize
}: {
	value: number;
	hugeFontSize: string | number | undefined;
}) => {
	const translate = useTranslate();
	return (
		<>
			<span className="huge" style={{fontSize: hugeFontSize}}>
				{(value / 100 / 10)
					.toFixed(value < 10_000 ? 1 : 0)
					.replace(/\./g, ',')}
			</span>
			<span className="small" style={{fontSize: hugeFontSize}}>
				&nbsp;
				{translate('charts.table.symbols.kilo')}
				{translate('charts.table.symbols.currency.euro')}
			</span>
		</>
	);
};

export default HugeCurrencyNumber;

import {
	CartesianGrid,
	LineChart,
	Line as RechartsLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import {COLORS, ChartSizes, LineStats, NumberTuple} from '../models/types';
import {getHeightInPixels} from '../utils/utils';

const formatData = (data: LineStats) =>
	Object.entries(data)
		.filter(([key]) => key !== 'all')
		.map(([key, value]) => {
			const dataKeys =
				typeof value === 'number'
					? {total: parseFloat(value.toFixed(2))}
					: Object.values(value)
							.map(({label, rule}) => ({
								[label]: parseFloat(rule.toFixed(2))
							}))
							.reduce((acc, prev) => Object.assign(acc, prev), {});
			return Object.assign(
				{
					name: key
				},
				dataKeys
			);
		});

interface LineProps {
	data: LineStats;
	size: ChartSizes;
	domain?: NumberTuple | null;
}

const Line = ({data, size, domain}: LineProps) => {
	const formattedData = formatData(data);

	let dataKeys: string[] = [];
	formattedData.forEach((value) => {
		Object.keys(value).filter((name) => {
			if (name !== 'name' && !dataKeys.includes(name)) {
				dataKeys.push(name);
			}
		});
	});

	return (
		<div style={{width: '100%', height: getHeightInPixels(size)}}>
			<ResponsiveContainer>
				<LineChart
					data={formattedData}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis
						domain={[0, 'auto']}
						allowDecimals={false}
						interval={'preserveStartEnd'}
						allowDataOverflow={false}
					/>
					<Tooltip />
					{dataKeys.map((key, index) => (
						<RechartsLine
							isAnimationActive={false}
							key={`line-chart-${key}-${index}`}
							type="monotone"
							dataKey={key}
							strokeWidth={3}
							stroke={COLORS[index]}
							fill={COLORS[index]}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Line;

import {RaRecord} from 'ra-core';
import {
	AutocompleteArrayInput,
	Datagrid,
	EditButton,
	ReferenceArrayInput,
	ReferenceField,
	TextField,
	TextInput
} from 'react-admin';
import CustomList from '../common/CustomList';

const filterToQuery = (searchText: string) => ({name: `%${searchText}%`});

const filters = [
	<TextInput source="label" />,
	<ReferenceArrayInput
		source="heading_id"
		reference="headings"
		sort={{field: 'name', order: 'ASC'}}
	>
		<AutocompleteArrayInput
			optionText="name"
			sx={{minWidth: '300px', flexWrap: 'nowrap'}}
			filterToQuery={filterToQuery}
			debounce={500}
		/>
	</ReferenceArrayInput>,
	<TextInput source="label_to_display" />
];
const ValueList = () => (
	<CustomList filters={filters} sort={{field: 'label', order: 'ASC'}}>
		<Datagrid size="small" bulkActionButtons={false}>
			<TextField source="label" />
			<TextField source="label_to_display" />
			<TextField source="code" />
			<ReferenceField
				source="heading_id"
				reference="headings"
				sortable={false}
				link={(record: RaRecord, reference: string) =>
					`/${reference}/${record.heading_id}/show`
				}
			>
				<TextField source="name" />
			</ReferenceField>
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default ValueList;

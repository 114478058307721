import {Box, Grid} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {RaRecord} from 'ra-core';
import {ReactElement} from 'react';
import {Show, ShowProps, useShowController, useTranslate} from 'react-admin';
import BackButton from './BackButton';
import Title from './Title';

const CustomShow = <RecordType extends RaRecord = any>(
	props: ShowProps<RecordType> & {children: ReactElement}
): ReactElement => {
	const {defaultTitle, resource} = useShowController();
	const translate = useTranslate();

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(2)
			})}
		>
			<Grid container alignItems="center" spacing={3}>
				<Grid item>
					<BackButton
						label={translate(`resources.${resource}.backLink`)}
						title={translate(`resources.${resource}.backLinkTitle`)}
						target={`/${resource}`}
					/>
				</Grid>
				<Grid item>
					<Title title={defaultTitle} resourceName={resource} />
				</Grid>
			</Grid>

			<Show {...props} />
		</Box>
	);
};

export default CustomShow;

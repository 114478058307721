import CloseIcon from '@mui/icons-material/Close';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography
} from '@mui/material';
import {useCallback} from 'react';
import {useTranslate} from 'react-admin';
import {
	Indicator as IndicatorType,
	RawData
} from '../../resources/indicators/types';
import ErrorMessage from './ErrorMessage';
import LoadingContainer from './LoadingContainer';

interface RawDataDialogProps {
	isOpen: boolean;
	isLoading: boolean;
	indicator: IndicatorType;
	rawData: RawData[];
	error: string | null;
	onClose: () => void;
}
const RawDataDialog = (props: RawDataDialogProps) => {
	const translate = useTranslate();
	const {onClose, isOpen, indicator, rawData, isLoading, error} = props;
	const {id, name} = indicator;

	const closeDialog = useCallback(() => {
		onClose();
	}, [onClose]);

	const handleDialogClose = useCallback(
		(_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
			if (reason === 'escapeKeyDown') {
				closeDialog();
			}
		},
		[closeDialog]
	);

	return (
		<Dialog
			onClose={handleDialogClose}
			open={isOpen}
			fullWidth
			maxWidth="lg"
			PaperProps={{sx: {padding: '2rem 1.5rem'}}}
		>
			<Grid container justifyContent="flex-end">
				<IconButton onClick={closeDialog} size="small">
					<CloseIcon />
				</IconButton>
			</Grid>
			<DialogTitle
				component="h1"
				sx={{
					padding: '0 3rem',
					marginBottom: '1rem',
					textAlign: 'center'
				}}
			>
				<Typography
					component="span"
					sx={(theme: Theme) => ({
						fontSize: '1rem',
						fontWeight: '500',
						lineHeight: 1.2,
						color: theme.palette.text.primary
					})}
				>
					<br />
					{translate('corporation.rawData.title', {name})}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{error ? (
					<ErrorMessage text={error} />
				) : (
					<LoadingContainer
						isLoading={isLoading}
						sx={{
							marginTop: '10rem !important',
							height: '100% !important'
						}}
					>
						<TableContainer
							sx={{
								borderRadius: '15px',
								border: '1px solid rgba(224, 224, 224, 1)'
							}}
						>
							<Table
								sx={{minWidth: 650}}
								size="medium"
								aria-label={`indicator-title-${id}`}
								stickyHeader
							>
								<TableHead>
									<TableRow>
										<TableCell>
											{translate(
												'corporation.rawData.table.matricule'
											)}
										</TableCell>
										<TableCell align="center">
											{translate('corporation.rawData.table.status')}
										</TableCell>
										<TableCell align="center">
											{translate(
												'corporation.rawData.table.workingHours'
											)}
										</TableCell>
										<TableCell align="center">
											{translate(
												'corporation.rawData.table.startDate'
											)}
										</TableCell>
										<TableCell align="center">
											{translate(
												'corporation.rawData.table.endDate'
											)}
										</TableCell>
										<TableCell>
											{translate('corporation.rawData.table.sex')}
										</TableCell>
										<TableCell align="center" sx={{padding: '2rem'}}>
											{translate(
												'corporation.rawData.table.birthDate'
											)}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rawData.map((row, index) => (
										<TableRow
											key={index}
											sx={{
												'&:last-child td, &:last-child th': {
													border: 0
												}
											}}
										>
											<TableCell
												component="th"
												scope="row"
												align="center"
											>
												{row?.matricule}
											</TableCell>
											<TableCell align="center">
												{row.status}
											</TableCell>
											<TableCell align="center">
												{row.temps}
											</TableCell>
											<TableCell align="center">
												{row.start_date ?? '-'}
											</TableCell>
											<TableCell align="center">
												{row.end_date ?? '-'}
											</TableCell>
											<TableCell align="center">
												{row.sexe}
											</TableCell>
											<TableCell align="center">
												{row.birth_date}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</LoadingContainer>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default RawDataDialog;

import ApartmentIcon from '@mui/icons-material/Apartment';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import {Button, Grid} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useState} from 'react';
import {useTranslate} from 'react-admin';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Corporation} from '../../resources/corporations/types';
import CorporationSwitcher from './CorporationSwitcher';
import './CorporationSwitcher.css';
import ExportCorporationModal from './ExportCorporationModal';
import {DatesFilter} from './types';
import {formatDateISO} from './utils';

interface CorporationDetailHeaderProps {
	corporation: Corporation;
	hasDeclarations: boolean;
	searchParams: URLSearchParams;
	datesFilter: DatesFilter | null;
}

const CorporationDetailHeader = ({
	corporation,
	hasDeclarations,
	searchParams,
	datesFilter
}: CorporationDetailHeaderProps) => {
	const translate = useTranslate();
	const {pathname, search} = useLocation();
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);

	const redirectToCompagny = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		const currentPath = `${pathname}${search}`;
		navigate(`${event.currentTarget.pathname}${event.currentTarget.search}`, {
			state: {from: currentPath},
			replace: true
		});
	};

	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	return (
		<Grid
			container
			justifyContent={'space-between'}
			alignItems="flex-start"
			spacing={4}
		>
			<Grid item>
				<Grid container alignItems="center" justifyContent={'start'}>
					<Grid item>
						<ApartmentIcon
							sx={(theme: Theme) => ({
								color: theme.palette.primary.main,
								mt: '0.5rem',
								display: 'block',
								mr: '0.25rem'
							})}
						/>
					</Grid>
					<Grid item>
						<CorporationSwitcher corporation={corporation} />
					</Grid>
				</Grid>
				{hasDeclarations && !!corporation?.company_id && !!datesFilter && (
					<Grid item>
						<Button
							to={`/companies/${
								corporation?.company_id
							}/detail?${new URLSearchParams({
								start: formatDateISO(datesFilter.start.date),
								end: formatDateISO(datesFilter.end.date)
							})}`}
							onClick={redirectToCompagny}
							color="primary"
							component={Link}
							variant="text"
							startIcon={<SchoolOutlinedIcon aria-hidden="true" />}
							title={`_${translate(
								'corporation.detail.action.schoolGroup'
							)} _ ${corporation.name}`}
						>
							{translate('corporation.detail.action.schoolGroup')}
						</Button>
					</Grid>
				)}
			</Grid>
			<Grid item>
				<Grid container alignItems="center" gap={1}>
					{hasDeclarations &&
						!!corporation?.company_id &&
						!!datesFilter && (
							<>
								<Button
									onClick={handleOpenModal}
									variant="outlined"
									startIcon={
										<PictureAsPdfOutlinedIcon aria-hidden="true" />
									}
								>
									{translate('corporation.report.export')}
								</Button>
							</>
						)}
					<Grid item>
						<Button
							to={`/corporations/${corporation?.id}/declarations?${searchParams}`}
							color="primary"
							component={Link}
							variant="contained"
							startIcon={<SettingsIcon aria-hidden="true" />}
						>
							{translate('corporation.detail.action.import')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<ExportCorporationModal
				corporation={corporation}
				searchParams={searchParams}
				open={openModal}
				onClose={handleCloseModal}
			/>
		</Grid>
	);
};

export default CorporationDetailHeader;

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {Box, Grid} from '@mui/material';
import {UserMenu} from 'react-admin';
import {ReactComponent as FicLogo} from './fic-logo.svg';

const CustomAppBar = () => (
	<Box
		sx={{
			padding: '0.5rem 1rem',
			position: {
				sm: 'relative',
				md: 'sticky'
			},
			top: 0,
			zIndex: 10,
			backgroundColor: '#f8f6f3'
		}}
	>
		<Grid container justifyContent={'space-between'} alignItems="center">
			<Grid item>
				<FicLogo style={{display: 'block'}} />
			</Grid>
			<Grid item>
				<UserMenu icon={<AccountCircleOutlinedIcon />} />
			</Grid>
		</Grid>
	</Box>
);

export default CustomAppBar;

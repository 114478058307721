import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {visuallyHidden} from '@mui/utils';
import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {useNotify, useRedirect, useTranslate} from 'react-admin';
import {useSearchParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {submitResetPasswordResquest} from '../../api';
import {ReactComponent as FicLogo} from '../../layout/fic-logo.svg';
import {ReactComponent as LoginIllustration} from '../login-illustration.svg';

const ResetPasswordPage = () => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const [searchParams] = useSearchParams();

	const [newPwd, setNewPwd] = useState({
		password: '',
		confirmPassword: '',
		token: searchParams.get('token')
	});

	const isConfirmPasswordValid =
		!newPwd.password ||
		newPwd.confirmPassword !== newPwd.password ||
		!newPwd.token;

	const handleChange = ({currentTarget}: ChangeEvent<HTMLInputElement>) => {
		const {value, name} = currentTarget;
		setNewPwd({...newPwd, [name]: value});
	};

	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = useCallback(() => {
		setShowPassword((showPassword) => !showPassword);
	}, [setShowPassword]);

	const [errors, setErrors] = useState({
		message: '',
		isError: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);

		const {message, isError} = await submitResetPasswordResquest({
			password: newPwd.password,
			token: newPwd.token
		});

		if (isError) {
			setErrors({message, isError});
			setIsSubmitting((isSubmitting) => !isSubmitting);
			return;
		}

		notify(translate(message), {
			type: 'success'
		});
		redirect('/login');
	};

	return (
		<div className="LoginPage">
			<div className="LoginPage-leftAside">
				<LoginIllustration
					className="LoginPage-illustration"
					role="img"
					aria-hidden="true"
				/>
			</div>
			<div className="LoginPage-rightMain">
				<form onSubmit={handleSubmit} className="LoginPage-form">
					<h1 style={visuallyHidden}>
						{translate('resetPassword.label.legend')}
					</h1>

					<FicLogo className="LoginPage-ficLogo" />

					<fieldset className="LoginPage-fieldset">
						<legend>
							<Typography
								component="span"
								sx={(theme: Theme) => ({
									display: 'block',
									marginBottom: '1rem',
									color: theme.palette.primary.main,
									fontSize: '1.5rem'
								})}
							>
								{translate('resetPassword.legend')}
							</Typography>
						</legend>

						<TextField
							sx={{marginTop: 0, marginBottom: '1rem'}}
							type={showPassword ? 'text' : 'password'}
							name="password"
							value={newPwd.password}
							onChange={handleChange}
							required
							label={translate('resetPassword.label.password')}
							variant="filled"
							size="medium"
							fullWidth
							error={errors.isError}
							helperText={errors.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={togglePasswordVisibility}
											edge="end"
											title={translate('login.password.toggle.show')}
										>
											<span style={visuallyHidden}>
												{translate('login.password.toggle.hidden')}
											</span>
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						<TextField
							sx={{marginTop: 0, marginBottom: '1rem'}}
							type={showPassword ? 'text' : 'password'}
							name="confirmPassword"
							value={newPwd.confirmPassword}
							onChange={handleChange}
							required
							label={translate('resetPassword.label.confirmpassword')}
							variant="filled"
							size="medium"
							fullWidth
							error={errors.isError}
						/>
					</fieldset>
					<Box
						sx={{
							display: 'flex',
							alignContent: 'center',
							flexDirection: 'column'
						}}
					>
						<Button
							disabled={isSubmitting || isConfirmPasswordValid}
							type="submit"
							variant="contained"
							sx={{
								width: 'fit-content',
								padding: '0.75rem 4rem',
								borderRadius: '12px',
								alignSelf: 'flex-end',
								marginBottom: '1.5rem'
							}}
						>
							{translate('resetPassword.label.submit')}
						</Button>
						<Button
							component={Link}
							to="/login"
							variant="text"
							sx={{
								width: 'fit-content',
								alignSelf: 'flex-end'
							}}
						>
							{translate('login.label.submit')}
						</Button>
					</Box>
				</form>
			</div>
		</div>
	);
};

export default ResetPasswordPage;

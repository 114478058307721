import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {useEffect, useRef} from 'react';
import {Loading, useGetOne, useRedirect, useTranslate} from 'react-admin';
import {useParams} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';
import ChartRenderer from '../../charts/components/ChartRenderer';
import {ChartSizes, GraphicTypes} from '../../charts/models/types';
import {Indicator} from '../../resources/indicators/types';
import ErrorMessage from './ErrorMessage';
import ExplodedIndicator from './ExplodedIndicator';
import PageHeader from './PageHeader';
import useErrorHandler from './useErrorHandler';
import useGetStats from './useGetStats';
import {isObjectEmpty} from './utils';

const IndicatorDetailPage = () => {
	const {resource, resourceId, indicatorId} = useParams() as {
		resource: string;
		resourceId: string;
		indicatorId: string;
	};
	const [searchParams] = useSearchParams();
	const redirect = useRedirect();
	const {error, handleError} = useErrorHandler();
	const {data: indicator, isLoading: isIndicatorLoading} =
		useGetOne<Indicator>(
			'indicators',
			{id: parseInt(indicatorId, 10)},
			{onError: () => redirect('/'), refetchOnWindowFocus: false}
		);
	const translate = useTranslate();
	const startDate = searchParams.get('start');
	const endDate = searchParams.get('end');
	const corporationsIds: number[] = useRef(
		searchParams.getAll('corporationsIds').map((id) => parseInt(id, 10))
	).current;
	const [stats, fetchStats] = useGetStats();

	useEffect(() => {
		if (startDate && endDate && indicator) {
			fetchStats(
				corporationsIds,
				[indicator.id],
				new Date(startDate),
				new Date(endDate)
			).catch(handleError);
		}
	}, [
		fetchStats,
		corporationsIds,
		indicator,
		startDate,
		endDate,
		handleError
	]);

	if (isIndicatorLoading || isObjectEmpty(stats)) {
		return <Loading />;
	}

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(2),
				maxWidth: '125rem'
			})}
		>
			<PageHeader
				backLink={{
					label: translate('corporation.indicator.link.back'),
					title: translate('corporation.indicator.link.backTitle'),
					target: `/${resource}/${resourceId}/detail?${searchParams}`
				}}
				title={indicator?.name}
			/>

			<Card
				sx={(theme: Theme) => ({
					marginTop: theme.spacing(2),
					animation: `slideUp 0.5s 0.2s ease-out both`
				})}
			>
				<CardContent sx={{padding: '60px !important'}}>
					<Box sx={{marginBottom: '2rem'}}>
						{!!startDate && !!endDate && (
							<Typography component="h2" sx={{fontSize: '1.1rem'}}>
								{translate('corporation.indicator.mainChart.title', {
									startDate: new Intl.DateTimeFormat('fr-FR', {
										month: 'long',
										year: 'numeric'
									}).format(new Date(startDate)),
									endDate: new Intl.DateTimeFormat('fr-FR', {
										month: 'long',
										year: 'numeric'
									}).format(new Date(endDate))
								})}
							</Typography>
						)}
					</Box>
					{error ? (
						<ErrorMessage text={error} />
					) : (
						<>
							{indicator && stats && (
								<Grid
									container
									justifyContent={'space-between'}
									alignItems="flex-start"
									spacing={4}
									marginBottom={4}
								>
									<Grid item md={9}>
										<>
											{stats?.[indicator.id] && (
												<ChartRenderer
													size={ChartSizes.large}
													idTitle={`indicator-${indicator.id}`}
													graphicType={
														indicator.graphical_representation
													}
													data={stats?.[indicator.id]}
													legend={
														!(
															GraphicTypes.bar_gradient_colors ===
															indicator.graphical_representation
														)
													}
													indicator={indicator}
												/>
											)}
										</>
									</Grid>
									{!!indicator.description && (
										<Grid item md={3}>
											<Typography
												component="h3"
												sx={(theme: Theme) => ({
													color: theme.palette.text.secondary
												})}
											>
												{translate(
													'corporation.indicator.description'
												)}
											</Typography>
											<Typography
												component="div"
												dangerouslySetInnerHTML={{
													__html: indicator.description
												}}
											></Typography>
										</Grid>
									)}
								</Grid>
							)}
							{indicator &&
								!isObjectEmpty(stats?.[indicator.id]) &&
								indicator?.value_group_type_ids?.length > 0 && (
									<ExplodedIndicator
										indicator={indicator}
										corporationsIds={corporationsIds}
										startDate={startDate}
										endDate={endDate}
									/>
								)}
						</>
					)}
				</CardContent>
			</Card>
		</Box>
	);
};

export default IndicatorDetailPage;

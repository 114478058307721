import {SelectInput, useTranslate} from 'react-admin';

interface SelectBooleanInputProps {
	source: string;
}

type Choice = {id: boolean};
const choices: Choice[] = [{id: true}, {id: false}];

const SelectBooleanInput = ({source}: SelectBooleanInputProps) => {
	const translate = useTranslate();

	return (
		<SelectInput
			source={source}
			choices={choices}
			optionText={({id}: Choice) =>
				translate(`common.selectBooleanInput.${id}`)
			}
		/>
	);
};

export default SelectBooleanInput;

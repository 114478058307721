export const fr = {
	name: 'Utilisateur |||| Utilisateurs',
	fields: {
		id: 'ID',
		firstname: 'Prénom',
		lastname: 'Nom',
		username: 'Email (exemple: jean@dupont.fr)',
		roles: 'Rôles',
		password: 'Mot de passe',
		corporations: 'Etablissements'
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des utilisateurs',
	helpers: {
		role: "Cliquer sur un rôle dans la liste pour l'ajouter ou le retirer. Les rôles sélectionnés apparaissent avec un fond coloré.",
		corporations:
			'Liste en lecture seule. Le rattachement utilisateur/établissement se gère sur le formulaire établissement.'
	},
	user: {
		entity: {
			create: {
				success: 'Le nouveau utilisateur a bien été créé.'
			}
		}
	}
};

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

interface BackButtonProps {
	label: string;
	title?: string;
	target: string;
}

const BackButton = ({label, title, target}: BackButtonProps) => (
	<Button
		color="primary"
		component={Link}
		variant="outlined"
		to={target}
		title={title}
		startIcon={<ArrowBackOutlinedIcon />}
	>
		{label}
	</Button>
);

export default BackButton;

import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useStore, useTranslate} from 'react-admin';
import {ChartSizes, TableStats} from '../models/types';
import {getHeightInPixels} from '../utils/utils';

interface TableValuesProps {
	idTitle: string;
	data: TableStats;
	size: ChartSizes;
	fontSize?: string | number;
	padding?: string | number;
}
const TableValues = ({
	data,
	idTitle,
	size,
	fontSize,
	padding
}: TableValuesProps) => {
	const [printable] = useStore('printable.page');
	const translate = useTranslate();

	const formattedData = Object.entries(data['all']).map(
		([key, {label, rule}]) => {
			let value = parseFloat(rule.toString());

			let decimalPart = value - Math.floor(value);
			let result;

			if (decimalPart < 0.5) {
				value = Math.floor(value);
				result = value.toFixed(0);
			} else if (decimalPart > 0.5) {
				value = Math.ceil(value);
				result = value.toFixed(0);
			} else {
				result = value.toFixed(2);
			}

			return {
				key: label,
				value: result.toString()
			};
		}
	);

	return (
		<TableContainer
			sx={{
				borderRadius: '15px',
				border: '1px solid rgba(224, 224, 224, 1)',
				maxHeight: printable ? 'auto' : getHeightInPixels(size)
			}}
		>
			<MuiTable aria-labelledby={idTitle} stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell
							scope="col"
							sx={{fontSize: fontSize, padding: padding}}
						>
							{translate('charts.table.thead.label')}
						</TableCell>
						<TableCell
							scope="col"
							sx={{
								textAlign: 'center',
								fontSize: fontSize,
								padding: padding
							}}
						>
							{translate('charts.table.thead.value')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{formattedData.map(({key, value}, index) => (
						<TableRow
							key={`table-stats-${index}`}
							sx={{'&:last-child td, &:last-child th': {border: 0}}}
						>
							<TableCell
								component="th"
								scope="row"
								sx={{fontSize: '0.85rem', padding: padding}}
							>
								{key}
							</TableCell>
							<TableCell sx={{textAlign: 'center', padding: padding}}>
								{value}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default TableValues;

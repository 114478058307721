import {Typography, TypographyProps} from '@mui/material';
import {styled} from '@mui/material/styles';

const IndicatorTitle = styled((props: TypographyProps) => (
	<Typography variant="h3" {...props} />
))(
	() => `
	position: relative;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.6;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0 44px;
`
);

export default IndicatorTitle;

import {Box, Grid, Theme, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {HttpError, Loading, useTranslate} from 'react-admin';
import {useParams, useSearchParams} from 'react-router-dom';
import dataProvider from '../../providers/data';
import {Corporation} from '../../resources/corporations/types';
import {Indicator} from '../../resources/indicators/types';
import ErrorMessage from './ErrorMessage';
import IndicatorsCharts from './IndicatorsCharts';
import IndicatorsCheckboxes from './IndicatorsCheckboxes';
import PageHeader from './PageHeader';
import {ThemeEnhancedWithIndicators} from './types';
import useErrorHandler from './useErrorHandler';
import useGetStats from './useGetStats';
import useGetThemes from './useGetThemes';
import {
	formatPeriodAsText,
	sortIndicatorsByThemeAndSelfPositions
} from './utils';

const CorporationComparePage = () => {
	const {id} = useParams() as {id: string};
	const [searchParams, setSearchParams] = useSearchParams();

	const translate = useTranslate();
	const [corporation, setCorporation] = useState<Corporation>();

	const {error, handleError} = useErrorHandler();
	const [isReady, setIsReady] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [themes, fetchThemes] = useGetThemes();
	const [expandedThemes, setExpandedThemes] = useState<
		Record<number, boolean>
	>({});
	const [activeIndicators, setActiveIndicators] = useState<Indicator[]>([]);
	const [referenceStats, fetchReferenceStats] = useGetStats();
	const [comparedStats, fetchComparedStats] = useGetStats();

	const initialize = useCallback(
		async (idCorporation: string, searchParams: URLSearchParams) => {
			try {
				if (
					!searchParams.has('referenceStart') ||
					!searchParams.has('referenceEnd') ||
					!searchParams.has('comparedStart') ||
					!searchParams.has('comparedEnd')
				) {
					throw new HttpError('corporation.compare.missingDates', 500);
				}

				const {data: corporation} = await dataProvider.getOne<Corporation>(
					'corporations',
					{
						id: parseInt(idCorporation)
					}
				);

				setCorporation(corporation);

				let themesTabs: ThemeEnhancedWithIndicators[];
				if (!themes) {
					themesTabs = await fetchThemes();
				} else {
					themesTabs = themes;
				}

				const expandedList: Record<number, boolean> = {};
				let activeIndicators: Indicator[] = [];

				if (searchParams.has('indicators')) {
					// On a des indicateurs en query params
					const defaultIndicatorsIds =
						searchParams
							.getAll('indicators')!
							.map((id) => parseInt(id, 10)) ?? [];

					defaultIndicatorsIds.forEach((id) => {
						const themeIndex = themesTabs?.findIndex(
							(theme) =>
								!theme.isSynthesis &&
								theme.indicators.some(
									(indicator) => indicator.id === id
								)
						);
						if (themeIndex !== -1) {
							const indicator = themesTabs?.[themeIndex].indicators.find(
								(indicator) => indicator.id === id
							);
							if (!!indicator) {
								activeIndicators.push(indicator);
								expandedList[themeIndex] = true;
							}
						}
					});
				} else {
					// On initialise avec les indicateurs de la synthèse
					const hasSynthesis = themesTabs?.[0]?.isSynthesis;
					if (hasSynthesis) {
						const synthesis = themesTabs[0];

						synthesis?.indicators?.forEach((indicator) => {
							const themeIndex = themesTabs.findIndex(
								(theme) => theme.id === indicator.theme_id
							);
							if (themeIndex !== -1) {
								activeIndicators.push(indicator);
								expandedList[themeIndex] = true;
							}
						});
					}
				}

				activeIndicators = sortIndicatorsByThemeAndSelfPositions(
					activeIndicators,
					themesTabs
				);

				const referenceStartDate = new Date(
					searchParams.get('referenceStart')!
				);
				const referenceEndDate = new Date(
					searchParams.get('referenceEnd')!
				);
				const comparedStartDate = new Date(
					searchParams.get('comparedStart')!
				);
				const comparedEndDate = new Date(searchParams.get('comparedEnd')!);
				await Promise.all([
					fetchReferenceStats(
						corporation?.id,
						activeIndicators.map(({id}) => id),
						referenceStartDate,
						referenceEndDate
					),

					fetchComparedStats(
						corporation?.id,
						activeIndicators.map(({id}) => id),
						comparedStartDate,
						comparedEndDate
					)
				]);

				setExpandedThemes((state) => ({
					...state,
					...expandedList
				}));
				setActiveIndicators(activeIndicators);
			} catch (e) {
				handleError(e);
			} finally {
				setIsReady(true);
				setIsLoading(false);
			}
		},
		[
			themes,
			fetchReferenceStats,
			fetchComparedStats,
			fetchThemes,
			handleError
		]
	);

	useEffect(() => {
		if (!isReady && !isLoading) {
			setIsLoading(true);
			initialize(id, searchParams);
		}
	}, [id, initialize, isReady, isLoading, searchParams]);

	const handleExpandClick = (index: number) => {
		setExpandedThemes((state) => ({
			...state,
			[index]: !state?.[index] ?? true
		}));
	};

	const toggleIndicator = useCallback(
		async (indicator: Indicator) => {
			const isActive = activeIndicators.find(({id}) => id === indicator.id);
			let indicatorsList: Indicator[] = [];
			if (isActive) {
				// Array.filter doesn't change items' order so no need to re-sort
				indicatorsList = activeIndicators.filter(
					({id}) => id !== indicator.id
				);
			} else {
				indicatorsList = [...activeIndicators, indicator];
				indicatorsList = sortIndicatorsByThemeAndSelfPositions(
					[...activeIndicators, indicator],
					themes!
				);
			}

			const referenceStartDate = new Date(
				searchParams.get('referenceStart')!
			);
			const referenceEndDate = new Date(searchParams.get('referenceEnd')!);
			const comparedStartDate = new Date(searchParams.get('comparedStart')!);
			const comparedEndDate = new Date(searchParams.get('comparedEnd')!);
			await Promise.all([
				fetchReferenceStats(
					corporation?.id,
					indicatorsList
						.map(({id}) => id)
						.filter((id) => !(id in referenceStats)),
					referenceStartDate,
					referenceEndDate
				),

				fetchComparedStats(
					corporation?.id,
					indicatorsList
						.map(({id}) => id)
						.filter((id) => !(id in comparedStats)),
					comparedStartDate,
					comparedEndDate
				)
			]);
			setActiveIndicators(indicatorsList);
			const updatedSearchParams = searchParams;
			updatedSearchParams.delete('indicators');
			indicatorsList.forEach(({id}) => {
				updatedSearchParams.append('indicators', id.toString());
			});
			setSearchParams(updatedSearchParams);
		},
		[
			activeIndicators,
			comparedStats,
			corporation?.id,
			fetchComparedStats,
			fetchReferenceStats,
			referenceStats,
			searchParams,
			setSearchParams,
			themes
		]
	);

	if (isLoading) {
		return <Loading />;
	}

	const referenceStartDate = new Date(searchParams.get('referenceStart')!);
	const referenceEndDate = new Date(searchParams.get('referenceEnd')!);
	const comparedStartDate = new Date(searchParams.get('comparedStart')!);
	const comparedEndDate = new Date(searchParams.get('comparedEnd')!);

	const referencePeriodAsText = formatPeriodAsText(
		referenceStartDate,
		referenceEndDate
	);
	const comparedPeriodAsText = formatPeriodAsText(
		comparedStartDate,
		comparedEndDate
	);

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(2)
			})}
		>
			<PageHeader
				backLink={{
					label: translate('corporation.compare.link.back'),
					title: translate('corporation.compare.link.backTitle'),
					target: `/corporations/${id}/detail`
				}}
				title={corporation?.name}
			/>

			<Typography
				component="h2"
				sx={(theme: Theme) => ({
					marginTop: theme.spacing(2),
					color: theme.palette.text.secondary,
					'span': {
						color: theme.palette.primary.main,
						textTransform: 'capitalize'
					}
				})}
				dangerouslySetInnerHTML={{
					__html: translate('corporation.compare.period.title', {
						reference: referencePeriodAsText,
						compared: comparedPeriodAsText
					})
				}}
			/>

			<Grid
				container
				spacing={4}
				justifyContent="space-between"
				sx={(theme: Theme) => ({
					marginTop: theme.spacing(2)
				})}
			>
				<Grid item lg={3} md={5} sm={12} xs={12}>
					{themes && themes?.length > 0 && (
						<IndicatorsCheckboxes
							themes={themes}
							expandedThemes={expandedThemes}
							activeIndicators={activeIndicators}
							handleExpandClick={handleExpandClick}
							handleIndicatorChange={toggleIndicator}
						/>
					)}
				</Grid>
				<Grid item lg={9} md={7} sm={12} xs={12}>
					{error ? (
						<ErrorMessage text={error} />
					) : (
						<IndicatorsCharts
							activeIndicators={activeIndicators}
							referenceStats={referenceStats}
							comparedStats={comparedStats}
							referenceChartsTitle={referencePeriodAsText}
							comparedChartsTitle={comparedPeriodAsText}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default CorporationComparePage;

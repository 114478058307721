import {useEffect} from 'react';
import {
	AutocompleteInput,
	NumberInput,
	ReferenceInput,
	SimpleForm,
	useNotify
} from 'react-admin';
import useErrorHandler from '../../app/corporation/useErrorHandler';
import CustomCreate from '../common/CustomCreate';
import CustomDateInput from '../common/CustomDateInput';
import RequiredText from '../common/RequiredText';

const ReferenceValueCreate = () => {
	const {error, setError, handleError} = useErrorHandler();
	const notify = useNotify();

	const onError = (e: unknown) => {
		handleError(e);
	};

	useEffect(() => {
		if (error) {
			notify(error, {type: 'error'});
			setError(null);
		}
	}, [error]);
	return (
		<CustomCreate mutationOptions={{onError}}>
			<SimpleForm sx={{maxWidth: 600}}>
				<RequiredText />
				<ReferenceInput source="type_id" reference="reference-value-types">
					<AutocompleteInput
						optionText="label"
						sx={{minWidth: '300px', flexWrap: 'nowrap'}}
						debounce={500}
						suggestionLimit={10}
						filterToQuery={(searchText) => ({label: `%${searchText}%`})}
						fullWidth
					/>
				</ReferenceInput>
				<CustomDateInput
					source="begin"
					required
					fullWidth
					helperText="resources.reference-values.helpers.begin.date"
				/>
				<NumberInput
					source="value"
					required
					fullWidth
					helperText="resources.reference-values.helpers.value"
				/>
				<ReferenceInput source="corporation_id" reference="corporations">
					<AutocompleteInput
						optionText="name"
						sx={{minWidth: '300px', flexWrap: 'nowrap'}}
						debounce={500}
						suggestionLimit={10}
						filterToQuery={(searchText) => ({name: `%${searchText}%`})}
						fullWidth
					/>
				</ReferenceInput>
			</SimpleForm>
		</CustomCreate>
	);
};

export default ReferenceValueCreate;

import {RaRecord} from 'ra-core';
import {
	AutocompleteArrayInput,
	Datagrid,
	DateField,
	EditButton,
	ReferenceArrayInput,
	ReferenceField,
	TextField,
	TextInput
} from 'react-admin';
import CustomList from '../common/CustomList';

const filters = [
	<TextInput source="begin.date" type="number" label="Date de début" />,
	<TextInput source="expiration.date" type="number" label="Date de fin" />,
	<ReferenceArrayInput
		source="theme_id"
		reference="themes"
		sort={{field: 'label', order: 'ASC'}}
	>
		<AutocompleteArrayInput
			source="name"
			optionText="name"
			sx={{minWidth: '300px', flexWrap: 'nowrap'}}
			debounce={500}
			suggestionLimit={10}
			filterToQuery={(searchText) => ({label: `%${searchText}%`})}
		/>
	</ReferenceArrayInput>,

	<ReferenceArrayInput source="corporation_id" reference="corporations">
		<AutocompleteArrayInput
			source="name"
			optionText="name"
			sx={{minWidth: '300px', flexWrap: 'nowrap'}}
			debounce={500}
			suggestionLimit={10}
			filterToQuery={(searchText) => ({name: `%${searchText}%`})}
		/>
	</ReferenceArrayInput>
];

const AnalyzeList = () => (
	<CustomList filters={filters} sort={{field: 'id', order: 'ASC'}}>
		<Datagrid size="small" bulkActionButtons={false}>
			<DateField
				source="begin"
				sortable={false}
				options={{year: 'numeric', month: '2-digit', day: '2-digit'}}
			/>
			<DateField
				source="expiration"
				sortable={false}
				options={{year: 'numeric', month: '2-digit', day: '2-digit'}}
			/>
			<ReferenceField source="theme_id" reference="themes">
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				source="corporation_id"
				reference="corporations"
				link={(record: RaRecord, reference: string) => {
					console.log(record);
					return `/${reference}/${record.corporation_id}/show`;
				}}
			>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField source="user_id" reference="users">
				<TextField source="username" />
			</ReferenceField>
			<DateField
				source="updated"
				sortable={false}
				options={{year: 'numeric', month: '2-digit', day: '2-digit'}}
			/>
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default AnalyzeList;

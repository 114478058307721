import {Datagrid, EditButton, TextField, TextInput} from 'react-admin';
import CustomList from '../common/CustomList';

const filters = [<TextInput source="name" />];

const CompanyList = () => (
	<CustomList filters={filters} sort={{field: 'name', order: 'ASC'}}>
		<Datagrid size="small">
			<TextField source="name" />
			<TextField source="registration" />
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default CompanyList;

import {stringify} from 'query-string';
import {useCallback, useState} from 'react';
import {ApiResponse, apiUrl, httpClient} from '../../api';
import {RawData} from '../../resources/indicators/types';

const fetchRawDataApi = async (
	id: number,
	corporationsIds: number[],
	start: string,
	end: string
) => {
	const params = {
		start,
		end,
		corporations_ids: corporationsIds
	};
	const {json} = await httpClient<ApiResponse<{data: RawData[]}>>(
		apiUrl(
			`/indicators/${id}/statistics/rawdata?${stringify(params, {
				arrayFormat: 'bracket'
			})}`
		)
	);

	return json.data;
};

const useGetRawData = () => {
	const [rawData, setRawData] = useState<RawData[]>([]);

	const fetchRawData = useCallback(
		async (
			id: number,
			corporationsIds: number[],
			start: string,
			end: string
		) => {
			const rawData = await fetchRawDataApi(id, corporationsIds, start, end);
			setRawData(rawData);
		},
		[]
	);

	return [rawData, fetchRawData] as const;
};

export default useGetRawData;

import {Box} from '@mui/material';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import {ChartSizes, NumberTuple, StackedBarStats} from '../models/types';
import {getHeightInPixels} from '../utils/utils';
import {useLabelsColor} from './useLabelsColor';

interface StackedMixBarProps {
	data: StackedBarStats;
	size: ChartSizes;
	idTitle: string;
	domain?: NumberTuple | null;
}
/** Autre nature de contrat, convention, mandat */
const StackedMixBar = ({data, size, idTitle, domain}: StackedMixBarProps) => {
	const colors = useLabelsColor();

	const entries = Object.entries(data);
	const formattedData = entries
		.flatMap(([key, value]) => {
			const mixedBar = Object.assign(
				{},
				...Object.keys(value).map((k) =>
					k && value[k] !== null
						? Object.entries(value[k])
								.map(([_, {label, rule}]) =>
									Object.fromEntries([
										[label, hasDecimal(rule) ? rule.toFixed(2) : rule]
									])
								)
								.reduce((prev, curr) => {
									const key = Object.keys(curr)[0];
									return {
										...prev,
										...{[`${k}\n${key}`.trim()]: curr[key]}
									};
								}, {})
						: {}
				)
			);
			return {
				...(key !== 'all' && {
					name: key,
					...mixedBar
				})
			};
		})
		.filter((value) => JSON.stringify(value) !== '{}');

	const label = formattedData
		.flatMap((value) => Object.keys(value))
		.map((key) => key);
	const legends = [...new Set(label)];

	return (
		<>
			<div style={{height: getHeightInPixels(size)}}>
				<ResponsiveContainer>
					<BarChart data={formattedData} maxBarSize={40}>
						<CartesianGrid strokeDasharray="3 3" />
						<YAxis
							domain={domain ?? [0, 'auto']}
							allowDecimals={false}
							interval={'preserveStartEnd'}
							allowDataOverflow={true}
						/>
						<XAxis dataKey="name" fontSize={16} />
						<Tooltip
							contentStyle={{maxWidth: '15rem', whiteSpace: 'unset'}}
						/>
						{Object.keys(
							formattedData
								.map((value) => value)
								.reduce((prev, acc) => ({...prev, ...acc}), {})
						).map(
							(key, index) =>
								key !== 'name' && (
									<Bar
										key={index}
										stackId={key.split('\n')[0]}
										dataKey={key}
										name={key}
										fill={
											colors?.mix_bar_2?.[idTitle]?.[
												key.replace(/\n/g, ' ')
											]
										}
										minPointSize={0}
										isAnimationActive={false}
									></Bar>
								)
						)}
					</BarChart>
				</ResponsiveContainer>
			</div>
			<Box
				sx={{
					position: 'relative',
					margin: '40px 0 0',
					display: 'grid',
					gridTemplateColumns: 'repeat(1, 1fr)',
					maxWidth: 'max-content',
					columnGap: '1rem'
				}}
			>
				{legends.map(
					(value, index) =>
						value !== 'name' && (
							<Legend
								key={index}
								wrapperStyle={{
									position: 'relative',
									fontSize: '0.85rem',
									maxWidth: 'max-content'
								}}
								align="right"
								layout="vertical"
								payload={[
									{
										value: value,
										color: colors?.mix_bar_2?.[idTitle]?.[
											value.replace(/\n/g, ' ')
										],
										type: 'rect',
										id: `${index}`
									}
								]}
							/>
						)
				)}
			</Box>
		</>
	);
};

export default StackedMixBar;

function hasDecimal(number: number): boolean {
	return number !== Math.floor(number);
}

import {useState} from 'react';
import {GetListParams, useTranslate} from 'react-admin';
import dataProvider from '../../providers/data';
import {Indicator} from '../../resources/indicators/types';
import {Theme} from '../../resources/themes/types';
import {ThemeEnhancedWithIndicators} from './types';

const useGetThemes = (): [
	themes: ThemeEnhancedWithIndicators[] | null,
	fetchThemesAndIndicators: () => Promise<ThemeEnhancedWithIndicators[]>
] => {
	const translate = useTranslate();
	const [themes, setThemes] = useState<ThemeEnhancedWithIndicators[] | null>(
		null
	);

	const defaultParams: GetListParams = {
		filter: {},
		pagination: {
			page: 1,
			perPage: 100
		},
		sort: {
			field: 'position',
			order: 'ASC'
		}
	};

	const fetchThemes = async () => {
		const themes = await dataProvider
			.getList<Theme>('themes', defaultParams)
			.then(({data}) => data);
		return themes;
	};

	const fetchIndicators = async (themes: Theme[]) => {
		const indicators = await Promise.all([
			dataProvider.getList<Indicator>('indicators', {
				...defaultParams,
				filter: {in_synthesis: true}
			}),
			...themes.map((theme: Theme) =>
				dataProvider.getList<Indicator>('indicators', {
					...defaultParams,
					filter: {theme_id: theme.id}
				})
			)
		]);
		return indicators;
	};

	const fetchThemesAndIndicators = async () => {
		let themes = await fetchThemes();
		const indicators = await fetchIndicators(themes);
		themes.unshift({
			name: translate('corporation.tabs.synthesis'),
			id: -1,
			position: -1,
			isSynthesis: true
		});
		const themesFiltered = themes
			.map((theme, index) => ({
				...theme,
				indicators: indicators[index].data,
				total: indicators[index].total
			}))
			.filter(({total}) => !!total && total > 0);
		setThemes(themesFiltered);
		return themesFiltered;
	};

	return [themes, fetchThemesAndIndicators];
};

export default useGetThemes;

import Tab from '@mui/material/Tab';
import {Theme, styled} from '@mui/material/styles';

interface StyledTabProps {
	label: string;
	id: string;
	'aria-controls': string;
	isLoading: boolean;
}

const StyledTab = styled(({isLoading, ...props}: StyledTabProps) => (
	<Tab {...props} />
))(({theme, isLoading}: {theme: Theme; isLoading?: boolean}) => ({
	'&.MuiTab-root': {
		textTransform: 'unset',
		fontWeight: 'normal',
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
		minWidth: 'max-content',
		opacity: isLoading ? 0.5 : 1,
		cursor: isLoading ? 'wait' : 'pointer'
	},
	'&.Mui-selected': {
		position: 'relative',
		fontWeight: '500',
		color: theme.palette.primary.main,

		'&::after': {
			content: "''",
			position: 'absolute',
			bottom: '8px',
			left: '50%',
			height: '3px',
			width: '25px',
			transform: 'translateX(-50%)',
			backgroundColor: theme.palette.primary.main
		}
	}
}));

export default StyledTab;

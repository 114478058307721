import {fetchUtils} from 'react-admin';
import {getToken} from './auth';

export type HttpClientResponse = Awaited<
	ReturnType<typeof fetchUtils.fetchJson>
>;

export interface ApiResponse<T> extends HttpClientResponse {
	json: T;
}

export const httpClient = <T extends HttpClientResponse>(
	url: string,
	options: fetchUtils.Options = {}
) => {
	const token = getToken();

	if (token) {
		options.user = {
			authenticated: true,
			token: `Bearer ${token}`
		};
	}

	return fetchUtils.fetchJson(url, options) as Promise<T>;
};

export const ApiBaseUrl = process.env.REACT_APP_API_URL as string;

export const apiUrl = (endpoint: string) => `${ApiBaseUrl}${endpoint}`;

export const fetchLogin = (username: string, password: string) =>
	fetchUtils.fetchJson(apiUrl('/login-check'), {
		method: 'POST',
		body: JSON.stringify({
			username,
			password
		})
	});

export const submitForgotPasswordRequest = async (email: string) => {
	const Apimessage = {
		message: 'forgotPassword.message.success',
		isError: false
	};
	try {
		await fetchUtils.fetchJson(apiUrl('/forget-password'), {
			method: 'PUT',
			body: JSON.stringify({
				email
			})
		});
		return Apimessage;
	} catch (error) {
		Apimessage.isError = true;
		if (error.status < 500) {
			Apimessage.message =
				error?.body?.errors?.message ?? error?.body?.errors[0]?.message;
			return Apimessage;
		}
		Apimessage.message = 'forgotPassword.message.unknow';
		return Apimessage;
	}
};

export const submitResetPasswordResquest = async (newPassword: {
	password: string;
	token: string | null;
}) => {
	const Apimessage = {
		message: 'resetPassword.message.success',
		isError: false
	};
	try {
		await fetchUtils.fetchJson(apiUrl('/change-password'), {
			method: 'PUT',
			body: JSON.stringify(newPassword)
		});
		return Apimessage;
	} catch (error) {
		Apimessage.isError = true;
		if (error.status < 500) {
			Apimessage.message =
				error?.body?.errors?.message ?? error?.body?.errors[0]?.message;
			return Apimessage;
		}
		Apimessage.message = 'resetPassword.message.unknow';
		return Apimessage;
	}
};

import {
	ChartSizes,
	GraphicTypes,
	NumberTuple,
	StackedBarStats
} from '../models/types';
import MixBar from './MixBar';
import StackedMixBar from './StakedMixBar';

interface StackedBarProps {
	data: StackedBarStats;
	size: ChartSizes;
	type: GraphicTypes;
	idTitle: string;
	domain?: NumberTuple | null;
}
const StackedBar = ({data, size, type, idTitle, domain}: StackedBarProps) =>
	type === 'mix_bar' ? (
		<MixBar data={data} size={size} idTitle={idTitle} domain={domain} />
	) : type === 'mix_bar_2' ? (
		<StackedMixBar
			data={data}
			size={size}
			idTitle={idTitle}
			domain={domain}
		/>
	) : (
		<></>
	);

export default StackedBar;

export const fr = {
	name: 'Déclaration |||| Déclarations',
	fields: {
		id: 'ID',
		dsn: 'DSN mensuelle',
		total_people: 'Nombre de salariés',
		user_id: 'Importé par',
		constituted_at: {
			date: 'Date de constitution'
		},
		declared_at: {
			date: 'Mois déclaré'
		}
	},
	delete: {
		modal: {
			title: `Suppression d'une déclaration`,
			content:
				'Cette action entraînera la suppression de toutes les données de cette déclaration.'
		},
		error: 'Oups ! Une erreur est survenue pendant la suppression de cette déclaration'
	},
	upload: {
		success: `Succès de l'import de la déclaration`,
		loading: {
			title: 'Import du fichier en cours...',
			content: `Cette opération peut prendre quelques secondes.
				Merci de patienter.`
		},
		title: 'Importer une déclaration',
		close: 'Fermer',
		submit: 'Importer',
		helper: `Vous ne pouvez importer qu'une déclaration au format DSN (.txt, .dsn, .edi etc.) qui suit ou précède les déclarations existantes.`,
		placeholder: `Glissez dans cette zone les fichiers à importer ou cliquez pour ouvrir une fenêtre de sélection sur votre poste (12 fichiers maximum)`,
		maxFiles:
			'Vous ne pouvez pas importer plus de %{maxFilesCount} déclarations en même temps.'
	}
};

import {Box, Grid, Typography} from '@mui/material';
import {stringify} from 'query-string';
import {useEffect, useState} from 'react';
import {Loading} from 'react-admin';
import {apiUrl, httpClient} from '../../api';
import ChartRenderer from '../../charts/components/ChartRenderer';
import {
	AreaStats,
	BarStats,
	ChartSizes,
	GraphicTypes,
	LineStats,
	NumberStats,
	NumberTuple,
	PieStats,
	TableStats
} from '../../charts/models/types';
import {
	getMinMaxRangeFromArray,
	getMinMaxRangeFromStats
} from '../../charts/utils/utils';
import {Indicator} from '../../resources/indicators/types';
import {isObjectEmpty} from './utils';

interface ExplodedIndicatorData {
	[key: string]: {
		data:
			| NumberStats
			| PieStats
			| BarStats
			| AreaStats
			| TableStats
			| LineStats;
	};
}

interface ExplodedIndicatorProps {
	indicator: Indicator;
	corporationsIds: number[];
	startDate: string | null;
	endDate: string | null;
}

const ExplodedIndicator = ({
	indicator,
	corporationsIds,
	startDate,
	endDate
}: ExplodedIndicatorProps) => {
	const {id, graphical_representation: graphicType} = indicator;
	const [explodedIndicatorData, setExplodedIndicatorData] =
		useState<ExplodedIndicatorData | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!startDate || !endDate) {
			setIsLoading(false);
			return;
		}

		const fetchExplodedIndicator = async () => {
			setIsLoading(true);
			try {
				const params = {
					start: startDate,
					end: endDate,
					corporations_ids: corporationsIds,
					value_group_type_id: indicator.value_group_type_ids[0]
				};
				const response = await httpClient(
					apiUrl(
						`/indicators/${id}/statistics/details?${stringify(params, {
							arrayFormat: 'bracket'
						})}`
					)
				).then(({json}) => json);

				setExplodedIndicatorData(
					Object.keys(response).length > 0 ? response : null
				);
			} catch (error) {
				console.error(
					"Erreur lors du chargement des données de l'indicateur",
					error
				);
			} finally {
				setIsLoading(false);
			}
		};

		fetchExplodedIndicator();
	}, [startDate, endDate, corporationsIds, indicator, id]);

	if (isLoading) {
		return <Loading />;
	}

	if (!explodedIndicatorData) {
		return null;
	}

	const domain = Object.values(
		explodedIndicatorData
	).reduce<NumberTuple | null>((previous, current) => {
		const domain = getMinMaxRangeFromStats(graphicType, current.data);
		if (!domain) {
			return previous;
		}

		if (!previous) {
			return domain;
		}

		return getMinMaxRangeFromArray([...previous, ...domain]);
	}, null);

	return (
		<Box>
			<Grid container spacing={2} sx={{marginTop: '2rem'}}>
				{Object.entries(explodedIndicatorData).map(
					([label, {data}], index) => (
						<Grid key={`indicator-sub-chart-${id}-${index}`} item md={6}>
							<Box
								sx={{
									height: '100%',
									padding: '1rem',
									borderRadius: 3,
									border: '1px solid #ccc'
								}}
							>
								<Typography
									component="h3"
									sx={{marginBottom: '0.5rem', textAlign: 'center'}}
									id={`indicator-valueGroup-title-${id}-${index}`}
								>
									{label}
								</Typography>
								{!isObjectEmpty(data) && (
									<ChartRenderer
										domain={domain}
										size={ChartSizes.small}
										idTitle={`indicator-${indicator.id}`}
										graphicType={graphicType}
										data={data}
										legend={
											!(
												GraphicTypes.bar_gradient_colors ===
												indicator.graphical_representation
											)
										}
										indicator={indicator}
									/>
								)}
							</Box>
						</Grid>
					)
				)}
			</Grid>
		</Box>
	);
};

export default ExplodedIndicator;

import {Datagrid, TextField} from 'react-admin';
import CustomList from '../common/CustomList';

const BlockList = () => (
	<CustomList sort={{field: 'code', order: 'ASC'}}>
		<Datagrid size="small" bulkActionButtons={false}>
			<TextField source="code" />
			<TextField source="title" />
		</Datagrid>
	</CustomList>
);

export default BlockList;

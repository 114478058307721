import {
	AreaChart,
	CartesianGrid,
	Area as RechartsArea,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import {AreaStats, COLORS, ChartSizes, NumberTuple} from '../models/types';
import {getHeightInPixels} from '../utils/utils';

const formatData = (data: AreaStats) =>
	Object.entries(data)
		.filter(([key]) => key !== 'all')
		.map(([key, value]) => {
			const dataKeys =
				typeof value === 'number' ? {total: value.toFixed(2)} : value;
			return Object.assign(
				{
					name: key
				},
				dataKeys
			);
		});

interface AreaProps {
	data: AreaStats;
	size: ChartSizes;
	domain?: NumberTuple | null;
}

const Area = ({data, size, domain}: AreaProps) => {
	const formattedData = formatData(data);
	const dataKeys = Object.keys(formattedData?.[0]).filter(
		(key) => key !== 'name'
	);

	return (
		<div style={{width: '100%', height: getHeightInPixels(size)}}>
			<ResponsiveContainer>
				<AreaChart
					data={formattedData}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis
						domain={domain ?? [0, 'auto']}
						allowDecimals={false}
						interval={'preserveStartEnd'}
						allowDataOverflow={true}
					/>
					<Tooltip />
					{dataKeys.map((key, index) => (
						<RechartsArea
							isAnimationActive={false}
							key={`area-chart-${key}-${index}`}
							type="monotone"
							dataKey={key}
							stackId="1"
							stroke={COLORS[index]}
							fill={COLORS[index]}
						/>
					))}
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Area;

import {RaRecord} from 'ra-core';
import {
	ReferenceField,
	SaveButton,
	SimpleForm,
	SimpleShowLayout,
	TextField,
	TextInput,
	Toolbar,
	ToolbarProps
} from 'react-admin';
import CustomEdit from '../common/CustomEdit';

const NoDeleteToolbar = (props: ToolbarProps) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

const ValueEdit = () => (
	<CustomEdit>
		<SimpleForm sx={{maxWidth: 600}} toolbar={<NoDeleteToolbar />}>
			<SimpleShowLayout sx={{padding: 0, marginBottom: '1rem'}}>
				<TextField source="label" />
				<TextField source="code" />
				<ReferenceField
					source="heading_id"
					reference="headings"
					link={(record: RaRecord, reference: string) =>
						`/${reference}/${record.heading_id}/show`
					}
				>
					<TextField source="name" />
				</ReferenceField>
			</SimpleShowLayout>
			<TextInput
				source="label_to_display"
				helperText="resources.values.helpers.label_to_display"
				fullWidth
			/>
		</SimpleForm>
	</CustomEdit>
);

export default ValueEdit;

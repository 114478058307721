import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Theme,
	Typography
} from '@mui/material';
import {useCallback} from 'react';
import {CreateBase, useTranslate} from 'react-admin';
import {Corporation} from '../../resources/corporations/types';
import CompareDialogDateForm from './CompareDialogDateForm';
import CompareDialogPanelForm from './CompareDialogPanelForm';
import {DatesFilter as DatesFilterType} from './types';

interface CompareDialogProps {
	corporation: Corporation;
	isOpen: boolean;
	onClose: () => void;
	activeDatesFilter: DatesFilterType;
}

const CompareDialog = (props: CompareDialogProps) => {
	const {onClose, isOpen, corporation, activeDatesFilter} = props;
	const translate = useTranslate();

	const closeDialog = useCallback(() => {
		onClose();
	}, [onClose]);

	const handleDialogClose = useCallback(
		(_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
			if (reason === 'backdropClick') {
				return;
			}
			closeDialog();
		},
		[closeDialog]
	);

	const titleDate = {
		startDate: activeDatesFilter.start.date.toLocaleDateString('fr-FR', {
			month: 'long',
			year: 'numeric'
		}),
		endDate: activeDatesFilter.end.date.toLocaleDateString('fr-FR', {
			month: 'long',
			year: 'numeric'
		})
	};

	return (
		<Dialog
			onClose={handleDialogClose}
			open={isOpen}
			fullWidth
			maxWidth="md"
			PaperProps={{sx: {padding: '2rem 1.5rem'}}}
		>
			<Grid container justifyContent="flex-end">
				<IconButton onClick={closeDialog} size="small">
					<CloseIcon />
				</IconButton>
			</Grid>
			<DialogTitle
				component="h1"
				sx={{
					padding: 0,
					marginBottom: '1rem',
					textAlign: 'center'
				}}
			>
				<Typography
					component="span"
					sx={(theme: Theme) => ({
						fontSize: '1rem',
						fontWeight: '500',
						lineHeight: 1.2,
						color: theme.palette.text.secondary
					})}
				>
					{corporation?.name} <br />
					{translate(
						'corporation.compare.dialog.displayedPeriod',
						titleDate
					)}
				</Typography>
			</DialogTitle>

			<Box
				sx={{
					padding: 0,
					marginBottom: '2rem',
					textAlign: 'center'
				}}
			>
				<Typography
					component="p"
					sx={(theme: Theme) => ({
						fontSize: '1.5rem',
						fontWeight: '500',
						lineHeight: 1.2,
						color: theme.palette.text.secondary
					})}
				>
					{translate('corporation.compare.dialog.title')}
				</Typography>
			</Box>
			<DialogContent>
				<Grid container sx={{padding: 0}}>
					<Grid item sx={{width: '30%'}}>
						<CreateBase resource="declarations">
							<CompareDialogDateForm
								corporation={corporation}
								activeDatesFilter={activeDatesFilter}
							/>
						</CreateBase>
					</Grid>
					<Divider
						orientation="vertical"
						flexItem
						sx={(theme: Theme) => ({
							borderWidth: '0',
							width: '0.5rem',
							margin: '0 1.5rem',
							backgroundColor: theme.palette.primary.main
						})}
					/>
					<Grid item xs>
						<Grid sx={{padding: 0}}>
							<CreateBase resource="declarations">
								<CompareDialogPanelForm
									corporation={corporation}
									dates={{
										start: activeDatesFilter.start.date,
										end: activeDatesFilter.end.date
									}}
								/>
							</CreateBase>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default CompareDialog;

export const fr = {
	name: 'Rubrique |||| Rubriques',
	fields: {
		id: 'ID',
		code: 'Code',
		name: 'Nom',
		format: 'Format',
		comment: 'Commentaires',
		block_id: 'Bloc'
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des rubriques'
};

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DefaultIcon from '@mui/icons-material/ViewList';
import {Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ReactElement, createElement} from 'react';
import {
	MenuProps,
	useCreatePath,
	useGetResourceLabel,
	useResourceDefinitions,
	useTranslate
} from 'react-admin';
import {Link, useMatch} from 'react-router-dom';

interface MenuIconButtonProps {
	label: string;
	icon: ReactElement;
	to: string;
}

const MenuIconLink = styled(
	({label, icon, to, ...rest}: MenuIconButtonProps) => {
		const match = useMatch(to);

		return (
			<Link {...rest} to={to} data-is-active={!!match}>
				<span className="MenuIconLink-icon">{icon}</span>
				<span className="MenuIconLink-label">{label}</span>
			</Link>
		);
	}
)(({theme}) => ({
	display: 'block',
	position: 'relative',
	marginBottom: '0.5rem',
	zIndex: 1000,
	color: theme.palette.primary.main,

	'&[data-is-active="true"]': {
		'& .MenuIconLink-icon': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		}
	},

	'& .MenuIconLink-label': {
		display: 'block',
		position: 'absolute',
		left: '-9999px',
		top: '50%',
		padding: '0.5rem 1rem 0.5rem 100%',
		transform: 'translateY(-50%)',
		whiteSpace: 'nowrap',
		lineHeight: '1.75rem',
		borderRadius: '1.25rem',
		zIndex: -1,
		backgroundColor: theme.palette.primary.main
	},

	'& .MenuIconLink-icon': {
		display: 'flex',
		borderRadius: '1.25rem',
		padding: '0.5rem',

		'& > svg': {
			width: '1.75rem',
			height: '1.75rem'
		}
	},

	'&:hover, &:focus': {
		'& .MenuIconLink-label': {
			left: 0,
			color: theme.palette.primary.contrastText
		},

		'& .MenuIconLink-icon': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		}
	}
}));

const CustomMenu = (props: MenuProps) => {
	const resources = useResourceDefinitions();
	const getResourceLabel = useGetResourceLabel();
	const createPath = useCreatePath();
	const translate = useTranslate();

	const {
		children = [
			<Grid item key="dashboard" component="li">
				<MenuIconLink
					to={'/'}
					label={translate('layout.menu.dashboard')}
					icon={<DashboardOutlinedIcon />}
				/>
			</Grid>,
			...Object.keys(resources)
				.filter((name) => resources[name].hasList)
				.map((name) => (
					<Grid item key={name} component="li">
						<MenuIconLink
							to={createPath({
								resource: name,
								type: 'list'
							})}
							label={getResourceLabel(name, 2)}
							icon={
								resources[name].icon ? (
									createElement(resources[name].icon)
								) : (
									<DefaultIcon />
								)
							}
						/>
					</Grid>
				))
		]
	} = props;

	return (
		<nav role="navigation" aria-label={translate('layout.menu.label')}>
			<Grid
				container
				flexDirection="column"
				alignItems="center"
				sx={{transform: 'translateY(-50%)', listStyle: 'none', padding: 0}}
				component="ul"
			>
				{children}
			</Grid>
		</nav>
	);
};

export default CustomMenu;

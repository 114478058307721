import {
	ReferenceArrayInput,
	SelectArrayInput,
	SimpleForm,
	TextInput
} from 'react-admin';
import CustomEdit from '../common/CustomEdit';
import RequiredText from '../common/RequiredText';
import {ROLE_CHOICES} from './types';

const UserEdit = () => (
	<CustomEdit>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />
			<TextInput source="firstname" required fullWidth />
			<TextInput source="lastname" required fullWidth />
			<TextInput source="username" required fullWidth />
			<SelectArrayInput
				source="roles"
				choices={ROLE_CHOICES}
				optionText="role"
				optionValue="role"
				required
				fullWidth
				helperText="resources.users.helpers.role"
			/>
			<ReferenceArrayInput
				source="corporations_ids"
				reference="corporations"
			>
				<SelectArrayInput
					disabled
					label="resources.users.fields.corporations"
					optionText="name"
					fullWidth
					helperText="resources.users.helpers.corporations"
				/>
			</ReferenceArrayInput>
		</SimpleForm>
	</CustomEdit>
);

export default UserEdit;

export const fr = {
	footer: {
		links: {
			siteMap: 'Plan du site'
		},
		copyright: 'FIC Expertise - Copyright 2024',
		need_help: "Besoin d'aide ?"
	},
	menu: {
		label: 'Menu de navigation principal',
		dashboard: 'Dashboard'
	}
};

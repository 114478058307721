import {NumberInput, SimpleForm, TextInput} from 'react-admin';
import CustomCreate from '../common/CustomCreate';
import RequiredText from '../common/RequiredText';

const ThemeCreate = () => (
	<CustomCreate>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />
			<TextInput source="name" required fullWidth />
			<NumberInput source="position" required fullWidth />
		</SimpleForm>
	</CustomCreate>
);

export default ThemeCreate;

import {ApiResponse, apiUrl, httpClient} from '../../api';
import {formatDateISO} from '../../app/corporation/utils';
import {Analyze} from '../models/Analyze';

export const fetchAnalyzes = async (
	corporationId: number,
	start: Date,
	end: Date
) => {
	const begin = formatDateISO(start);
	const expiration = formatDateISO(end);

	const {json} = await httpClient<ApiResponse<{data: Analyze[]}>>(
		apiUrl(`/analyzes/${begin}/${expiration}/corporation/${corporationId}`)
	);

	return json.data;
};

import {Button, ButtonProps, styled} from '@mui/material';

interface ExpandMoreProps extends ButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const {expand, ...other} = props;
	return <Button {...other} />;
})(({expand, theme}) => ({
	display: 'flex',
	alignItems: 'center',
	gap: '0.5rem',
	'svg': {
		color: theme.palette.primary.main,
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		marginLeft: 'auto',
		transition: 'transform 0.3s ease-out'
	}
}));

export default ExpandMore;

import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
	Button,
	IconButton,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {visuallyHidden} from '@mui/utils';
import {FormEvent, useCallback, useState} from 'react';
import {useLogin, useNotify, useTranslate} from 'react-admin';
import {Link} from 'react-router-dom';
import {ReactComponent as FicLogo} from '../layout/fic-logo.svg';
import './CustomLoginPage.css';
import {ReactComponent as LoginIllustration} from './login-illustration.svg';

const CustomLoginPage = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const login = useLogin();
	const notify = useNotify();
	const translate = useTranslate();

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		login({username, password}).catch((e) => {
			notify(e.message, {type: 'error'});
		});
	};
	const togglePasswordVisibility = useCallback(() => {
		setShowPassword((showPassword) => !showPassword);
	}, [setShowPassword]);

	return (
		<div className="LoginPage">
			<div className="LoginPage-leftAside">
				<LoginIllustration
					className="LoginPage-illustration"
					role="img"
					aria-hidden="true"
				/>
			</div>
			<div className="LoginPage-rightMain">
				<form onSubmit={handleSubmit} className="LoginPage-form">
					<h1 style={visuallyHidden}>{translate('login.title')}</h1>

					<FicLogo className="LoginPage-ficLogo" />

					<fieldset className="LoginPage-fieldset">
						<legend>
							<Typography
								component="span"
								sx={(theme: Theme) => ({
									display: 'block',
									marginBottom: '1rem',
									color: theme.palette.primary.main,
									fontSize: '1.5rem'
								})}
							>
								{translate('login.legend')}
							</Typography>
						</legend>

						<TextField
							sx={{marginTop: 0, marginBottom: '1rem'}}
							id="username"
							type="email"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
							label={translate('login.label.username')}
							variant="filled"
							size="medium"
							fullWidth
						/>
						<TextField
							sx={{
								marginTop: 0,
								marginBottom: '1rem'
							}}
							id="password"
							type={showPassword ? 'text' : 'password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
							label={translate('login.label.password')}
							variant="filled"
							size="medium"
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={togglePasswordVisibility}
											edge="end"
											title={translate('login.password.toggle')}
										>
											<span style={visuallyHidden}>
												{translate('login.password.toggle')}
											</span>
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</fieldset>
					<Button
						component={Link}
						to="/forgot-password"
						variant="text"
						sx={{
							alignSelf: 'flex-end',
							marginBottom: '1.5rem'
						}}
					>
						{translate('login.label.forgotpassword')}
					</Button>
					<Button
						type="submit"
						variant="contained"
						sx={{
							width: 'fit-content',
							padding: '0.75rem 3rem',
							borderRadius: '12px',
							alignSelf: 'flex-end'
						}}
					>
						{translate('login.label.submit')}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default CustomLoginPage;

import {Box} from '@mui/material';
import {ReactNode} from 'react';

const CustomSidebar = (props: {children: ReactNode}) => (
	<Box
		sx={{
			padding: {
				xs: '0 8px',
				md: '0 16px'
			},
			position: 'fixed',
			zIndex: 10,
			top: '50%',
			left: {
				md: '20px'
			},
			alignSelf: 'flex-start'
		}}
	>
		{props.children}
	</Box>
);

export default CustomSidebar;

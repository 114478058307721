interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = ({children, value, index, ...rest}: TabPanelProps) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...rest}
	>
		{value === index && children}
	</div>
);

export default TabPanel;

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import {Box, Button, Grid, Theme, Typography} from '@mui/material';
import {useCallback, useEffect, useRef, useState} from 'react';
import {HttpError, useStore, useTranslate} from 'react-admin';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import useAnalyzes from '../../analyzes/hooks/useAnalyzes';
import {ReactComponent as FicLogo} from '../../layout/fic-logo.svg';
import dataProvider from '../../providers/data';
import {Corporation} from '../../resources/corporations/types';
import ErrorMessage from './ErrorMessage';
import LoadingContainer from './LoadingContainer';
import PrintableIndicator from './PrintableIndicator';
import {ThemeEnhancedWithIndicators} from './types';
import useDatesFilter from './useDatesFilter';
import useErrorHandler from './useErrorHandler';
import useGetStats from './useGetStats';
import useGetThemes from './useGetThemes';
import {formatDateISO, isObjectEmpty} from './utils';

const PrintableCorporationDetailPage = () => {
	const {id} = useParams() as {id: string};
	const [searchParams, setSearchParams] = useSearchParams();

	const translate = useTranslate();
	const [corporation, setCorporation] = useState<Corporation>();
	const [isReady, setIsReady] = useState(false);
	const {error, setError, handleError} = useErrorHandler();
	const [datesFilter, options, onChange, intializeDatesFilter] =
		useDatesFilter();

	const [isLoading, setIsLoading] = useState(false);
	const [themes, fetchThemes] = useGetThemes();
	const [stats, fetchStats, isStatsLoading] = useGetStats();
	const [analyzes, fetchAnalayzes] = useAnalyzes();
	const [printable, setIsPrintable] = useStore('printable.page');
	const printableRef = useRef<HTMLElement>(null);

	const data = JSON.parse(
		sessionStorage.getItem('exportCorporationIndicators') as string
	);

	const initialize = useCallback(
		async (idCorporation: string, searchParams: URLSearchParams) => {
			try {
				const {data: corporation} = await dataProvider.getOne<Corporation>(
					'corporations',
					{
						id: parseInt(idCorporation)
					}
				);

				setCorporation(corporation);

				let themesTabs: ThemeEnhancedWithIndicators[];
				if (!themes) {
					themesTabs = await fetchThemes();
				} else {
					themesTabs = themes;
				}

				if (!corporation?.declarations_ids?.length) {
					throw new HttpError('corporation.tabs.noData', 500);
				}

				const datesFilter = await intializeDatesFilter(
					corporation.id,
					searchParams
				);

				await fetchStats(
					corporation?.id,
					themesTabs.flatMap(({indicators}) =>
						indicators.map(({id}) => id)
					),
					datesFilter.values.start.date,
					datesFilter.values.end.date,
					{reset: true}
				);

				await fetchAnalayzes(
					corporation?.id,
					datesFilter.values.start.date,
					datesFilter.values.end.date
				);

				setSearchParams(
					new URLSearchParams({
						start: formatDateISO(datesFilter.values.start.date),
						end: formatDateISO(datesFilter.values.end.date)
					})
				);
			} catch (e) {
				handleError(e);
			} finally {
				setIsReady(true);
				setIsLoading(false);
			}
		},
		[
			themes,
			fetchAnalayzes,
			fetchStats,
			setSearchParams,
			fetchThemes,
			handleError,
			intializeDatesFilter
		]
	);

	useEffect(() => {
		setIsPrintable(true);
	}, []);

	useEffect(() => {
		if (!isReady && !isLoading) {
			setError(null);
			setIsLoading(true);
			initialize(id, searchParams);
		}
	}, [id, initialize, isReady, isLoading, searchParams, setError]);

	if (!corporation) {
		return null;
	}

	return (
		<Box
			ref={printableRef}
			sx={(theme: Theme) => ({
				display: 'flex',
				flexDirection: 'column',
				rowGap: '1rem',
				width: '297mm',
				minHeight: '210mm',
				padding: '0 1rem',
				margin: '0mm auto',
				borderRadius: '0.5rem',
				backgroundColor: theme.palette.primary.contrastText,
				'@media print': {
					border: 'none'
				}
			})}
		>
			<Grid
				container
				gap={1}
				sx={{
					justifyContent: 'space-between',
					padding: '2rem 0',
					'@media print': {
						display: 'none'
					}
				}}
			>
				<Box
					sx={{
						'@media print': {
							display: 'none'
						}
					}}
				>
					<Button
						to={`/corporations/${id}/detail?${searchParams}`}
						component={Link}
						sx={{fontSize: '0.9rem'}}
						variant="outlined"
						startIcon={<ArrowBackOutlinedIcon />}
						disabled={isLoading}
					>
						{translate('corporation.report.back')}
					</Button>
				</Box>
				<Box display={'flex'} gap={1}>
					<Button
						sx={{fontSize: '0.9rem'}}
						variant="outlined"
						startIcon={<LocalPrintshopOutlinedIcon />}
						// onClick={() => window.print()}
						onClick={() => {
							setTimeout(() => {
								window.print();
							}, 2000);
						}}
						disabled={isLoading}
					>
						{translate('corporation.report.print')}
					</Button>
				</Box>
			</Grid>
			<Grid
				container
				sx={(theme: Theme) => ({
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '210mm',
					border: `0.1rem solid  ${theme.palette.primary.light} `,
					'@media print': {
						border: 'none',
						breakAfter: 'page',
						marginTop: '30%'
					},
					'@-moz-document url-prefix()': {
						'@media print': {
							breakAfter: 'initial'
						}
					}
				})}
			>
				<Typography
					component="h1"
					variant="h5"
					fontWeight={'600'}
					sx={{
						fontSize: '45px',
						marginBottom: '50px'
					}}
				>
					{corporation.name}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						rowGap: ' 0.5rem',
						marginBottom: '2rem'
					}}
				>
					<Box
						sx={{
							maxHeight: '150px'
						}}
					>
						<FicLogo
							style={{
								width: '100%',
								height: '100px'
							}}
						/>
					</Box>
				</Box>
			</Grid>
			{error ? (
				<ErrorMessage text={error} />
			) : (
				<LoadingContainer
					isLoading={isObjectEmpty(stats) || isStatsLoading || isLoading}
					sx={{
						marginTop: '10rem !important',
						height: '100% !important'
					}}
				>
					{themes
						?.filter((theme) => data[theme.id].length > 0)
						.map((theme, index) => (
							<Grid
								container
								sx={{
									display: 'flex',
									rowGap: '1rem',
									'@media print': {
										breakAfter: 'page'
									},
									'@-moz-document url-prefix()': {
										'@media print': {
											breakAfter: 'initial'
										}
									}
								}}
							>
								<Box
									sx={(theme: Theme) => ({
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										border: `0.1rem solid  ${theme.palette.primary.light} `,
										color: `${theme.palette.primary.main}`,
										width: '100%',
										height: '210mm',
										'@media print': {
											breakAfter: 'page',
											marginTop: '48% !important',
											height: 'auto',
											border: 'none'
										}
									})}
								>
									<Typography
										component="h2"
										variant="h5"
										fontWeight={'600'}
									>
										{corporation.name}
									</Typography>
									<Typography component="h3" variant="h6">
										{`${translate(
											'corporation.report.socialReport'
										)}`}
									</Typography>
									<Box>
										{!!datesFilter && (
											<Box
												sx={(theme: Theme) => ({
													color: `${theme.palette.secondary.light}`
												})}
											>
												<Typography component={'span'}>
													{datesFilter.start.label} {' / '}
												</Typography>

												<Typography component={'span'}>
													{datesFilter.end.label}
												</Typography>
											</Box>
										)}
									</Box>
									<Typography
										component="h1"
										variant="h5"
										sx={(theme: Theme) => ({
											color: `${theme.palette.text.primary}`
										})}
									>
										{theme.name}
									</Typography>
								</Box>
								<Box
									key={index}
									sx={(theme: Theme) => ({
										display: 'grid',
										justifyContent: 'center',
										gridTemplateColumns: `repeat(2, 460px)`,
										border: `0.1rem solid  ${theme.palette.primary.light} `,
										columnGap: '1rem',
										width: '100%',
										maxWidth: '100%',
										padding: '50px',
										'@media print': {
											border: 'none',
											padding: '50px 50px 50px 5px'
										}
									})}
								>
									{theme.indicators
										?.filter((indicator) =>
											data[theme.id].includes(
												indicator.id.toString()
											)
										)
										.map((indicator, index) => {
											return (
												<Box
													sx={{
														listStyle: 'none',
														'@media print': {
															'-webkit-column-break-inside':
																'avoid'
														}
													}}
												>
													<PrintableIndicator
														key={`tabpanel-indicator-${indicator.id}-${index}`}
														indicator={indicator}
														stats={stats?.[indicator.id]}
														index={index}
														corporationsIds={[1, 2]}
														datesFilter={datesFilter}
														activeTabIndex={0}
														resource={'corporations'}
														resourceId={0}
													/>
												</Box>
											);
										})}
								</Box>
							</Grid>
						))}
				</LoadingContainer>
			)}
		</Box>
	);
};

export default PrintableCorporationDetailPage;

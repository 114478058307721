import {User, UserRole} from './resources/users/types';

export const getToken = () => localStorage.getItem('token');

export const setToken = (token: string) => {
	localStorage.setItem('token', token);
};

export const removeToken = () => {
	localStorage.removeItem('token');
};

export const hasFicPermissions = (permissions: User['roles']) =>
	permissions.includes(UserRole.FicUser) ||
	permissions.includes(UserRole.FicAdmin);

export const isAllowedAnonymousRoute = (currentPath: string) =>
	/^\/change-password/.test(currentPath) ||
	/^\/forgot-password/.test(currentPath);

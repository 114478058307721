import {Grid, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {createElement} from 'react';
import {useResourceContext, useResourceDefinitions} from 'react-admin';

interface TitleProps {
	title: string | undefined;
	resourceName: string;
}

const Title = ({resourceName, title}: TitleProps) => {
	const resources = useResourceDefinitions();

	const resource = resources?.[resourceName];

	if (!resource) {
		return null;
	}

	return (
		<Grid container alignItems="center" columnGap={1}>
			<Grid
				item
				sx={(theme: Theme) => ({
					display: 'flex',
					color: theme.palette.primary.main
				})}
			>
				{createElement(resource.icon)}
			</Grid>
			<Grid item>
				<Typography
					component="h1"
					sx={(theme: Theme) => ({
						fontSize: '2rem',
						fontWeight: '500',
						color: theme.palette.text.secondary
					})}
				>
					{title}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default Title;

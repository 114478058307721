import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import Button from '@mui/material/Button';
import {stringify} from 'query-string';
import {useRecordContext, useTranslate} from 'react-admin';
import {Link} from 'react-router-dom';
import {Corporation} from './types';

const ReferenceValuesLink = ({label}: {label: string}) => {
	const record = useRecordContext<Corporation>();
	const translate = useTranslate();

	const query = {
		displayedFilters: JSON.stringify({
			corporation_id: true
		}),
		filter: JSON.stringify({
			corporation_id: [record.id]
		}),
		order: 'DESC'
	};

	return (
		<Button
			component={Link}
			to={`/reference-values?${stringify(query)}`}
			startIcon={<AutoAwesomeMotionOutlinedIcon />}
		>
			{translate(label)}
		</Button>
	);
};

export default ReferenceValuesLink;

import {useTranslate} from 'react-admin';

const HugeHourNumber = ({
	value,
	hugeFontSize
}: {
	value: number;
	hugeFontSize: string | number | undefined;
}) => {
	const translate = useTranslate();
	return (
		<>
			<span className="huge" style={{fontSize: hugeFontSize}}>
				{value}
			</span>
			<span className="small" style={{fontSize: hugeFontSize}}>
				&nbsp;{translate('charts.table.symbols.hour')}
			</span>
		</>
	);
};

export default HugeHourNumber;

import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import {Box, Button, Grid, SelectChangeEvent, Theme} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {HttpError, useStore, useTranslate} from 'react-admin';
import {useParams, useSearchParams} from 'react-router-dom';
import useAnalyzes from '../../analyzes/hooks/useAnalyzes';
import dataProvider from '../../providers/data';
import {Corporation} from '../../resources/corporations/types';
import CompareDialog from './CompareDialog';
import CorporationDetailHeader from './CorporationDetailHeader';
import DashboardGrid from './DashboardGrid';
import DatesFilter from './DatesFilter';
import ErrorMessage from './ErrorMessage';
import LoadingContainer from './LoadingContainer';
import TabPanel from './TabPanel';
import Tabs from './Tabs';
import {
	DatesFilter as DatesFilterType,
	ThemeEnhancedWithIndicators
} from './types';
import useDatesFilter from './useDatesFilter';
import useErrorHandler from './useErrorHandler';
import useGetStats from './useGetStats';
import useGetThemes from './useGetThemes';
import {formatDateISO, isObjectEmpty} from './utils';

const CorporationDetailPage = () => {
	const {id} = useParams() as {id: string};
	const [searchParams, setSearchParams] = useSearchParams();

	const translate = useTranslate();
	const [corporation, setCorporation] = useState<Corporation>();
	const [isReady, setIsReady] = useState(false);
	const {error, setError, handleError} = useErrorHandler();
	const [datesFilter, options, onChange, intializeDatesFilter] =
		useDatesFilter();
	const [activeTabIndex, setActiveTabIndex] = useState(
		searchParams.has('activeTabIndex')
			? parseInt(searchParams.get('activeTabIndex')!, 10)
			: 0
	);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [themes, fetchThemes] = useGetThemes();
	const [stats, fetchStats, isStatsLoading] = useGetStats();
	const [analyzes, fetchAnalayzes] = useAnalyzes();
	const [printable, setIsPrintable] = useStore('printable.page');

	const initialize = useCallback(
		async (
			idCorporation: string,
			activeTabIndex: number,
			searchParams: URLSearchParams
		) => {
			try {
				const {data: corporation} = await dataProvider.getOne<Corporation>(
					'corporations',
					{
						id: parseInt(idCorporation)
					}
				);

				setCorporation(corporation);

				let themesTabs: ThemeEnhancedWithIndicators[];
				if (!themes) {
					themesTabs = await fetchThemes();
				} else {
					themesTabs = themes;
				}

				if (!corporation?.declarations_ids?.length) {
					throw new HttpError('corporation.tabs.noData', 500);
				}

				const datesFilter = await intializeDatesFilter(
					corporation.id,
					searchParams
				);

				await fetchStats(
					corporation?.id,
					themesTabs?.[activeTabIndex].indicators.map(({id}) => id),
					datesFilter.values.start.date,
					datesFilter.values.end.date,
					{reset: true}
				);

				await fetchAnalayzes(
					corporation?.id,
					datesFilter.values.start.date,
					datesFilter.values.end.date
				);

				setSearchParams(
					new URLSearchParams({
						start: formatDateISO(datesFilter.values.start.date),
						end: formatDateISO(datesFilter.values.end.date),
						activeTabIndex: activeTabIndex.toString()
					})
				);
			} catch (e) {
				handleError(e);
			} finally {
				setIsReady(true);
				setIsLoading(false);
			}
		},
		[
			themes,
			fetchAnalayzes,
			fetchStats,
			setSearchParams,
			fetchThemes,
			handleError,
			intializeDatesFilter
		]
	);

	useEffect(() => {
		// Ce useEffect est là pour relancer
		// la fonction initalize lorsque l'on a changé d'établissement en
		// modifiant une valeur dans le state qui va trigger le useEffect ci dessous
		if (!corporation) {
			return;
		}

		if (corporation.id !== parseInt(id, 10)) {
			setActiveTabIndex(
				searchParams.has('activeTabIndex')
					? parseInt(searchParams.get('activeTabIndex')!, 10)
					: 0
			);
			setIsReady(false);
		}
	}, [corporation, id, isReady, isLoading, searchParams, setIsReady]);

	useEffect(() => {
		setIsPrintable(false);
		if (!isReady && !isLoading) {
			setError(null);
			setIsLoading(true);
			initialize(id, activeTabIndex, searchParams);
		}
	}, [
		id,
		initialize,
		activeTabIndex,
		isReady,
		isLoading,
		searchParams,
		setError,
		setIsPrintable
	]);

	const updateStats = useCallback(
		async (
			datesFilter: DatesFilterType | null,
			tabIndex: number,
			options: {reset: boolean}
		) => {
			if (!themes || !datesFilter) {
				return;
			}

			const indicators = themes[tabIndex].indicators
				.map(({id}) => id)
				.filter((id) => (options.reset ? true : !(id in stats)));

			setActiveTabIndex(tabIndex);

			try {
				await fetchStats(
					corporation?.id,
					indicators,
					datesFilter.start.date,
					datesFilter.end.date,
					options
				);

				const searchParams = new URLSearchParams({
					start: formatDateISO(datesFilter.start.date),
					end: formatDateISO(datesFilter.end.date),
					activeTabIndex: tabIndex.toString()
				});
				setSearchParams(searchParams);
			} catch (e) {
				handleError(e);
			}
		},
		[corporation?.id, fetchStats, handleError, setSearchParams, stats, themes]
	);

	const handleTabChange = useCallback(
		(index: number) => {
			updateStats(datesFilter, index, {reset: false});
		},
		[datesFilter, updateStats]
	);

	const handleDatesFilterChange = useCallback(
		async (event: SelectChangeEvent<number>, key: 'start' | 'end') => {
			const result = onChange(event, key);

			if (!result.values || !corporation?.id) {
				return;
			}

			updateStats(result.values, activeTabIndex, {reset: true});

			try {
				fetchAnalayzes(
					corporation.id,
					result?.values.start.date,
					result?.values.end.date
				);
			} catch (e) {
				handleError(e);
			}
		},
		[
			onChange,
			corporation?.id,
			updateStats,
			activeTabIndex,
			fetchAnalayzes,
			handleError
		]
	);

	const toggleCompareDialog = useCallback(() => {
		setIsOpen((isOpen) => !isOpen);
	}, [setIsOpen]);

	if (!corporation) {
		return null;
	}

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(6)
			})}
		>
			<CorporationDetailHeader
				key={corporation.id}
				corporation={corporation}
				hasDeclarations={corporation.declarations_ids.length > 0}
				datesFilter={datesFilter}
				searchParams={searchParams}
			/>

			{themes && !error && (
				<Tabs
					activeTabIndex={activeTabIndex}
					handleTabChange={handleTabChange}
					themes={themes}
					isLoading={isLoading}
				/>
			)}

			{error ? (
				<ErrorMessage text={error} />
			) : (
				<LoadingContainer
					isLoading={isObjectEmpty(stats) || isStatsLoading || isLoading}
					sx={{
						marginTop: '10rem !important',
						height: '100% !important'
					}}
				>
					<Grid
						container
						alignItems="baseline"
						columnGap={1}
						marginBottom={'0.5rem'}
					>
						<Grid item>
							{!!datesFilter && (
								<DatesFilter
									options={options}
									handleChange={handleDatesFilterChange}
									datesFilter={datesFilter}
								/>
							)}
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="outlined"
								onClick={toggleCompareDialog}
								startIcon={<BalanceOutlinedIcon aria-hidden="true" />}
							>
								{translate('corporation.detail.action.compare')}
							</Button>
						</Grid>
					</Grid>
					<Box>
						{themes?.map((theme, index) => (
							<TabPanel
								key={`corporation-tabpanel-${index}`}
								value={activeTabIndex}
								index={index}
							>
								<DashboardGrid
									key={corporation.id}
									indicators={theme.indicators}
									analyzes={analyzes.filter(({theme_id}) => {
										const isSynthesis =
											themes[activeTabIndex].isSynthesis;
										// Si on est sur la synthèse on renvoie les analyses
										// qui n'ont pas de thème rattaché
										return isSynthesis
											? !theme_id
											: themes[activeTabIndex].id === theme_id;
									})}
									stats={stats}
									datesFilter={datesFilter}
									corporationsIds={[corporation.id]}
									activeTabIndex={activeTabIndex}
									resource="corporations"
									resourceId={corporation.id}
								/>
							</TabPanel>
						))}
					</Box>
				</LoadingContainer>
			)}

			{!!datesFilter && isOpen && (
				<CompareDialog
					isOpen={isOpen}
					onClose={toggleCompareDialog}
					corporation={corporation}
					activeDatesFilter={datesFilter}
				/>
			)}
		</Box>
	);
};

export default CorporationDetailPage;

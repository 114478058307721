export const fr = {
	name: 'Indicateur |||| Indicateurs',
	fields: {
		name: 'Nom',
		identifier: 'Identifiant',
		description: 'Description',
		theme_id: 'Thème',
		rule_by_month: 'Règle de consolidation par mois',
		rule_on_period: 'Règle de consolidation par période',
		group: 'Groupe',
		position: 'Position',
		value_group_type_ids: 'Vue filtrée disponible',
		in_synthesis: 'Synthèse',
		abscissa: 'Libellé abscisse',
		ordinate: 'Libellé ordonnée'
	},
	ruleChoices: {
		sum: 'Somme',
		avg: 'Moyenne',
		count: 'Décompte',
		avg_value: 'Valeur moyenne'
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des indicateurs'
};

/* eslint-disable no-template-curly-in-string */
export const fr = {
	detail: {
		link: {
			back: 'Retour',
			backTitle: 'Retour à la liste des établissements'
		},
		action: {
			import: 'Importer vos données',
			compare: 'Comparer',
			schoolGroup: 'Consulter les données au niveau du groupe scolaire'
		},
		datesFilter: {
			start: 'De',
			startBeforeText: 'Période affichée de',
			end: 'À',
			endBeforeText: 'à'
		},
		tabs: {
			label: 'Regroupement des indicateurs par thèmes'
		}
	},
	declarations: {
		title: 'Déclarations',
		link: {
			back: 'Retour',
			backTitle: `Retour à la fiche de l'établissement`
		},
		upload: {
			buttonLabel: 'Importer une déclaration'
		}
	},
	tabs: {
		noData:
			'Aucune donnée disponible pour cet établissement. <br/> Veuillez importer des déclarations en cliquant sur le bouton "Importer vos données".',
		synthesis: 'Synthèse'
	},
	explodedView: {
		buttonLabel: 'Vue éclatée',
		noData: 'Aucune donnée disponible pour cet indicateur'
	},
	indicator: {
		link: {
			back: 'Retour',
			backTitle: `Retour à la fiche de l'établissement`
		},
		description: 'Description',
		mainChart: {
			title: 'Période affichée de %{startDate} à %{endDate}'
		}
	},
	compare: {
		dialog: {
			displayedPeriod: 'Période affichée: %{startDate} - %{endDate}',
			title: 'Vous souhaitez comparer ces données avec :',
			period: 'Une autre période',
			submit: {
				period: 'Comparer les 2 périodes',
				establishement: "Comparer l'établissement au panel"
			},
			close: 'Fermer',
			panel_establishements: "Ou un panel d'établissements",
			number_students_helper: "Nombre d'élèves :",
			number_students_start_label: 'Entre',
			number_students_end_label: 'et',
			regions_helper: 'Régions :',
			regions_label: 'Régions',
			school_levels_label: "Mêmes niveaux que l'établissement consulté",
			school_levels_helper: 'Niveaux scolaires : ',
			agreement_label: 'Conventions collectives',
			agreement_helper: 'Conventions collectives :',
			payroll_helper: 'Masse salariale sous-traitée (%) :',
			payroll_start_label: 'Entre',
			payroll_end_label: 'et'
		},
		link: {
			back: 'Retour',
			backTitle: `Retour à la fiche de l'établissement`
		},
		expand: 'Afficher/Masquer les indicateurs du thème "%{name}"',
		period: {
			title: 'Comparaison de la période <span>%{reference}</span> avec <span>%{compared}</span>',
			panel: "Un panel d'établissements répondant à des critères équivalents au votre"
		},
		missingDates:
			'Une erreur est survenue, les dates de la période référence ou comparée sont absentes partiellement ou entièrement. ',
		panelAverage: 'Moyenne du panel'
	},
	switcher: {
		label: 'Rechercher',
		title: 'Filtrer la liste des établissements',
		results: {
			none: 'Aucun établissement',
			total: '%{smart_count} établissement |||| %{smart_count} établissements',
			displayed: ', %{smart_count} affiché |||| , %{smart_count} affichés'
		}
	},
	report: {
		export: 'Exporter le rapport',
		print: 'Imprimer ou exporter en PDF',
		choices: 'Choisissez les indicateurs à exporter',
		back: 'Retour',
		socialReport: 'Bilan social',
		fic_address:
			'Fic Expertise \n50 rue des Tisserands \nZA les Filatiers \n62223 Anzin Saint Aubin'
	},
	rawData: {
		title: 'Liste des salariés: %{name}',
		table: {
			startDate: 'Date de début de contrat',
			endDate: 'Date de fin de contrat',
			workingHours: 'Temps de travail',
			status: 'Statut',
			sex: 'Sexe',
			birthDate: 'Date de naissance',
			matricule: 'Matricule'
		},
		back: 'Retour',
		socialReport: 'Bilan social',
		fic_address:
			'Fic Expertise \n50 rue des Tisserands \nZA les Filatiers \n62223 Anzin Saint Aubin'
	}
};

import {Box} from '@mui/material';
import {useEffect} from 'react';
import {Loading, useGetList, useTranslate} from 'react-admin';
import {useNavigate} from 'react-router-dom';

const CustomHomePage = () => {
	const translate = useTranslate();
	const navigate = useNavigate();

	const {data, isLoading, error} = useGetList('corporations', {
		pagination: {page: 1, perPage: 1}
	});

	useEffect(() => {
		if (data && data?.length > 0) {
			navigate(`/corporations/${data[0].id}/detail`);
		}
	}, [data]);

	if (isLoading) {
		return <Loading />;
	}

	if (error) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh"
			>
				<p>{translate('home.fetch.error')}</p>
			</Box>
		);
	}

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
		>
			<p>{translate('home.fetch.noData')}</p>
		</Box>
	);
};

export default CustomHomePage;

import {Box} from '@mui/material';
import {useEffect} from 'react';
import Analyzes from '../../analyzes/components/Analyzes';
import {Analyze} from '../../analyzes/models/Analyze';
import {GraphicTypes, Stats} from '../../charts/models/types';
import {Indicator as IndicatorType} from '../../resources/indicators/types';
import Indicator from './Indicator';
import {DatesFilter} from './types';

interface DashboardGridProps {
	indicators: IndicatorType[];
	analyzes?: Analyze[];
	stats: Stats;
	datesFilter: DatesFilter | null;
	corporationsIds: number[];
	activeTabIndex: number;
	resource: 'companies' | 'corporations';
	resourceId: number;
}

const DashboardGrid = ({
	indicators,
	analyzes,
	stats,
	...rest
}: DashboardGridProps) => {
	useEffect(() => {
		const resizeGridItem = (grid: HTMLElement, item: HTMLLIElement) => {
			const rowHeight = parseInt(
				window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
			);
			const rowGap = parseInt(
				window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
			);
			const content = item.querySelector('.content');

			if (!content) {
				return;
			}

			const itemMarginBottom = 20;
			const rowSpan = Math.ceil(
				(content.getBoundingClientRect().height +
					rowGap +
					itemMarginBottom) /
					(rowHeight + rowGap)
			);
			item.style.gridRowEnd = `span ${rowSpan}`;
		};

		const resizeAllGridItems = (
			grid: HTMLElement | null,
			items: HTMLLIElement[]
		) => {
			if (!grid) {
				return;
			}

			items.forEach((item) => {
				resizeGridItem(grid, item);
			});
		};

		const initialize = async () => {
			const grid = document.getElementById('indicators-grid');
			const allItems = grid?.querySelectorAll('li');
			resizeAllGridItems(grid, Array.from(allItems ?? []));

			const resizeListener = () => {
				resizeAllGridItems(grid, Array.from(allItems ?? []));
			};
			window.addEventListener('resize', resizeListener);

			return () => {
				window.removeEventListener('reisze', resizeListener);
			};
		};

		initialize();
	}, []);

	return (
		<Box
			component="ul"
			sx={{
				display: 'grid',
				gridGap: '0 20px',
				gridTemplateColumns: {
					xs: '100%',
					sm: 'repeat(auto-fill, minmax(425px, 1fr))'
				},
				gridAutoRows: {
					xs: 'unset',
					sm: '20px'
				},
				listStyle: 'none',
				padding: 0,
				marginTop: 0
			}}
			id="indicators-grid"
		>
			{analyzes && analyzes.length > 0 && <Analyzes analyzes={analyzes} />}
			{indicators?.map((indicator, index) => (
				<Indicator
					key={`tabpanel-indicator-${indicator.id}-${index}`}
					indicator={indicator}
					stats={stats?.[indicator.id]}
					index={index}
					legend={
						!(
							GraphicTypes.bar_gradient_colors ===
							indicator.graphical_representation
						)
					}
					{...rest}
				/>
			))}
		</Box>
	);
};

export default DashboardGrid;

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@mui/material';
import React, {useCallback, useEffect} from 'react';
import {CheckboxGroupInput, Form, useTranslate} from 'react-admin';
import {FieldValues} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {Company} from '../../resources/companies/types';
import useGetThemes from '../corporation/useGetThemes';

interface ExportCompanyModalProps {
	company: Company;
	searchParams: URLSearchParams;
	open: boolean;
	onClose: any;
}

const ExportCompanyModal = ({
	company,
	searchParams,
	open,
	onClose
}: ExportCompanyModalProps) => {
	const navigate = useNavigate();
	const translate = useTranslate();
	const [themes, fetchThemes] = useGetThemes();

	const initialize = useCallback(async () => {
		if (!themes) {
			await fetchThemes();
		}
	}, [themes, fetchThemes]);

	const handleExport = () => {
		navigate(`/report/company/${company.id}?${searchParams}`);
	};

	const closeDialog = useCallback(() => {
		onClose();
	}, [onClose]);

	const handleDialogClose = useCallback(
		(_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
			if (reason === 'backdropClick') {
				return;
			}
			closeDialog();
		},
		[closeDialog]
	);

	useEffect(() => {
		initialize().then();
	}, [initialize]);

	const handleSubmit = (data: FieldValues) => {
		sessionStorage.setItem('exportCompanyIndicators', JSON.stringify(data));
		handleExport();
	};

	return (
		<Dialog
			open={open}
			onClose={handleDialogClose}
			fullWidth
			maxWidth="md"
			PaperProps={{sx: {padding: '1rem'}}}
		>
			<DialogTitle sx={{padding: 0, marginBottom: '1rem'}}>
				{translate('corporation.report.choices')}
			</DialogTitle>

			<DialogContent sx={{padding: 0}}>
				<Form onSubmit={handleSubmit}>
					{themes?.map((theme) => (
						<Box mb={3}>
							<CheckboxGroupInput
								key={theme.id}
								id={theme.id.toString()}
								name={theme.id.toString()}
								label={theme.name}
								choices={theme.indicators.map((indicator) => ({
									id: indicator.id,
									name: indicator.name
								}))}
								source="theme"
								defaultValue={theme.indicators.map((indicator) =>
									indicator.id.toString()
								)}
								row={false}
							/>
						</Box>
					))}
					<DialogActions sx={{alignSelf: 'flex-end'}}>
						<Button onClick={closeDialog}>
							{translate('corporation.compare.dialog.close')}
						</Button>
						<Button variant="contained" type="submit">
							{translate('corporation.report.export')}
						</Button>
					</DialogActions>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ExportCompanyModal;

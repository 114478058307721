import {RaRecord} from 'ra-core';
import {RichTextInput} from 'ra-input-rich-text';
import {
	AutocompleteInput,
	ReferenceField,
	ReferenceInput,
	SimpleForm,
	SimpleShowLayout,
	TextField
} from 'react-admin';
import {SelectInput} from 'react-admin';
import CustomDateInput from '../common/CustomDateInput';
import CustomEdit from '../common/CustomEdit';
import CustomRichTextToolbar from '../common/CustomRichTextToolbar';
import RequiredText from '../common/RequiredText';

const AnalyzeEdit = () => (
	<CustomEdit>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />
			<ReferenceInput
				source="corporation_id"
				isRequired
				reference="corporations"
				sort={{field: 'name', order: 'ASC'}}
			>
				<AutocompleteInput
					optionText="name"
					sx={{minWidth: '300px', flexWrap: 'nowrap'}}
					debounce={500}
					suggestionLimit={10}
					filterToQuery={(searchText) => ({name: `%${searchText}%`})}
					fullWidth
					helperText="resources.analyzes.helpers.theme"
				/>
			</ReferenceInput>
			<ReferenceInput source="theme_id" reference="themes">
				<SelectInput optionText="name" fullWidth />
			</ReferenceInput>
			<CustomDateInput
				source="begin"
				required
				fullWidth
				helperText="resources.analyzes.helpers.begin.date"
			/>
			<CustomDateInput
				source="expiration"
				required
				fullWidth
				helperText="resources.analyzes.helpers.begin.date"
			/>
			<CustomDateInput source="updated" disabled fullWidth />
			<RichTextInput
				isRequired
				source="content"
				toolbar={<CustomRichTextToolbar />}
				fullWidth
			/>
			<SimpleShowLayout sx={{padding: 0, marginBottom: '1rem'}}>
				<ReferenceField
					source="user_id"
					reference="users"
					link={(record: RaRecord, reference: string) =>
						`/${reference}/${record.user_id}/show`
					}
				>
					<TextField source="username" />
				</ReferenceField>
			</SimpleShowLayout>
		</SimpleForm>
	</CustomEdit>
);

export default AnalyzeEdit;

import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import {Box, Card, CardContent, IconButton, Typography} from '@mui/material';
import {stringify} from 'query-string';
import {useCallback, useState} from 'react';
import {useRefresh, useStore} from 'react-admin';
import {usePermissions} from 'react-admin';
import {Link} from 'react-router-dom';
import {hasFicPermissions} from '../../auth';
import ChartRenderer from '../../charts/components/ChartRenderer';
import {ChartSizes, IndicatorStats} from '../../charts/models/types';
import {getMinMaxRangeFromStats} from '../../charts/utils/utils';
import {Indicator as IndicatorType} from '../../resources/indicators/types';
import ErrorMessage from './ErrorMessage';
import IndicatorTitle from './IndicatorTitle';
import RawDataDialog from './RawDataDialog';
import {DatesFilter} from './types';
import useErrorHandler from './useErrorHandler';
import useGetRawData from './useGetRawData';
import {getQueryDatesRange} from './utils';

interface IndicatorProps {
	indicator: IndicatorType;
	stats: IndicatorStats;
	datesFilter: DatesFilter | null;
	corporationsIds: number[];
	activeTabIndex: number;
	index: number;
	resource: 'companies' | 'corporations';
	resourceId: number;
}

const PrintableIndicator = ({
	indicator,
	stats,
	datesFilter,
	corporationsIds,
	activeTabIndex,
	index,
	resource,
	resourceId
}: IndicatorProps) => {
	const {
		id,
		name,
		description,
		graphical_representation: graphicType
	} = indicator;

	const queryDatesRange = getQueryDatesRange(
		datesFilter?.start?.date,
		datesFilter?.end?.date
	);

	const domain = getMinMaxRangeFromStats(graphicType, stats);
	const [printable] = useStore('printable.page');
	const delay = 0.2 * (index + 1);
	const {permissions} = usePermissions();
	const hasRawData = hasFicPermissions(permissions);

	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useRefresh();

	const [rawData, fetchRawData] = useGetRawData();
	const {error, handleError} = useErrorHandler();

	const closeRawDataDialog = useCallback(() => {
		setIsOpen(false);
		setIsLoading(true);
		refresh();
	}, [refresh]);

	const openRawDataDialog = useCallback(async () => {
		setIsOpen(true);
		try {
			await fetchRawData(
				id,
				corporationsIds,
				queryDatesRange.start,
				queryDatesRange.end
			);
		} catch (e) {
			handleError(e);
		} finally {
			setIsLoading(false);
		}
		refresh();
	}, [id]);
	return (
		<Box
			component="li"
			sx={{
				py: 0,
				marginBottom: '20px',
				animation: `slideUp 0.5s ${delay}s ease-out both`
			}}
		>
			<Card sx={{width: '100%', height: '100%'}}>
				<CardContent
					className="content"
					sx={{position: 'relative', padding: '20px'}}
				>
					<IndicatorTitle
						sx={{
							inlineSize: '90%',
							wordBreak: 'break-word',
							textAlign: 'center',
							margin: '0 auto 1.5rem auto',
							fontSize: '0.85rem'
						}}
						id={`indicator-title-${id}`}
					>
						{name}
					</IndicatorTitle>

					{!stats?.error && !printable && (
						<Box sx={{position: 'absolute', top: 0, right: 0}}>
							{hasRawData && indicator.has_rawdata && (
								<IconButton color="primary" onClick={openRawDataDialog}>
									<PeopleAltOutlinedIcon />
								</IconButton>
							)}
							<IconButton
								color="primary"
								component={Link}
								to={`/${resource}/${resourceId}/indicator/${id}?${stringify(
									{
										activeTabIndex,
										corporationsIds,
										...queryDatesRange
									}
								)}`}
							>
								<TroubleshootIcon />
							</IconButton>
						</Box>
					)}
					<Box sx={{overflow: 'visible'}}>
						{!!stats?.error ? (
							<ErrorMessage text={stats.error} size="small" />
						) : (
							<ChartRenderer
								size={ChartSizes.small}
								idTitle={`indicator-title-${id}`}
								graphicType={graphicType}
								data={stats}
								domain={domain}
								hugeFontSize="3rem"
								fontSize="0.85rem"
								padding="8px"
								legend={true}
								indicator={indicator}
							/>
						)}
					</Box>
					<Typography
						sx={{
							fontSize: '0.65rem',
							marginTop: '20px'
						}}
						component="div"
						dangerouslySetInnerHTML={{
							__html: description
						}}
					></Typography>
				</CardContent>
			</Card>
			{indicator.has_rawdata && (
				<RawDataDialog
					isOpen={isOpen}
					isLoading={isLoading}
					onClose={closeRawDataDialog}
					indicator={indicator}
					rawData={rawData}
					error={error}
				/>
			)}
		</Box>
	);
};

export default PrintableIndicator;

import {useEffect} from 'react';
import {SelectArrayInput, SimpleForm, TextInput, useNotify} from 'react-admin';
import useErrorHandler from '../../app/corporation/useErrorHandler';
import CustomCreate from '../common/CustomCreate';
import RequiredText from '../common/RequiredText';
import {ROLE_CHOICES} from './types';

const UserCreate = () => {
	const notify = useNotify();
	const {error, setError, handleError} = useErrorHandler();
	const onError = (err: unknown) => handleError(err);

	useEffect(() => {
		if (error) {
			notify(error!, {type: 'error'});
			setError(null);
		}
	}, [error]);
	return (
		<CustomCreate mutationOptions={{onError}}>
			<SimpleForm sx={{maxWidth: 600}}>
				<RequiredText />
				<TextInput source="firstname" required fullWidth />
				<TextInput source="lastname" required fullWidth />
				<TextInput source="username" required fullWidth />
				<SelectArrayInput
					source="roles"
					choices={ROLE_CHOICES}
					optionText="role"
					optionValue="role"
					required
					fullWidth
					helperText="resources.users.helpers.role"
				/>
			</SimpleForm>
		</CustomCreate>
	);
};

export default UserCreate;
function notify(arg0: any, arg1: {type: string}) {
	throw new Error('Function not implemented.');
}

import {useCallback, useState} from 'react';
import {Analyze} from '../models/Analyze';
import {fetchAnalyzes as fetchAnalyzesApi} from '../utils/api';

const useAnalyzes = () => {
	const [analyzes, setAnalyzes] = useState<Analyze[]>([]);

	const fetchAnalyzes = useCallback(
		async (corporationId: number, start: Date, end: Date) => {
			const analyzes = await fetchAnalyzesApi(corporationId, start, end);
			setAnalyzes(analyzes);
			return analyzes;
		},
		[]
	);

	return [analyzes, fetchAnalyzes] as const;
};

export default useAnalyzes;

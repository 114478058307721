import {useEffect} from 'react';
import {SimpleForm, TextInput, useNotify} from 'react-admin';
import useErrorHandler from '../../app/corporation/useErrorHandler';
import CustomCreate from '../common/CustomCreate';
import RequiredText from '../common/RequiredText';

const CompanyCreate = () => {
	const notify = useNotify();
	const {error, setError, handleError} = useErrorHandler();

	const onError = (e: unknown) => {
		handleError(e);
	};
	useEffect(() => {
		if (error) {
			notify(error, {type: 'error'});
			setError(null);
		}
	}, [error]);

	return (
		<CustomCreate mutationOptions={{onError}}>
			<SimpleForm sx={{maxWidth: 600}}>
				<RequiredText />
				<TextInput source="name" required fullWidth />
				<TextInput source="registration" required fullWidth />
			</SimpleForm>
		</CustomCreate>
	);
};

export default CompanyCreate;

import {Indicator} from '../../resources/indicators/types';
import {DatesFilter, Option, ThemeEnhancedWithIndicators} from './types';
import {GetPanelStatsParams} from './useGetPanelStats';

export const generateAllOptions = (dateRange: {
	start: string;
	end: string;
}): Option[] => {
	const startDate = getDateFromPartialDateString(dateRange.start);
	const endDate = getDateFromPartialDateString(dateRange.end);
	let i = 0;
	const dateAndYearList = [
		{
			value: i,
			label: startDate.toLocaleString('fr', {
				month: 'long',
				year: 'numeric'
			}),
			date: startDate
		}
	];

	let dateIterator = new Date(startDate.getTime());
	while (dateIterator.getTime() < endDate.getTime()) {
		i++;
		dateIterator.setMonth(dateIterator.getMonth() + 1);
		dateAndYearList.push({
			value: i,
			label: dateIterator.toLocaleString('fr', {
				month: 'long',
				year: 'numeric'
			}),
			date: new Date(dateIterator.getTime())
		});
	}

	return dateAndYearList.reverse();
};

// On pourrait améliorer cette fonction en parcourant les exercices dans
// l'ordre antichronologique mais elle fait le boulot et ne provoquera pas
// de soucis de performance vu la volumétrie de données.
const getLastCompleteExercice = (options: Option[]) => {
	const years = options
		.reduce<Array<number>>((previousValue, currentValue) => {
			const year = currentValue.date.getFullYear();
			if (previousValue.includes(year)) {
				return previousValue;
			}

			return previousValue.concat([year]);
		}, [])
		.reverse();

	if (years.length <= 1) {
		return null;
	}

	const lastCompleteExercice = years.reduce<[Option, Option] | null>(
		(previousValue, year, index, years) => {
			if (!years?.[index + 1]) {
				// il n'y a pas d'année après celle là donc pas d'exercice complet
				return previousValue;
			}

			const exerciceStartOption = options.find(
				(option) =>
					option.date.getMonth() === 8 &&
					option.date.getFullYear() === year
			);
			const exerciceEndOption = options.find(
				(option) =>
					option.date.getMonth() === 7 &&
					option.date.getFullYear() === year + 1
			);

			if (!exerciceStartOption || !exerciceEndOption) {
				return previousValue;
			}

			return [exerciceStartOption, exerciceEndOption];
		},
		null
	);

	return lastCompleteExercice;
};

export const getDefaultDatesValues = (options: Option[]): DatesFilter => {
	let startDateOption: DatesFilter['start'];
	let endDateOption: DatesFilter['end'];
	const lastCompleteExercice = getLastCompleteExercice(options);
	const hasSeptemberInOptions = options.some(
		(options) => options.date.getMonth() === 8
	);

	if (lastCompleteExercice) {
		// On a un exercice complet de septembre à août
		startDateOption = lastCompleteExercice[0];
		endDateOption = lastCompleteExercice[1];
	} else if (hasSeptemberInOptions) {
		// On a un mois de septembre présent dans les options
		const firstSeptemberOccurenceIndex = options.findIndex(
			(options) => options.date.getMonth() === 8
		);
		const optionsRange = options.slice(0, firstSeptemberOccurenceIndex + 1);
		startDateOption = optionsRange[optionsRange.length - 1];
		endDateOption = optionsRange[0];
	} else {
		// On prend par défaut la première et la dernière option
		startDateOption = options[options.length - 1];
		endDateOption = options[0];
	}

	return {
		start: startDateOption,
		end: endDateOption
	};
};

const padTo2Digits = (number: number) => {
	return number.toString().padStart(2, '0');
};

// dateString is a weird format like '03-2022" and comme from API
export const getDateFromPartialDateString = (dateString: string) => {
	const [month, year] = dateString.split('-');
	return new Date(parseInt(year, 10), parseInt(month, 10) - 1, 1, 0, 0, 0, 0);
};

export const formatDateISO = (date: Date): string => {
	return [
		date.getFullYear(),
		padTo2Digits(date.getMonth() + 1),
		padTo2Digits(date.getDate())
	].join('-');
};

const getDatesValuesFromQuery = (
	options: Option[],
	searchParams: URLSearchParams
): DatesFilter | null => {
	const start = searchParams.get('start');
	const end = searchParams.get('end');

	if (!start || !end) {
		return null;
	}

	const startDate = new Date(start);
	startDate.setDate(1);
	startDate.setHours(0, 0, 0, 0);
	const endDate = new Date(end);
	endDate.setDate(1);
	endDate.setHours(0, 0, 0, 0);
	if (
		!startDate ||
		!endDate ||
		startDate.getTime() > endDate.getTime() ||
		endDate.getTime() < startDate.getTime()
	) {
		return null;
	}

	const startValue = options.find(
		({date}) => date.getTime() === new Date(startDate).getTime()
	);
	const endValue = options.find(
		({date}) => date.getTime() === new Date(endDate).getTime()
	);
	if (!startValue || !endValue) {
		return null;
	}

	return {
		start: startValue,
		end: endValue
	};
};

export const getInitialDatesValues = (
	options: Option[],
	searchParams: URLSearchParams
): DatesFilter =>
	getDatesValuesFromQuery(options, searchParams) ??
	getDefaultDatesValues(options);

export const groupBy = <T>(array: T[], key: keyof T): any => {
	return array.reduce((accumulator, val) => {
		const groupedKey = val[key];
		if (!accumulator[groupedKey]) {
			accumulator[groupedKey] = [];
		}
		accumulator[groupedKey].push(val);
		return accumulator;
	}, {} as any);
};

export const getQueryDatesRange = (
	startDate: Date | undefined,
	endDate: Date | undefined
) => {
	if (!startDate || !endDate) {
		return {
			start: '',
			end: ''
		};
	}

	const start = formatDateISO(startDate);
	const endDateLastDayOfMonth = new Date(
		endDate.getFullYear(),
		endDate.getMonth() + 1,
		0
	);
	const end = formatDateISO(endDateLastDayOfMonth);

	return {
		start,
		end
	};
};

export const isObjectEmpty = (object: Record<any, any> | undefined | null) => {
	if (object === null || object === undefined) {
		return true;
	}

	return Object.keys(object).length === 0;
};

export const formatPeriodAsText = (start: Date, end: Date) =>
	`${new Intl.DateTimeFormat('fr-FR', {
		month: 'long',
		year: 'numeric'
	}).format(start)} - ${new Intl.DateTimeFormat('fr-FR', {
		month: 'long',
		year: 'numeric'
	}).format(end)}`;

export const compareThemeAndSelfPositions = (
	a: Indicator & {themeIndex: number},
	b: Indicator & {themeIndex: number}
) => {
	if (a.themeIndex < b.themeIndex) {
		return -1;
	}
	if (a.themeIndex > b.themeIndex) {
		return 1;
	}
	if (a.position < b.position) {
		return -1;
	}
	if (a.position > b.position) {
		return 1;
	}
	return 0;
};

export const sortIndicatorsByThemeAndSelfPositions = (
	indicators: Indicator[],
	themes: ThemeEnhancedWithIndicators[]
) => {
	return indicators
		.map((indicator) => {
			const themeIndex = themes.findIndex(
				({id}) => id === indicator.theme_id
			);
			return {
				...indicator,
				themeIndex
			};
		})
		.sort(compareThemeAndSelfPositions);
};

export const getPanelStatsParams = (
	searchParams: URLSearchParams
): GetPanelStatsParams | null => {
	if (!searchParams.has('start') || !searchParams.has('end')) {
		return null;
	}

	return {
		start: searchParams.get('start')!,
		end: searchParams.get('end')!,
		same_activities: searchParams.get('same_activities') === 'true',
		agreements_ids: searchParams
			.getAll('agreements_ids')
			.map((value) => Number(value)),
		regions_ids: searchParams
			.getAll('regions_ids')
			.map((value) => Number(value)),
		min_students: Number(searchParams.get('min_students')),
		max_students: Number(searchParams.get('max_students')),
		min_payroll: Number(searchParams.get('min_payroll')),
		max_payroll: Number(searchParams.get('max_payroll'))
	};
};

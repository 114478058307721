export const fr = {
	name: 'Groupe scolaire |||| Groupes scolaires',
	fields: {
		id: 'ID',
		name: 'Nom',
		registration: 'SIREN'
	},
	backLink: 'Retour',
	backLinkTitle: 'Retour à la liste des groupes scolaires',
	groupReportLink: 'Voir le bilan du groupe'
};

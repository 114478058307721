import {
	FormControl,
	FormControlProps,
	Grid,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select,
	Theme,
	Typography
} from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import {useTranslate} from 'react-admin';
import {DatesFilter as DatesFilterType, Options} from './types';

interface DatesFilterProps {
	options: Options;
	datesFilter: DatesFilterType;
	handleChange: Function;
}

const CustomFormControl = (props: FormControlProps) => (
	<FormControl
		{...props}
		variant="standard"
		sx={(theme: Theme) => ({
			m: 0,
			'& .MuiInputBase-root': {
				color: theme.palette.primary.main,
				marginTop: '0 !important',

				'&::before': {
					content: 'none'
				},

				'& svg': {
					fill: theme.palette.primary.main
				}
			},
			'& .MuiSelect-select': {
				paddingBottom: 0,
				fontSize: '0.9rem'
			}
		})}
	/>
);

const DatesFilter = ({
	options,
	datesFilter,
	handleChange
}: DatesFilterProps) => {
	const translate = useTranslate();

	return (
		<Grid container alignItems="center" columnGap={1} marginBottom="0.5rem">
			<Grid item>
				<Typography
					component="p"
					sx={(theme: Theme) => ({
						color: theme.palette.text.secondary,
						fontSize: '0.9rem'
					})}
				>
					{translate('corporation.detail.datesFilter.startBeforeText')}
				</Typography>
			</Grid>
			<Grid item>
				<CustomFormControl>
					<InputLabel id="start-month-label" style={visuallyHidden}>
						{translate('corporation.detail.datesFilter.start')}
					</InputLabel>
					<Select
						labelId="start-month-label"
						id="start-month-select"
						value={datesFilter?.start.value}
						size="small"
						onChange={(event) => {
							handleChange(event, 'start');
						}}
					>
						{options.start.map(([year, options]) => [
							<ListSubheader>{year}</ListSubheader>,
							options.map(({value, label}) => (
								<MenuItem key={`start-month-${value}`} value={value}>
									{label}
								</MenuItem>
							))
						])}
					</Select>
				</CustomFormControl>
			</Grid>
			<Grid item>
				<Typography
					component="p"
					sx={(theme: Theme) => ({
						color: theme.palette.text.secondary,
						fontSize: '0.9rem'
					})}
				>
					{translate('corporation.detail.datesFilter.endBeforeText')}
				</Typography>
			</Grid>
			<Grid item>
				<CustomFormControl>
					<InputLabel id="end-month-label" style={visuallyHidden}>
						{translate('corporation.detail.datesFilter.end')}
					</InputLabel>
					<Select
						labelId="end-month-label"
						id="end-month-select"
						value={datesFilter?.end.value}
						size="small"
						onChange={(event) => {
							handleChange(event, 'end');
						}}
					>
						{options.end.map(([year, options]) => [
							<ListSubheader>{year}</ListSubheader>,
							options.map(({value, label}) => (
								<MenuItem key={`end-month-${value}`} value={value}>
									{label}
								</MenuItem>
							))
						])}
					</Select>
				</CustomFormControl>
			</Grid>
		</Grid>
	);
};

export default DatesFilter;

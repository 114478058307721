import {GraphicTypes} from '../../charts/models/types';

export type Rule = {
	id: 'sum' | 'avg' | 'count' | 'avg_value';
};

export const RULE_CHOICES: Rule[] = [
	{
		id: 'sum'
	},
	{
		id: 'avg'
	},
	{
		id: 'count'
	},
	{
		id: 'avg_value'
	}
];
export interface Indicator {
	id: number;
	name: string;
	description: string;
	in_synthesis: boolean;
	position: number;
	rule_by_month: string;
	rule_on_period: string;
	theme_id: number;
	graphical_representation: GraphicTypes;
	value_group_type_ids: number[];
	has_rawdata: boolean;
	identifier: number;
}

export type RawData = {
	start_date: string | null;
	end_date: string | null;
	matricule: string;
	temps: string;
	status: string;
	sexe: string;
	birth_date: string;
};

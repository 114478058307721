import {SxProps} from '@mui/material';
import {Loading} from 'react-admin';

interface LoadingContainerProps {
	isLoading: boolean;
	sx: SxProps;
	children: React.ReactNode;
}

const LoadingContainer = ({isLoading, sx, children}: LoadingContainerProps) =>
	isLoading ? <Loading sx={sx} /> : <>{children}</>;

export default LoadingContainer;

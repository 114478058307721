import {RichTextInput} from 'ra-input-rich-text';
import {
	BooleanInput,
	NumberField,
	NumberInput,
	ReferenceArrayInput,
	ReferenceInput,
	SelectArrayInput,
	SelectField,
	SelectInput,
	SimpleForm,
	SimpleShowLayout,
	TextInput,
	useTranslate
} from 'react-admin';
import CustomEdit from '../common/CustomEdit';
import CustomRichTextToolbar from '../common/CustomRichTextToolbar';
import RequiredText from '../common/RequiredText';
import {RULE_CHOICES, Rule} from './types';

const IndicatorEdit = () => {
	const translate = useTranslate();

	return (
		<CustomEdit>
			<SimpleForm sx={{maxWidth: 600}}>
				<RequiredText />
				<SimpleShowLayout sx={{padding: 0}}>
					<NumberField source="identifier" />
				</SimpleShowLayout>
				<TextInput source="name" required fullWidth />
				<RichTextInput
					source="description"
					toolbar={<CustomRichTextToolbar />}
					fullWidth
				/>
				<ReferenceInput source="theme_id" reference="themes">
					<SelectInput optionText="name" required fullWidth />
				</ReferenceInput>
				<ReferenceArrayInput
					source="value_group_type_ids"
					reference="value-group-types"
				>
					<SelectArrayInput
						label="resources.indicators.fields.value_group_type_ids"
						optionText="label"
						fullWidth
					/>
				</ReferenceArrayInput>
				<NumberInput source="position" required fullWidth />
				<BooleanInput source="in_synthesis" />
				<TextInput source="abscissa" fullWidth />
				<TextInput source="ordinate" fullWidth />
				<SimpleShowLayout sx={{padding: 0}}>
					<SelectField
						source="rule_by_month"
						choices={RULE_CHOICES}
						optionText={({id}: Rule) =>
							translate(`resources.indicators.ruleChoices.${id}`)
						}
					/>
					<SelectField
						source="rule_on_period"
						choices={RULE_CHOICES}
						optionText={({id}: Rule) =>
							translate(`resources.indicators.ruleChoices.${id}`)
						}
					/>
				</SimpleShowLayout>
			</SimpleForm>
		</CustomEdit>
	);
};

export default IndicatorEdit;

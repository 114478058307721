import {
	BooleanField,
	Datagrid,
	EditButton,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	TextField,
	TextInput
} from 'react-admin';
import CustomList from '../common/CustomList';
import SelectBooleanInput from '../common/SelectBooleanInput';

const filters = [
	<TextInput source="name" />,
	<SelectBooleanInput source="in_synthesis" />,
	<ReferenceInput source="theme_id" reference="themes">
		<SelectInput optionText="name" />
	</ReferenceInput>
];

const IndicatorList = () => (
	<CustomList
		filters={filters}
		sort={{field: 'name', order: 'ASC'}}
		hasCreate={false}
	>
		<Datagrid size="small">
			<TextField source="name" />
			<ReferenceField source="theme_id" reference="themes" sortable={false}>
				<TextField source="name" />
			</ReferenceField>
			<TextField source="position" />
			<BooleanField source="in_synthesis" />
			<EditButton />
		</Datagrid>
	</CustomList>
);

export default IndicatorList;

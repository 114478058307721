import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useStore, useTranslate} from 'react-admin';
import {getDateFromPartialDateString} from '../../app/corporation/utils';
import {ChartSizes, TableStats} from '../models/types';
import {getHeightInPixels} from '../utils/utils';

interface TableDateProps {
	idTitle: string;
	data: TableStats;
	size: ChartSizes;
	fontSize?: string | number;
	padding?: string | number;
}

const TableDate = ({
	data,
	idTitle,
	size,
	fontSize,
	padding
}: TableDateProps) => {
	const translate = useTranslate();
	const [printable] = useStore('printable.page');
	const formattedData = Object.entries(data).map(([key, value]) => ({
		key:
			key === 'all'
				? translate('charts.table.all', {
						total: Object.keys(data).length - 1
					})
				: new Intl.DateTimeFormat('fr-FR', {
						month: 'long',
						year: 'numeric'
					}).format(getDateFromPartialDateString(key)), // key is a string like 01-2022
		value: new Intl.NumberFormat('fr-FR', {
			maximumFractionDigits: 2
		}).format(value ?? 0)
	}));

	return (
		<TableContainer
			sx={{
				borderRadius: '15px',
				border: '1px solid rgba(224, 224, 224, 1)',
				maxHeight: printable ? 'auto' : getHeightInPixels(size)
			}}
		>
			<MuiTable aria-labelledby={idTitle} stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell
							scope="col"
							sx={{fontSize: fontSize, padding: padding}}
						>
							{translate('charts.table.thead.months')}
						</TableCell>
						<TableCell
							scope="col"
							sx={{fontSize: fontSize, padding: padding}}
						>
							{translate('charts.table.thead.value')}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{formattedData.map(({key, value}) => (
						<TableRow
							key={`table-stats-${key}`}
							sx={{'&:last-child td, &:last-child th': {border: 0}}}
						>
							<TableCell
								component="th"
								scope="row"
								sx={{fontSize: fontSize, padding: padding}}
							>
								{key}
							</TableCell>
							<TableCell sx={{fontSize: fontSize, padding: padding}}>
								{value}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default TableDate;

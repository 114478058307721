import {RaRecord} from 'ra-core';
import {NumberInput, SimpleForm} from 'react-admin';
import {ReferenceField, SimpleShowLayout, TextField} from 'react-admin';
import CustomDateInput from '../common/CustomDateInput';
import CustomEdit from '../common/CustomEdit';
import RequiredText from '../common/RequiredText';

const ReferenceValueEdit = () => (
	<CustomEdit>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />

			<SimpleShowLayout sx={{padding: 0, marginBottom: '1rem'}}>
				<ReferenceField source="type_id" reference="reference-value-types">
					<TextField source="label" />
				</ReferenceField>
			</SimpleShowLayout>
			<CustomDateInput
				source="begin"
				required
				fullWidth
				helperText="resources.reference-values.helpers.begin.date"
			/>
			<NumberInput
				source="value"
				required
				fullWidth
				helperText="resources.reference-values.helpers.value"
			/>
			<SimpleShowLayout sx={{padding: 0, marginBottom: '1rem'}}>
				<ReferenceField
					source="corporation_id"
					reference="corporations"
					link={(record: RaRecord, reference: string) =>
						`/${reference}/${record.corporation_id}/show`
					}
				>
					<TextField source="name" />
				</ReferenceField>
			</SimpleShowLayout>
		</SimpleForm>
	</CustomEdit>
);

export default ReferenceValueEdit;

import {useRef, useState} from 'react';
import {HttpError} from 'react-admin';

const useErrorHandler = () => {
	const [error, setError] = useState<string | null>(null);

	const handleError = useRef((e: unknown) => {
		if (e instanceof HttpError) {
			setError(e?.body?.errors?.message || e.message);
		}
	}).current;

	return {error, setError, handleError};
};

export default useErrorHandler;

import {Typography, TypographyProps} from '@mui/material';
import {Theme, styled} from '@mui/material/styles';
import {NumberStats} from '../models/types';
import HugeCurrencyNumber from './HugeCurrencyNumber';
import HugeHourNumber from './HugeHourNumber';
import HugePeopleNumber from './HugePeopleNumber';
import HugePercentNumber from './HugePercentNumber';

type HugeNumberProps = TypographyProps & {
	value: NumberStats['all'];
	type: 'percent' | 'currency' | 'people' | 'hour';
	hugeFontSize?: string | number;
};

const HugeNumber = ({value, type, hugeFontSize, ...rest}: HugeNumberProps) => (
	<Typography {...rest}>
		{type === 'currency' ? (
			<HugeCurrencyNumber value={value} hugeFontSize={hugeFontSize} />
		) : type === 'percent' ? (
			<HugePercentNumber value={value} hugeFontSize={hugeFontSize} />
		) : type === 'hour' ? (
			<HugeHourNumber value={value} hugeFontSize={hugeFontSize} />
		) : (
			<HugePeopleNumber value={value} hugeFontSize={hugeFontSize} />
		)}
	</Typography>
);

export default styled((props: HugeNumberProps) => <HugeNumber {...props} />)(
	({theme, hugeFontSize = '6rem'}) => `
	display: block;
	text-align: center;
	line-height: 1;
	font-weight: normal;
	font-size: 3.5rem;
	color: ${theme.palette.text.secondary};

	.huge {
		font-size: ${hugeFontSize};
		font-weight: 900;
		color: ${theme.palette.primary.main};
	}
`
);

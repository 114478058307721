import {SimpleShowLayout, TextField} from 'react-admin';
import CustomShow from '../common/CustomShow';

const BlockShow = () => (
	<CustomShow>
		<SimpleShowLayout>
			<TextField source="code" />
			<TextField source="title" />
		</SimpleShowLayout>
	</CustomShow>
);

export default BlockShow;

import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {Stats} from '../../charts/models/types';
import {
	getMinMaxRangeFromArray,
	getMinMaxRangeFromStats
} from '../../charts/utils/utils';
import {Indicator} from '../../resources/indicators/types';
import IndicatorChart from './IndicatorChart';

interface IndicatorsChartsProps {
	activeIndicators: Indicator[];
	referenceStats: Stats;
	comparedStats: Stats;
	referenceChartsTitle: string;
	comparedChartsTitle: string;
}

const IndicatorsCharts = ({
	activeIndicators,
	referenceStats,
	comparedStats,
	referenceChartsTitle,
	comparedChartsTitle
}: IndicatorsChartsProps) => {
	return (
		<Box>
			{activeIndicators.map(({id, name, graphical_representation}) => {
				const referenceDomain = getMinMaxRangeFromStats(
					graphical_representation,
					referenceStats?.[id]
				);
				const comparedDomain = getMinMaxRangeFromStats(
					graphical_representation,
					comparedStats?.[id]
				);

				let domain = referenceDomain;
				if (!!referenceDomain && !!comparedDomain) {
					domain = getMinMaxRangeFromArray([
						...referenceDomain,
						...comparedDomain
					]);
				}

				return (
					<Card
						key={`active-indicator-charts-${id}`}
						sx={{
							mb: 4,
							animation: `slideUp 0.5s 0.2s ease-out both`
						}}
					>
						<CardContent
							sx={{
								padding: {
									xs: '1rem 0.5rem !important',
									lg: '2.5rem 4rem !important'
								}
							}}
						>
							<Typography
								component="h3"
								sx={{
									textAlign: 'center',
									marginBottom: '2rem',
									fontSize: '1.4rem'
								}}
								id={`indicator-title-${id}`}
							>
								{name}
							</Typography>
							<Grid container justifyContent="space-evenly" spacing={2}>
								<Grid item xs={12} lg={6}>
									<Box
										sx={{
											padding: '2rem 1.5rem'
										}}
									>
										{!!referenceStats?.[id] && (
											<IndicatorChart
												domain={domain}
												stats={referenceStats[id]}
												title={referenceChartsTitle}
												indicatorId={id}
												indicatorGraphicType={
													graphical_representation
												}
											/>
										)}
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										sx={{
											padding: '2rem 1.5rem',
											borderRadius: '30px',
											background: '#f8f6f3'
										}}
									>
										{!!comparedStats?.[id] && (
											<IndicatorChart
												domain={domain}
												stats={comparedStats?.[id]}
												title={comparedChartsTitle}
												indicatorId={id}
												indicatorGraphicType={
													graphical_representation
												}
											/>
										)}
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				);
			})}
		</Box>
	);
};

export default IndicatorsCharts;

import {stringify} from 'query-string';
import simpleRestProvider from 'ra-data-simple-rest';
import {DataProvider} from 'react-admin';
import {ApiBaseUrl, ApiResponse, apiUrl, httpClient} from '../api';

type ApiListResponse = ApiResponse<{
	total: number;
	limit: number;
	page: number;
	data: any[];
}>;

const dataProvider: DataProvider = {
	// Par défaut, on utilise les méthodes du provider REST.
	...simpleRestProvider(ApiBaseUrl, httpClient),

	// Pour les listes, on surcharge le provider par défaut
	// car l'API ne supporte pas le protocole qu'il utilise.
	async getList(resource, params) {
		const {page, perPage} = params.pagination;
		const {field, order} = params.sort;
		const query = new URLSearchParams({
			page: `${page}`,
			limit: `${perPage}`,
			sort: JSON.stringify({[field]: order}),
			filter: JSON.stringify(params.filter)
		});

		const response = await httpClient<ApiListResponse>(
			apiUrl(`/${resource}?${query}`)
		);

		const {total, data} = response.json;

		return {
			total,
			data
		};
	},

	async getMany(resource, params) {
		const perPage = 100;
		const query = {
			filter: JSON.stringify({id: params.ids}),
			limit: perPage
		};

		const response = await httpClient<ApiListResponse>(
			apiUrl(`/${resource}?${stringify(query)}`)
		);

		const {data} = response.json;

		return {
			data
		};
	},

	async create(resource, params) {
		const response = await httpClient(apiUrl(`/${resource}`), {
			method: 'POST',
			body: params?.meta?.isMultipart
				? params.data.formData
				: JSON.stringify(params.data),
			headers: new Headers()
		});

		return {
			data: {
				id: -1,
				...response.json
			}
		};
	},

	async deleteMany(resource, params) {
		return Promise.all(
			params.ids.map((id) =>
				httpClient(apiUrl(`/${resource}/${id}`), {
					method: 'DELETE',
					headers: new Headers({
						'Content-Type': 'text/plain'
					})
				})
			)
		).then(() => ({
			data: []
		}));
	},

	async update(resource, params) {
		const response = await httpClient(apiUrl(`/${resource}/${params.id}`), {
			method: 'PATCH',
			body: JSON.stringify(params.data)
		});

		return {
			data: response.json
		};
	},

	// simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
	async updateMany(resource, params) {
		return Promise.all(
			params.ids.map((id) =>
				httpClient(apiUrl(`/${resource}/${id}`), {
					method: 'PATCH',
					body: JSON.stringify(params.data)
				})
			)
		).then((responses) => ({data: responses.map(({json}) => json.id)}));
	}
};

export default dataProvider;

export enum UserRole {
	FicAdmin = 'ROLE_ADMIN',
	FicUser = 'ROLE_USER',
	CorpoAdmin = 'ROLE_ADMIN_CORPO',
	CorpoUser = 'ROLE_USER_CORPO'
}

export interface User {
	id: string;
	firstname: string;
	lastname: string;
	username: string;
	roles: UserRole[];
}

export const ROLE_CHOICES = (
	Object.keys(UserRole) as Array<keyof typeof UserRole>
).map((key) => ({
	role: UserRole[key]
}));

import {ChartSizes, GraphicTypes, TableStats} from '../models/types';
import TableDate from './TableDate';
import TableValues from './TableValues';

interface TableProps {
	idTitle: string;
	data: TableStats;
	size: ChartSizes;
	type: GraphicTypes;
	fontSize?: string | number;
	padding?: string | number;
}

const Table = ({data, idTitle, size, type, fontSize, padding}: TableProps) =>
	type === GraphicTypes.table ? (
		<TableDate
			data={data}
			idTitle={idTitle}
			size={size}
			fontSize={fontSize}
			padding={padding}
		/>
	) : type === GraphicTypes.values_table ? (
		<TableValues
			data={data}
			idTitle={idTitle}
			size={size}
			fontSize={fontSize}
			padding={padding}
		/>
	) : null;
export default Table;

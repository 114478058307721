import {Box, Grid, Theme, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {HttpError, Loading, useTranslate} from 'react-admin';
import {useParams, useSearchParams} from 'react-router-dom';
import dataProvider from '../../providers/data';
import {Corporation} from '../../resources/corporations/types';
import {Indicator} from '../../resources/indicators/types';
import ErrorMessage from './ErrorMessage';
import IndicatorsCharts from './IndicatorsCharts';
import IndicatorsCheckboxes from './IndicatorsCheckboxes';
import PageHeader from './PageHeader';
import {ThemeEnhancedWithIndicators} from './types';
import useErrorHandler from './useErrorHandler';
import useGetPanelStats from './useGetPanelStats';
import useGetStats from './useGetStats';
import useGetThemes from './useGetThemes';
import {
	formatPeriodAsText,
	getPanelStatsParams,
	sortIndicatorsByThemeAndSelfPositions
} from './utils';

const CorporationCompareToPanelPage = () => {
	const {id} = useParams() as {id: string};
	const [searchParams, setSearchParams] = useSearchParams();
	const translate = useTranslate();

	const [corporation, setCorporation] = useState<Corporation>();

	const {error, handleError} = useErrorHandler();
	const [isReady, setIsReady] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [themes, fetchThemes] = useGetThemes();
	const [expandedThemes, setExpandedThemes] = useState<
		Record<number, boolean>
	>({});
	const [activeIndicators, setActiveIndicators] = useState<Indicator[]>([]);
	const [referenceStats, fetchReferenceStats] = useGetStats();
	const [panelStats, fetchPanelStats] = useGetPanelStats();

	const startDateString = searchParams.get('start')!;
	const endDateString = searchParams.get('end')!;
	const panelParams = getPanelStatsParams(searchParams);

	const initialize = useCallback(
		async (idCorporation: string, searchParams: URLSearchParams) => {
			try {
				if (!panelParams) {
					throw new HttpError('corporation.compare.missingDates', 500);
				}

				const {data: corporation} = await dataProvider.getOne<Corporation>(
					'corporations',
					{
						id: parseInt(idCorporation)
					}
				);

				setCorporation(corporation);

				let themesTabs: ThemeEnhancedWithIndicators[];
				if (!themes) {
					themesTabs = await fetchThemes();
				} else {
					themesTabs = themes;
				}

				const expandedList: Record<number, boolean> = {};
				let activeIndicators: Indicator[] = [];

				if (searchParams.has('indicators')) {
					// On a des indicateurs en query params
					const defaultIndicatorsIds =
						searchParams
							.getAll('indicators')!
							.map((id) => parseInt(id, 10)) ?? [];

					defaultIndicatorsIds.forEach((id) => {
						const themeIndex = themesTabs?.findIndex(
							(theme) =>
								!theme.isSynthesis &&
								theme.indicators.some(
									(indicator) => indicator.id === id
								)
						);
						if (themeIndex !== -1) {
							const indicator = themesTabs?.[themeIndex].indicators.find(
								(indicator) => indicator.id === id
							);
							if (!!indicator) {
								activeIndicators.push(indicator);
								expandedList[themeIndex] = true;
							}
						}
					});
				} else {
					// On initialise avec les indicateurs de la synthèse
					const hasSynthesis = themesTabs?.[0]?.isSynthesis;
					if (hasSynthesis) {
						const synthesis = themesTabs[0];

						synthesis?.indicators?.forEach((indicator) => {
							const themeIndex = themesTabs.findIndex(
								(theme) => theme.id === indicator.theme_id
							);
							if (themeIndex !== -1) {
								activeIndicators.push(indicator);
								expandedList[themeIndex] = true;
							}
						});
					}
				}

				activeIndicators = sortIndicatorsByThemeAndSelfPositions(
					activeIndicators,
					themesTabs
				);

				await Promise.all([
					fetchReferenceStats(
						corporation?.id,
						activeIndicators.map(({id}) => id),
						new Date(startDateString),
						new Date(endDateString)
					),

					fetchPanelStats(
						corporation?.id,
						activeIndicators.map(({id}) => id),
						panelParams
					)
				]);

				setExpandedThemes((state) => ({
					...state,
					...expandedList
				}));
				setActiveIndicators(activeIndicators);
			} catch (e) {
				handleError(e);
			} finally {
				setIsReady(true);
				setIsLoading(false);
			}
		},
		[
			themes,
			fetchReferenceStats,
			startDateString,
			endDateString,
			fetchPanelStats,
			panelParams,
			fetchThemes,
			handleError
		]
	);

	useEffect(() => {
		if (!isReady && !isLoading) {
			setIsLoading(true);
			initialize(id, searchParams);
		}
	}, [id, initialize, isReady, isLoading, searchParams]);

	const handleExpandClick = (index: number) => {
		setExpandedThemes((state) => ({
			...state,
			[index]: !state?.[index] ?? true
		}));
	};

	const toggleIndicator = useCallback(
		async (indicator: Indicator) => {
			if (!panelParams) {
				return null;
			}

			const isActive = activeIndicators.find(({id}) => id === indicator.id);
			let indicatorsList: Indicator[] = [];
			if (isActive) {
				// Array.filter doesn't change items' order so no need to re-sort
				indicatorsList = activeIndicators.filter(
					({id}) => id !== indicator.id
				);
			} else {
				indicatorsList = [...activeIndicators, indicator];
				indicatorsList = sortIndicatorsByThemeAndSelfPositions(
					[...activeIndicators, indicator],
					themes!
				);
			}

			await Promise.all([
				fetchReferenceStats(
					corporation?.id,
					indicatorsList
						.map(({id}) => id)
						.filter((id) => !(id in referenceStats)),
					new Date(startDateString),
					new Date(endDateString)
				),

				fetchPanelStats(
					corporation?.id,
					indicatorsList
						.map(({id}) => id)
						.filter((id) => !(id in panelStats)),
					panelParams
				)
			]);
			setActiveIndicators(indicatorsList);
			const updatedSearchParams = searchParams;
			updatedSearchParams.delete('indicators');
			indicatorsList.forEach(({id}) => {
				updatedSearchParams.append('indicators', id.toString());
			});
			setSearchParams(updatedSearchParams);
		},
		[
			activeIndicators,
			corporation?.id,
			fetchPanelStats,
			fetchReferenceStats,
			panelParams,
			panelStats,
			endDateString,
			startDateString,
			referenceStats,
			searchParams,
			setSearchParams,
			themes
		]
	);

	if (isLoading) {
		return <Loading />;
	}

	const referencePeriodAsText = formatPeriodAsText(
		new Date(startDateString),
		new Date(endDateString)
	);

	return (
		<Box
			sx={(theme: Theme) => ({
				marginTop: theme.spacing(2)
			})}
		>
			<PageHeader
				backLink={{
					label: translate('corporation.compare.link.back'),
					title: translate('corporation.compare.link.backTitle'),
					target: `/corporations/${id}/detail`
				}}
				title={corporation?.name}
			/>

			<Typography
				component="h2"
				sx={(theme: Theme) => ({
					marginTop: theme.spacing(2),
					color: theme.palette.text.secondary,
					'span': {
						color: theme.palette.primary.main
					}
				})}
				dangerouslySetInnerHTML={{
					__html: translate('corporation.compare.period.title', {
						reference: referencePeriodAsText,
						compared: translate('corporation.compare.period.panel')
					})
				}}
			/>

			<Grid
				container
				spacing={4}
				justifyContent="space-between"
				sx={(theme: Theme) => ({
					marginTop: theme.spacing(2)
				})}
			>
				<Grid item lg={3} md={5} sm={12} xs={12}>
					{themes && themes?.length > 0 && (
						<IndicatorsCheckboxes
							themes={themes}
							expandedThemes={expandedThemes}
							activeIndicators={activeIndicators}
							handleExpandClick={handleExpandClick}
							handleIndicatorChange={toggleIndicator}
						/>
					)}
				</Grid>
				<Grid item lg={9} md={7} sm={12} xs={12}>
					{error ? (
						<ErrorMessage text={error} />
					) : (
						<IndicatorsCharts
							activeIndicators={activeIndicators}
							referenceStats={referenceStats}
							comparedStats={panelStats}
							referenceChartsTitle={corporation?.name ?? ''}
							comparedChartsTitle={translate(
								'corporation.compare.panelAverage'
							)}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default CorporationCompareToPanelPage;

import {useTranslate} from 'react-admin';
import {
	Cell,
	LabelList,
	PieChart,
	Pie as RechartsPie,
	ResponsiveContainer,
	Tooltip
} from 'recharts';
import {ChartSizes, PieLabelType, PieStats} from '../models/types';
import {getHeightInPixels} from '../utils/utils';
import ChartLegend from './ChartLegend';
import {useLabelsColor} from './useLabelsColor';

interface PieProps {
	data: PieStats['all'];
	idTitle: string;
	size: ChartSizes;
	showLabelInside: boolean;
}

interface DataItem {
	key: string;
	value: number;
	percent: string;
}

const Pie = ({data, idTitle, size, showLabelInside}: PieProps) => {
	const colors = useLabelsColor();

	const total = Object.values(data).reduce(
		(previous, current) => previous + current.rule,
		0
	);
	const translation = useTranslate();
	const minPercentValue = 1;
	const minValue = 0;
	const isMinPercentAndValueToDisplay = (value: number, percent: number) =>
		value > minValue && percent >= minPercentValue;

	const formattedData: DataItem[] = Object.values(data)
		.map((value) => ({
			key: value.label,
			value: value.rule,
			percentNumber: Math.round(Number(value.rule / total) * 100),
			percent: new Intl.NumberFormat('fr-FR', {
				style: 'percent',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			})
				.format(value.rule / total)
				.replace(/\s/g, '')
		}))
		.filter(({value, percentNumber}) =>
			isMinPercentAndValueToDisplay(value, percentNumber)
		);

	const RenderCustomizedLabelLine = (props: PieLabelType) => {
		return isMinPercentAndValueToDisplay(props.value, props.percentNumber) ? (
			<path
				stroke={props.stroke}
				d={`M${props.points[0].x},${props.points[0].y}L${props.points[1].x},${props.points[1].y}`}
			/>
		) : (
			<></>
		);
	};

	const RenderLabel = (props: PieLabelType) => {
		const RADIAN = Math.PI / 180;
		const radius =
			25 + props.innerRadius + (props.outerRadius - props.innerRadius);
		const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
		const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);
		return (
			isMinPercentAndValueToDisplay(props.value, props.percentNumber) && (
				<text
					x={x}
					y={y}
					fontSize="0.8rem"
					fontFamily="sans-serif"
					dominantBaseline="central"
					cy={props.cy}
					cx={props.cx}
					fill="#202020"
					stroke="transparent"
					textAnchor={props.x > props.cx ? 'start' : 'end'}
				>
					{props.percentNumber}
					{translation('charts.table.symbols.percent')}
				</text>
			)
		);
	};

	return (
		<>
			<div style={{height: getHeightInPixels(size)}}>
				<ResponsiveContainer width="99%" height={300}>
					<PieChart margin={{top: 25, left: 25, right: 25, bottom: 25}}>
						<RechartsPie
							startAngle={90}
							endAngle={450}
							minAngle={3}
							data={formattedData}
							outerRadius="90%"
							cx="50%"
							cy="50%"
							dataKey="value"
							isAnimationActive={false}
							label={RenderLabel}
							labelLine={RenderCustomizedLabelLine}
						>
							{formattedData.map(({key}, index) => (
								<Cell
									key={`pie-cell-${index}`}
									fill={colors?.pie?.[idTitle]?.[key]}
								/>
							))}
							{showLabelInside && (
								<LabelList
									fill="#2e2e2e"
									stroke="none"
									fontSize="12px"
									fontWeight="bold"
									dataKey="value"
									formatter={(value: number) => `${value.toFixed(0)}`}
									position="inside"
								/>
							)}
						</RechartsPie>
						<Tooltip
							contentStyle={{maxWidth: '15rem', whiteSpace: 'unset'}}
							formatter={(_value, _name, props) => {
								let formattedValue = parseFloat(
									`${(props?.payload as DataItem)?.value}`
								);

								let decimalPart =
									formattedValue - Math.floor(formattedValue);

								let result;

								if (decimalPart < 0.5) {
									formattedValue = Math.floor(formattedValue);
									result = formattedValue.toFixed(0);
								} else if (decimalPart > 0.5) {
									formattedValue = Math.ceil(formattedValue);
									result = formattedValue.toFixed(0);
								} else {
									result = formattedValue.toFixed(2);
								}

								return [
									result.toString(),
									(props?.payload as DataItem)?.key
								];
							}}
						/>
					</PieChart>
				</ResponsiveContainer>
			</div>
			<ChartLegend
				legends={formattedData.map(({key, value}) => ({
					name: key,
					value: value
				}))}
				colors={colors?.pie?.[idTitle]}
			/>
		</>
	);
};

export default Pie;

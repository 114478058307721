import {RichTextInput} from 'ra-input-rich-text';
import {
	BooleanInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	useTranslate
} from 'react-admin';
import CustomCreate from '../common/CustomCreate';
import CustomRichTextToolbar from '../common/CustomRichTextToolbar';
import RequiredText from '../common/RequiredText';
import {RULE_CHOICES, Rule} from './types';

const IndicatorCreate = () => {
	const translate = useTranslate();

	return (
		<CustomCreate>
			<SimpleForm sx={{maxWidth: 1000}}>
				<RequiredText />
				<TextInput source="name" required fullWidth />
				<NumberInput source="identifier" required fullWidth />
				<RichTextInput
					source="description"
					toolbar={<CustomRichTextToolbar />}
					fullWidth
				/>
				<ReferenceInput source="theme_id" reference="themes">
					<SelectInput optionText="name" required fullWidth />
				</ReferenceInput>
				<SelectInput
					source="rule_by_month"
					choices={RULE_CHOICES}
					optionText={({id}: Rule) =>
						translate(`resources.indicators.ruleChoices.${id}`)
					}
					required
					fullWidth
				/>
				<SelectInput
					source="rule_on_period"
					choices={RULE_CHOICES}
					optionText={({id}: Rule) =>
						translate(`resources.indicators.ruleChoices.${id}`)
					}
					required
					fullWidth
				/>
				<SelectInput
					source="group"
					choices={[{text: 'raw'}, {text: 'workforce'}]}
					optionValue="text"
					optionText="text"
					required
					fullWidth
				/>
				<NumberInput source="position" required fullWidth />
				<BooleanInput source="in_synthesis" />
				<TextInput source="abscissa" fullWidth />
				<TextInput source="ordinate" fullWidth />
			</SimpleForm>
		</CustomCreate>
	);
};

export default IndicatorCreate;

import {useEffect} from 'react';
import {
	ArrayInput,
	AutocompleteArrayInput,
	AutocompleteInput,
	BooleanInput,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	useNotify
} from 'react-admin';
import useErrorHandler from '../../app/corporation/useErrorHandler';
import CustomCreate from '../common/CustomCreate';
import RequiredText from '../common/RequiredText';

const CorporationCreate = () => {
	const {error, setError, handleError} = useErrorHandler();
	const notify = useNotify();

	const onError = (e: unknown) => {
		handleError(e);
	};

	useEffect(() => {
		if (error) {
			notify(error, {type: 'error'});
			setError(null);
		}
	}, [error]);

	return (
		<CustomCreate mutationOptions={{onError}}>
			<SimpleForm sx={{maxWidth: 600}}>
				<RequiredText />
				<TextInput source="name" required fullWidth />
				<ReferenceInput source="company_id" reference="companies">
					<AutocompleteInput
						optionText="name"
						sx={{minWidth: '300px', flexWrap: 'nowrap'}}
						debounce={500}
						suggestionLimit={10}
						filterToQuery={(searchText) => ({name: `%${searchText}%`})}
						fullWidth
					/>
				</ReferenceInput>
				<ReferenceInput source="region_id" reference="regions">
					<SelectInput optionText="name" required fullWidth />
				</ReferenceInput>
				<TextInput source="street" required fullWidth />
				<TextInput source="zip" required fullWidth />
				<TextInput source="city" required fullWidth />
				<ReferenceInput source="agreement_id" reference="agreements">
					<AutocompleteInput
						source="agreements"
						suggestionLimit={10}
						debounce={500}
						filterToQuery={(searchText) => ({label: `%${searchText}%`})}
						optionText="label"
						fullWidth
					/>
				</ReferenceInput>
				<TextInput
					source="registration"
					helperText="resources.corporations.helpers.registration"
					required
					fullWidth
				/>
				<ArrayInput source="registrations">
					<SimpleFormIterator>
						<TextInput source="" />
					</SimpleFormIterator>
				</ArrayInput>
				<ReferenceArrayInput reference="activities" source="activities_ids">
					<AutocompleteArrayInput
						optionText="name"
						suggestionLimit={10}
						debounce={500}
						filterToQuery={(searchText) => ({name: `%${searchText}%`})}
						fullWidth
					/>
				</ReferenceArrayInput>
				<ReferenceArrayInput reference="users" source="users_ids">
					<AutocompleteArrayInput
						suggestionLimit={10}
						debounce={500}
						filterToQuery={(searchText) => ({
							lastname: `%${searchText}%`
						})}
						optionText={({firstname, lastname, username}) =>
							`${lastname.toUpperCase()} ${firstname} (${username})`
						}
						fullWidth
					/>
				</ReferenceArrayInput>
				<BooleanInput
					source="active"
					helperText="resources.corporations.helpers.active"
				/>
				<BooleanInput
					source="reportable"
					helperText="resources.corporations.helpers.reportable"
				/>
				<TextInput
					source="internal"
					defaultValue={false}
					type="hidden"
					sx={{display: 'none'}}
				/>
			</SimpleForm>
		</CustomCreate>
	);
};

export default CorporationCreate;

export const fr = {
	table: {
		all: 'Total sur la période (%{total} mois)',
		thead: {
			months: 'Mois',
			value: 'Valeur',
			label: 'Libellé'
		},
		symbols: {
			currency: {
				euro: '€'
			},
			kilo: 'k',
			percent: '%',
			people: 'pers.',
			hour: 'hr.'
		}
	}
};

import {RichTextInput} from 'ra-input-rich-text';
import {AutocompleteInput, ReferenceInput, SimpleForm} from 'react-admin';
import {SelectInput} from 'react-admin';
import CustomCreate from '../common/CustomCreate';
import CustomDateInput from '../common/CustomDateInput';
import CustomRichTextToolbar from '../common/CustomRichTextToolbar';
import RequiredText from '../common/RequiredText';

const ReferenceValueCreate = () => (
	<CustomCreate>
		<SimpleForm sx={{maxWidth: 600}}>
			<RequiredText />
			<ReferenceInput
				source="corporation_id"
				reference="corporations"
				sort={{field: 'name', order: 'ASC'}}
			>
				<AutocompleteInput
					optionText="name"
					sx={{minWidth: '300px', flexWrap: 'nowrap'}}
					debounce={500}
					suggestionLimit={10}
					filterToQuery={(searchText) => ({name: `%${searchText}%`})}
					fullWidth
				/>
			</ReferenceInput>
			<ReferenceInput source="theme_id" reference="themes">
				<SelectInput
					optionText="name"
					fullWidth
					helperText="resources.analyzes.helpers.theme"
				/>
			</ReferenceInput>
			<CustomDateInput
				source="begin"
				required
				fullWidth
				helperText="resources.analyzes.helpers.begin.date"
			/>
			<CustomDateInput
				source="expiration"
				required
				fullWidth
				helperText="resources.analyzes.helpers.begin.date"
			/>
			<RichTextInput
				isRequired
				source="content"
				toolbar={<CustomRichTextToolbar />}
				fullWidth
			/>
		</SimpleForm>
	</CustomCreate>
);

export default ReferenceValueCreate;

export const fr = {
	name: 'Analyse |||| Analyses',
	backLink: 'Retour',
	fields: {
		id: 'ID',
		content: 'Paragraphe',
		'begin.date': 'Date de début de la période analysée',
		begin: 'Date de début de la période analysée',
		expiration: 'Date de fin de la période analysée',
		'expiration.date': 'Date de fin de la période analysée',
		updated: 'Date de mise à jour de la période analysée',
		'updated.date': 'Date de mise à jour de la période analysée',
		theme_id: 'Thème',
		user_id: 'Utilisateur',
		corporation_id: 'Etablissement'
	},
	helpers: {
		theme: "Si aucun thème n'est sélectionné, l'analyse sera affichée dans l'onglet \"Synthèse\".",
		begin: {
			date: "L'analyse sera affichée si sa date de début correspond à l'un des mois de la période consultée."
		},
		expiration: {
			date: "L'analyse sera affichée si sa date de fin correspond à l'un des mois de la période consultée.s"
		}
	}
};
